import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { validate, defaultValues } from "./formHelpers";
import {
    COUNTRIES,
    US_STATES,
    CA_STATES,
    LICENSE_CLASS_ITEM
} from "../../../utils/constants";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from "../../../utils/constants";
import { updateRegistration } from "../../../actions/registration/registrationActions";
import AccidentModal from "./AccidentModal";
import ViolationModal from "./ViolationModal";
import ViolationView from "./ViolationView";
import AccidentView from "./AccidentView";
import ConfirmationModal from "../../../modals/ConfirmationModal"

class LicenseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: "",
            isViolationOpen: false,
            isAccidentOpen: false,
            isConfirmOpen: false,
        };
    }

    onSubmit = async values => {
        await this.props.updateRegistration({ license: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/history`;
        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };
    onViolationModalClose = data => {
        const value = _get(this, "props.violation", []);
        value.push(data);
        this.props.updateRegistration({ violation: value });
        this.toggleViolation();
    };
    onAccidentModalClose = data => {
        const value = _get(this, "props.accident", []);
        value.push(data);
        this.props.updateRegistration({ accident: value });
        this.toggleAccident();
    };
    toggleViolation = () => {
        this.setState({
            isViolationOpen: !this.state.isViolationOpen
        });
    };
    toggleAccident = () => {
        this.setState({
            isAccidentOpen: !this.state.isAccidentOpen
        });
    };

    onItemDelete = ({ item, type }) => {
        if (type === 1) {
            const value = _get(this, "props.accident", []);
            const items = value.filter(i => i !== item);
            this.props.updateRegistration({ accident: items });
        } else if (type === 2) {
            const value = _get(this, "props.violation", []);
            const items = value.filter(i => i !== item);
            this.props.updateRegistration({ violation: items });
        }
        this.toggleConfirm({ item: null })
    };

    toggleConfirm = ({ item, type }) => {
        this.setState({
            isConfirmOpen: !this.state.isConfirmOpen,
            item: item,
            type: type
        });
    };



    render() {
        let { initialValues, isEdit, onClose } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/general`;
        const { violation, accident } = this.props;
        const { isConfirmOpen, isAccidentOpen, isViolationOpen } = this.state;

        return (

            <div>
                {isConfirmOpen &&
                    (< ConfirmationModal
                        message={`Are you sure to delete this ${this.state.type === 2 ? "violation ?" : "accident ?"}`}
                        isOpen={isConfirmOpen}
                        toggle={() => this.toggleConfirm({ item: null, type: null })}
                        onConfirm={() => this.onItemDelete({ item: this.state.item, type: this.state.type })}
                    />)
                }
                {isAccidentOpen && (<AccidentModal
                    isOpen={isAccidentOpen}
                    toggle={this.toggleAccident}
                    onClose={this.onAccidentModalClose}
                />)}
                {isViolationOpen && (<ViolationModal
                    isOpen={this.state.isViolationOpen}
                    toggle={this.toggleViolation}
                    onClose={this.onViolationModalClose}
                />)}
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={this.onSubmit}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, values }) => {
                        const SEL_STATES =
                            values.licenseCountry === "CA"
                                ? CA_STATES
                                : US_STATES;
                        if (values.none) {
                            values.other = false
                            values.tanker = false
                            values.doublesTriples = false
                            values.hazmat = false
                            values.xEndorsement = false
                            values.hazmatExp = ""
                            values.endorsementExp = ""
                        }
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <SelectBox
                                                md={4}
                                                name={"licenseCountry"}
                                                label={"Lic Country"}
                                                options={COUNTRIES}
                                            />
                                            <SelectBox
                                                md={4}
                                                name={"licenseState"}
                                                label={"Lic State"}
                                                options={SEL_STATES}
                                            />
                                            <SelectBox
                                                md={4}
                                                name={"licenseClass"}
                                                label={"Lic Class"}
                                                options={LICENSE_CLASS_ITEM}
                                            />
                                        </Row>
                                        <Row>
                                            <InputBox
                                                md={4}
                                                name={"licenseNo"}
                                                label={"Lic Number"}
                                                placeholder={
                                                    "Enter License Number"
                                                }
                                            />
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"licenseExp"}
                                                label={"Lic Expiry."}
                                                onlyFutureDate={true}

                                            />
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"physicalExp"}
                                                label={"Medical Expiry."}
                                                onlyFutureDate={true}

                                            />
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <div
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 5
                                            }}
                                        >
                                            <strong>
                                                <h5>Endorsements</h5>
                                            </strong>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    name={"none"}
                                                    label={"None"}
                                                    checked={values.none}
                                                    marginTop={isEdit ? "25" : "20"}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    name={"tanker"}
                                                    label={"Tanker"}
                                                    marginTop={isEdit ? "25" : "20"}
                                                    disabled={
                                                        values.none
                                                            ? values.none
                                                            : false
                                                    }
                                                    checked={values.none
                                                        ? false
                                                        : values.tanker}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    name={"doublesTriples"}
                                                    marginTop={isEdit ? "25" : "20"}
                                                    label={"Doubles / Triples"}
                                                    disabled={
                                                        values.none
                                                            ? values.none
                                                            : false
                                                    }
                                                    checked={values.none
                                                        ? false
                                                        : values.doublesTriples}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    name={"xEndorsement"}
                                                    label={"X Endorsement"}
                                                    marginTop={isEdit ? "25" : "20"}
                                                    disabled={
                                                        values.none
                                                            ? values.none
                                                            : false
                                                    }
                                                    checked={values.none
                                                        ? false
                                                        : values.xEndorsement}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    name={"hazmat"}
                                                    marginTop={isEdit ? "25" : "20"}
                                                    label={"Hazmat"}
                                                    disabled={
                                                        values.none
                                                            ? values.none
                                                            : false
                                                    }
                                                    checked={values.none
                                                        ? false
                                                        : values.hazmat}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <InputCheckbox
                                                    marginTop={isEdit ? "25" : "20"}
                                                    name={"other"}
                                                    label={"Other"}
                                                    disabled={
                                                        values.none
                                                            ? values.none
                                                            : false
                                                    }
                                                    checked={values.none
                                                        ? false
                                                        : values.other}

                                                />
                                            </Col>

                                        </Row>
                                    </Col>

                                    {!values.none && values.xEndorsement && (
                                        <InputDatePicker
                                            md={6}
                                            type={"date"}
                                            name={"endorsementExp"}
                                            onlyFutureDate={true}
                                            label={"X Endorsement Expiry"}
                                        />
                                    )}
                                    {!values.none && values.hazmat && (
                                        <InputDatePicker
                                            md={6}
                                            type={"date"}
                                            name={"hazmatExp"}
                                            onlyFutureDate={true}
                                            label={"Hazmat Expiry"}
                                        />
                                    )}
                                    <Col md={12} >
                                        <Row style={{ marginTop: 12 }}>
                                            <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                                                <h5 style={{ textAlign: "left" }}                                                >
                                                    Violations
                                                </h5>
                                            </Col>
                                            <Col md={4} style={{ textAlign: "right" }}                                            >
                                                <div >
                                                    {violation.length < 10 ?
                                                        <Button
                                                            style={{
                                                                width: 100,
                                                                borderWidth: 0.5
                                                            }}
                                                            color="primary"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={this.toggleViolation}

                                                        >Add</Button>
                                                        : <span style={{
                                                            fontSize: 10,
                                                            color: "red"
                                                        }}>
                                                            Can't add more then 10
                                                        </span>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        {violation.map(
                                            (item, i) => {
                                                return (
                                                    <ViolationView
                                                        md={12}
                                                        violation={item}
                                                        onClick={() => this.toggleConfirm({ item: item, type: 2 })}
                                                    />
                                                );
                                            }
                                        )}
                                    </Col>

                                    <Col md={12} >
                                        <Row style={{
                                            marginTop: 12
                                        }}>
                                            <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                                                <h5
                                                    style={{
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Traffic Convictions / Accidents
                                                    </h5>
                                            </Col>
                                            <Col md={4} style={{ textAlign: "right" }}>
                                                <div>
                                                    {accident.length < 10 ?
                                                        <Button
                                                            style={{
                                                                width: 100,
                                                                borderWidth: 0.5
                                                            }}
                                                            color="primary"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={this.toggleAccident}
                                                        >
                                                            Add
                                                        </Button>
                                                        : <span style={{
                                                            fontSize: 10,
                                                            color: "red"
                                                        }}>
                                                            Can't add more then 10
                                                        </span>
                                                    }

                                                </div>
                                            </Col>
                                        </Row>


                                        {accident.map((item, i) => {
                                            return (
                                                <AccidentView
                                                    md={12} accident={item}
                                                    onClick={() => this.toggleConfirm({ item: item, type: 1 })}
                                                />
                                            );
                                        })}
                                    </Col>
                                </Row>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (General)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (History)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }
                    }
                </Formik >
            </div >
        );
    }
}

const mapStateToProps = state => {
    const initialValues = _get(state, "registrationData.license", {});
    return {
        business: _get(state, "appData.business", {}),
        initialValues: {
            ...defaultValues,
            ...initialValues
        },
        violation: _get(state, "registrationData.violation", []),
        accident: _get(state, "registrationData.accident", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateRegistration
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LicenseForm)
);
