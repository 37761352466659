import moment from "moment-timezone";

moment.createFromInputFallback = function (config) {
    config._d = new Date(config._i);
};

export function formatDate(e: any) {
    if (e === undefined) {
        return "";
    }
    else {
        try {
            return moment(e).format("MMM D, YYYY");
        } catch (e) {
            return "";
        }
    }
}
export function isValidLicence(no) {
    if (no === undefined) {
        return false;
    }
    else {
        return (/^[A-Za-z][0-9]{6,}$/.test(no))
    }
}

export function isFutureDate(e: any) {
    if (e === undefined) {
        return false;
    }
    else {
        const start = moment(new Date());
        const end = moment(e);
        const days = moment.duration(start.diff(end)).asDays();
        return (Math.round(days) <= 1)
    }
}
export function isUnvalidDate(start: any, end: any) {
    if (!start || !end) {
        return false;
    }
    else {
        start = moment(start);
        end = moment(end);
        const days = moment.duration(start.diff(end)).asDays();
        if (Math.round(days) <= 0) {
            return false;
        }
        else {
            return true
        }
    }
}

export function getYear(e: any) {
    try {
        return moment(e).format("YYYY");
    } catch (e) {
        return "";
    }
}

export function getDays(end, start = new Date()) {
    start = moment(start);
    end = moment(end);
    const days = moment.duration(start.diff(end)).asDays();
    return Math.round(days);
}
