import _get from "lodash/get";
import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateGoogleUser } from "../actions/app/appAction";

function GoogleSignIn({ googleUserEmail, business, updateGU }) {
    function onSuccess(googleUser) {
        try {
            const profile = googleUser.getBasicProfile();
            const token = googleUser.getAuthResponse().id_token;

            if (googleUserEmail === profile.getEmail()) {
                return;
            }

            updateGU({
                token: token,
                id: profile.getId(),
                name: profile.getName(),
                email: profile.getEmail(),
                firstName: profile.getGivenName(),
                lastName: profile.getFamilyName(),
                imageUrl: profile.getImageUrl(),
                getEmail: profile.getEmail()
            });
        } catch (err) {
            console.log(err);
        }
    }

    function onFailure(error) {
        console.log(error);
    }

    function renderButton() {
        window.gapi.signin2.render("google-signin2", {
            scope: "profile email",
            theme: "dark",
            width: 200,
            // height: 50,
            longtitle: true,
            onsuccess: onSuccess,
            onfailure: onFailure
        });
    }

    setTimeout(() => {
        renderButton();
    }, 1000);

    return (
        <Row>
            <Col xs="12" md="4">
                <div id="google-signin2" />
            </Col>
            <Col xs="12" md="8" style={{ fontSize: 12, fontWeight: 400 }}>
                Sign In to google to save your application. You can retrive your application anytime you want.
                <span style={{ color: "red" }}> Required..</span>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        googleUserEmail: _get(state, "appData.googleUser.email", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ updateGU: updateGoogleUser }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleSignIn);
