import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { defaultValues } from './formHelpers';
import STYLES from '../STYLES';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from '../../../actions/carrier/carrierActions';
import TruckModal from './TruckModal';
import TruckView from './TruckView';
import DriverModal from './DriverModal';
import DriverView from './DriverView';
import TrailerModal from './TrailerModal';
import TrailerView from './TrailerView';
import ConfirmationModal from '../../../modals/ConfirmationModal';

class LicenseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMsg: '',
      successMsg: '',
      isDriverOpen: false,
      isTruckOpen: false,
      isTrailerOpen: false,
      isConfirmOpen: false,
    };
  }

  onSubmit = async (values) => {
    const businessKey = _get(this, 'props.match.params.businessKey');
    const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/hos`;
    const { isEdit, onClose } = this.props
    if (isEdit) {
      onClose()
    } else {
      this.props.history.push(nextUrl);
    }
  };
  onDriverModalClose = (data) => {
    const value = _get(this, 'props.drivers', []);
    value.push(data);
    this.props.updateRegistration({ drivers: value });
    this.toggleDriver();
  };
  onTruckModalClose = (data) => {
    const value = _get(this, 'props.trucks', []);
    value.push(data);
    this.props.updateRegistration({ trucks: value });
    this.toggleTruck();
  };
  onTrailerModalClose = (data) => {
    const value = _get(this, 'props.trailers', []);
    value.push(data);
    this.props.updateRegistration({ trailers: value });
    this.toggleTrailer();
  };
  toggleDriver = () => {
    this.setState({
      isDriverOpen: !this.state.isDriverOpen,
    });
  };
  toggleTruck = () => {
    this.setState({
      isTruckOpen: !this.state.isTruckOpen,
    });
  };
  toggleTrailer = () => {
    this.setState({
      isTrailerOpen: !this.state.isTrailerOpen,
    });
  };

  onItemDelete = ({ item, type }) => {
    if (type === 1) {
      const value = _get(this, 'props.drivers', []);
      const items = value.filter((i) => i !== item);
      this.props.updateRegistration({ driverTypes: items });
    } else if (type === 2) {
      const value = _get(this, 'props.trucks', []);
      const items = value.filter((i) => i !== item);
      this.props.updateRegistration({ truckTypes: items });
    } else if (type === 3) {
      const value = _get(this, 'props.trailers', []);
      const items = value.filter((i) => i !== item);
      this.props.updateRegistration({ trailerTypes: items });
    }
    this.toggleConfirm({ item: null });
  };

  toggleConfirm = ({ item, type }) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      item: item,
      type: type,
    });
  };

  render() {
    let { initialValues, isEdit, onClose } = this.props;
    const businessKey = _get(this, 'props.match.params.businessKey');
    const backUrl = `${CARRIER_BASE_URL}/${businessKey}/contacts`;
    const { trucks, drivers, trailers } = this.props;
    const {
      isConfirmOpen,
      isTruckOpen,
      isDriverOpen,
      isTrailerOpen,
    } = this.state;

    return (
      <div>
        {isConfirmOpen && (
          <ConfirmationModal
            message={
              'Are you sure to delete this item?'
            }
            isOpen={isConfirmOpen}
            toggle={() => this.toggleConfirm({ item: null })}
            onConfirm={() =>
              this.onItemDelete({
                item: this.state.item,
                type: this.state.type,
              })
            }
          />
        )}
        {isTruckOpen && (
          <TruckModal
            isOpen={isTruckOpen}
            toggle={this.toggleTruck}
            onClose={this.onTruckModalClose}
          />
        )}
        {isDriverOpen && (
          <DriverModal
            isOpen={this.state.isDriverOpen}
            toggle={this.toggleDriver}
            onClose={this.onDriverModalClose}
          />
        )}
        {isTrailerOpen && (
          <TrailerModal
            isOpen={this.state.isTrailerOpen}
            toggle={this.toggleTrailer}
            onClose={this.onTrailerModalClose}
          />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, values }) => {
            if (values.none) {
              values.other = false;
              values.tanker = false;
              values.doublesTriples = false;
              values.hazmat = false;
              values.xEndorsement = false;
              values.hazmatExp = '';
              values.endorsementExp = '';
            }
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Row></Row>
                  </Col>
                  <Col md={12}>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 5,
                      }}
                    ></div>
                  </Col>

                  <Col md={12}>
                    <Row
                      style={{
                        marginTop: 12,
                      }}
                    >
                      <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                        <h5
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          Drivers
                        </h5>
                      </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        <div>
                          {drivers.length < 10 ? (
                            <Button
                              style={{
                                width: 100,
                                borderWidth: 0.5,
                              }}
                              color='primary'
                              className='btn btn-primary btn-sm'
                              onClick={this.toggleDriver}
                            >
                              Add
                            </Button>
                          ) : (
                              <span
                                style={{
                                  fontSize: 10,
                                  color: 'red',
                                }}
                              >
                                Can't add more then 10
                            </span>
                            )}
                        </div>
                      </Col>
                    </Row>

                    {drivers.map((item, i) => {
                      return (
                        <DriverView
                          md={12}
                          drivers={item}
                          onClick={() =>
                            this.toggleConfirm({ item: item, type: 1 })
                          }
                        />
                      );
                    })}
                  </Col>

                  <Col md={12}>
                    <Row
                      style={{
                        marginTop: 12,
                      }}
                    >
                      <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                        <h5
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          Trailers
                        </h5>
                      </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        <div>
                          {trailers.length < 10 ? (
                            <Button
                              style={{
                                width: 100,
                                borderWidth: 0.5,
                              }}
                              color='primary'
                              className='btn btn-primary btn-sm'
                              onClick={this.toggleTrailer}
                            >
                              Add
                            </Button>
                          ) : (
                              <span
                                style={{
                                  fontSize: 10,
                                  color: 'red',
                                }}
                              >
                                Can't add more then 10
                            </span>
                            )}
                        </div>
                      </Col>
                    </Row>

                    {trailers.map((item, i) => {
                      return (
                        <TrailerView
                          md={12}
                          trailers={item}
                          onClick={() =>
                            this.toggleConfirm({ item: item, type: 3 })
                          }
                        />
                      );
                    })}
                  </Col>

                  <Col md={12}>
                    <Row
                      style={{
                        marginTop: 12,
                      }}
                    >
                      <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                        <h5
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          Trucks
                        </h5>
                      </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        <div>
                          {trucks.length < 10 ? (
                            <Button
                              style={{
                                width: 100,
                                borderWidth: 0.5,
                              }}
                              color='primary'
                              className='btn btn-primary btn-sm'
                              onClick={this.toggleTruck}
                            >
                              Add
                            </Button>
                          ) : (
                              <span
                                style={{
                                  fontSize: 10,
                                  color: 'red',
                                }}
                              >
                                Can't add more then 10
                            </span>
                            )}
                        </div>
                      </Col>
                    </Row>

                    {trucks.map((item, i) => {
                      return (
                        <TruckView
                          md={12}
                          trucks={item}
                          onClick={() =>
                            this.toggleConfirm({ item: item, type: 2 })
                          }
                        />
                      );
                    })}
                  </Col>
                </Row>
                {
                  isEdit ?
                    <Row style={STYLES.BUTTON_WRAPPER}>
                      <Col xs="6">
                        <button
                          onClick={onClose}
                          className="btn btn-warning btn-sm"
                          style={STYLES.BUTTON_BACK}
                        >
                          CANCEL
                                        </button>
                      </Col>
                      <Col xs="6">
                        <button
                          className="btn btn-primary btn-sm"
                          style={STYLES.BUTTON_NEXT}
                          type="submit"
                        >
                          SAVE
                                        </button>
                      </Col>
                    </Row>
                    :

                    <Row style={STYLES.BUTTON_WRAPPER}>
                      <Col xs='6'>
                        <Link
                          to={backUrl}
                          className='btn btn-warning btn-sm'
                          style={STYLES.BUTTON_BACK}
                        >
                          BACK (CONTACTS)
                    </Link>
                      </Col>
                      <Col xs='6'>
                        <button
                          className='btn btn-primary btn-sm'
                          style={STYLES.BUTTON_NEXT}
                          type='submit'
                        >
                          NEXT (HOS)
                    </button>
                      </Col>
                    </Row>
                }
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = _get(state, 'registrationData.license', {});
  return {
    business: _get(state, 'appData.business', {}),
    initialValues: {
      ...defaultValues,
      ...initialValues,
    },
    drivers: _get(state, 'carrierData.driverTypes', []),
    trucks: _get(state, 'carrierData.truckTypes', []),
    trailers: _get(state, 'carrierData.trailerTypes', []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateRegistration,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LicenseForm)
);
