import React from "react";
import { Row, Col } from "reactstrap";
import _get from "lodash/get";

export class ContentContainer extends React.Component {
    render() {
        const businessName = _get(this.props, "business.name");
        return (
            <div>
                <Row>
                    <Col
                        xs="12"
                        style={{
                            margin: "10px auto",
                            fontSize: 12,
                            fontWeight: 200
                        }}
                    >
                        <h5
                            className="text-center"
                            style={{ margin: "15px auto" }}
                        >
                            Carrier Application
                        </h5>
                        <p>
                            Thank you for your interest in {businessName}. To
                            apply for a partnership, please complete our online
                            application. Incomplete information will delay the
                            processing of your application or prevent it from
                            being submitted.
                        </p>
                        <p>
                            In compliance with Federal and State equal
                            employment opportunity laws, qualified applicants
                            are considered for all positions without regard to
                            race, color, religion, sex, national origin, age,
                            marital status, veteran status, non-job related
                            disability, or any other protected group status.
                        </p>
                        <h6>
                            To fill out this form, you will need to know the
                            following:
                        </h6>
                        <ul>
                            <li>
                                USDOT number with authorized operating status
                                and available for Hire.
                            </li>
                            <li>Valid Authority to Operate (MC Number)</li>
                            <li>Employer Identification Number</li>
                            <li>Minimum of atleast one power unit.</li>
                            <li>
                                Should have atleast minimum of $5,000 per
                                vehicle, $10,000 per occurrence for cargo
                                insurance and $75,000 for bond trust fund.
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentContainer;
