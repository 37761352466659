import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import { validate, defaultValues } from "./formHelpers";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from "../../../actions/registration/registrationActions";
import InputBox from "../../../components/InputBox/InputBox";

class MVRForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: ""
        };
    }
    rowStyle = {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 5
    }

    onSubmit = async values => {
        await this.props.updateRegistration({ mvr: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/hos`;
        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };

    render() {
        let { initialValues, isEdit, onClose } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/military`;

        const suspendedTxt = "Has any license, permit or privilege ever been denied, suspended or revoked for any reason?"
        const withoutlicenseTxt = "Have you ever been convicted of driving during license suspension or revocation, or driving without a valid license or an expired license, or are any charges pending?"
        const alcoholTxt = "Have you ever been convicted for any alcohol or controlled substance related offense while operating a motor vehicle, or are any charges pending?"
        const illegalTxt = "Have you ever been convicted for possession, sale or transfer of an illegal substance(including but not limited to, marijuana, amphetamines, or derivatives thereof) while on duty, or are any charges pending?"
        const recklessTxt = "Have you ever been convicted of reckless driving, careless driving or careless operation of a motor vehicle, or are any charges pending?"
        const alcoholTestTxt = "Have you ever tested positive, or refused to test on drug or alcohol test by an employer to whom you applied, but did not obtain safety - sensitive transportation work covered by DOT agency drug and alcohol testing rules in past three years, or have you ever tested positive or refused to test on any DOT - mandated drug or pre - employment alcohol test?"

        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Col>
                                    <InputCheckbox
                                        name={"suspended"}
                                        label={suspendedTxt}
                                        checked={values.suspended}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.suspended && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"suspendedDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"suspendedComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                    <InputCheckbox
                                        name={"withoutlicense"}
                                        label={withoutlicenseTxt}
                                        checked={values.withoutlicense}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.withoutlicense && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"withoutlicenseDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"withoutlicenseComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                    <InputCheckbox
                                        name={"alcohol"}
                                        label={alcoholTxt}
                                        checked={values.alcohol}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.alcohol && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"alcoholDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"alcoholComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                    <InputCheckbox
                                        name={"illegal"}
                                        label={illegalTxt}
                                        checked={values.illegal}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.illegal && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"illegalDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"illegalComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                    <InputCheckbox
                                        name={"reckless"}
                                        label={recklessTxt}
                                        checked={values.reckless}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.reckless && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"recklessDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"recklessComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                    <InputCheckbox
                                        name={"alcoholTest"}
                                        label={alcoholTestTxt}
                                        checked={values.alcoholTest}
                                        marginTop={isEdit?"25":"20"}
                                    />
                                    {values.alcoholTest && (
                                        <Row style={this.rowStyle}>
                                            <InputDatePicker
                                                md={4}
                                                type={"date"}
                                                name={"alcoholTestDate"}
                                                label={"Date"}
                                            />
                                            <InputBox
                                                md={8}
                                                name={"alcoholTestComments"}
                                                label={"Comments"}
                                            />
                                        </Row>
                                    )}
                                </Col>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (Millltary)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (HOS)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues,
            ..._get(state, "registrationData.mvr", {})
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MVRForm)
);