import React from "react";
import { Card, CardBody, CardSubtitle, Button } from "reactstrap";
import { pdfOptions } from "../../../../constants"
import html2pdf from "html2pdf.js";


class CertifyInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: false,
            sending: false,
            isOpen: false,
            isSubmitOpen: false,
            success: false,
            error: false
        };
    }
    onPrint = async () => {
        this.setState({
            downloading: true
        });
        const element = document.getElementById("info");
        pdfOptions.filename = "Certification of Information.pdf";
        await html2pdf()
            .set(pdfOptions)
            .from(element)
            .toPdf()
            .save();
        this.setState({
            downloading: false,
        });
    };


    render() {
        return (
            <div>
                <Card
                    style={{
                        fontSize: 12,
                        maxHeight: 200,
                        overflow: "scroll",
                        marginBottom: 15
                    }}
                >
                    <CardBody
                        id="info">
                        <CardSubtitle>
                            <h4 className="text-center">
                                Certification of Information
                        </h4>
                        </CardSubtitle>
                        <div style={{ textAlign: "justify" }}>
                            <p>You are hereby provided a summary of the following provisions of the Fair Credit Reporting Act, 15 U.S.C. 1681m(a):</p>
                            <ul>
                                <li>The following consumer reporting agencies (CRAs) will prepare the background report / consumer report for the Company:<br />
                                    {/* <Container>
                                        <Row>
                                            <Col></Col>
                                        </Row> */}
                                    <div>
                                        <br />
                                        HireRight, LLC
                                    3349 Michelson Drive, Suite 150<br />
                                        Irvine, CA 92612 <br />
                                        Phone: 866-521-6995<br />
                                        Fax: 877-797-3442<br />
                                        E-mail: customerservice@hireright.com<br />
                                        Web: http://www.hireright.com/applicants<br />
                                    </div>
                                    <br />
                                    <div>
                                        Tenstreet<br />
                                        120 W. 3rd Street<br />
                                        Tulsa, OK 74103<br />
                                        Phone: (877) 219-9283<br />
                                        Email: support@tenstreet.com<br />
                                        Web: http://www.tenstreet.com/drivers/<br />
                                    </div>
                                    <br />
                                    <div>
                                        For Pre-Employment Screening Program (PSP) and Clearinghouse:<br />
                                        U.S. Federal Motor Carrier Safety Administration, of the Department of Transportation, ("FMCSA-DOT")<br />
                                        1200 New Jersey Avenue SE<br />
                                        Washington, DC 20590<br />
                                        Phone: (800) 832-5660, TTY (800) 877-8339<br />
                                        https://dataqs.fmcsa.dot.gov/Default.aspx<br />
                                    </div>
                                    <br />
                                    {/* </Container> */}
                                </li>
                                <li className="html2pdf__page-break">No CRA utilized by the Company makes adverse decisions relating to your employment or contract relationship and no consumer reporting agency utilized by the Company is able to provide any specific reasons to you why an adverse decision relating to your employment or contract relationship may be taken or was taken based on a consumer report.</li>
                                <li>15 U.S.C. 1681j provides for the right to obtain a free copy of a consumer report on you from the consumer reporting agency which prepared your background report, under various circumstances, including but not limited to where you receive notice that an adverse action has been taken toward you based on the consumer report. In that instance, Section 1681j provides a right to a free copy of the report provided that you make the request within 60 days of the date that you received notice of the adverse action.</li>
                                <li>15 U.S.C. 1681i provides for the right to dispute, with a consumer reporting agency the accuracy or completeness of any information in a consumer report furnished by the agency.</li>
                            </ul>


                            <div className="text-center" style={{
                                marginBottom: 15,
                                marginTop: 15
                            }}>
                                {this.state.downloading ? null :
                                    <Button size="sm"
                                        disabled={this.state.downloading}
                                        onClick={this.onPrint}>
                                        PRINT
                                    </Button>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    };
}
export default CertifyInformation;
