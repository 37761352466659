import React from "react";
import { Progress } from "reactstrap";

export default function ProgressBar({ name, percentage }) {
    name = name || `${percentage}%`;
    return (
        <div style={{ padding: "5px 0px" }}>
            <Progress value={percentage}>{name}</Progress>
        </div>
    );
}
