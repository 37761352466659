import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Alert, Button } from "reactstrap";
import AddAddressModal from "./AddAddressModal";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { validate, defaultValues, isResident } from "./formHelpers";
import { COUNTRIES, US_STATES, CA_STATES, GENDER } from "../../../utils/constants";
import { SUFFIX, EDU_GRADES } from "../../../utils/constants";
import { getDays, formatDate } from "../../../utils/momentUtil";
import STYLES from "../STYLES";
import {
    updateRegistration,
    saveDriverPersonalAddress
} from "../../../actions/registration/registrationActions";
import { DRIVER_BASE_URL } from "../../../utils/constants";
import ConfirmationModal from "../../../modals/ConfirmationModal"

class PersonalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            errorMsg: "",
            successMsg: "",
            isConfirmOpen: false
        };
    }

    onSubmit = async values => {
        await this.props.updateRegistration({ personal: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/general`;
        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    onAddressModalClose = address => {
        let addresses = _get(this, "props.addresses", []);
        addresses.push(address);
        this.props.saveDriverPersonalAddress(addresses);
        this.props.updateRegistration({ personalAddress: addresses });
        this.toggle();
    };
    onAddressDelete = item => {
        let addresses = _get(this, "props.addresses", []);
        const items = addresses.filter(i => i !== item);
        this.props.saveDriverPersonalAddress(items);
        this.props.updateRegistration({ personalAddress: items });
        this.toggleConfirm({ item: null })
    };


    toggleConfirm = (item) => {
        this.setState({
            isConfirmOpen: !this.state.isConfirmOpen,
            item: item
        });
    };
    render() {
        const { addresses, isEdit, onClose } = this.props;
        const { isConfirmOpen, isOpen } = this.state;
        let { initialValues, business } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/requirement`;
        const businessName = business.name;
        const promotionsText = `Yes, I want to receive information and communications from ${businessName} concerning future opportunities or promotions.`;
        const communincationText = `Would you like to receive communication from ${businessName} via text message? By participating, you consent to receive text messages sent by an automatic telephone dialing system, which may contain recruiting/advertising messages. Consent to these terms is not a condition of being hired, contracted, or leased. You may opt out at any time by texting STOP to unsubscribe. You also agree that ${businessName}'s service provider receives in real time and logs your text messages with ${businessName}.`;
        const addressesArray = addresses.sort((a, b) => new Date(b.residenceFrom).getTime() - new Date(a.residenceFrom).getTime()).reverse();

        return (
            <div>
                {isConfirmOpen && (<ConfirmationModal
                    message={"Are you sure to delete this address?"}
                    isOpen={this.state.isConfirmOpen}
                    toggle={() => this.toggleConfirm({ item: null })}
                    onConfirm={() => this.onAddressDelete(this.state.item)}
                />)}
                {isOpen && (<AddAddressModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    onClose={this.onAddressModalClose}
                />)}
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={validate}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        const SEL_STATES =
                            values.address_country === "CA" ? CA_STATES : US_STATES;
                        const days = getDays(values.address_residenceSince);
                        const isPastAddress = days > 3 * 365;
                        if (isPastAddress) {
                            values.previousAddress = true
                            delete errors['previousAddress']
                            if (addresses.length > 0) {
                                this.props.saveDriverPersonalAddress([]);
                                this.props.updateRegistration({ personalAddress: [] });
                            }
                        }
                        if (!isPastAddress && isResident(values.address_residenceSince, addresses)) {
                            values.previousAddress = true
                            delete errors['previousAddress']
                        }
                        if (!isPastAddress && !isResident(values.address_residenceSince, addresses)) {
                            values.previousAddress = false
                            errors.previousAddress = "error"
                        }
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <SelectBox
                                        md={1}
                                        name={"suffix"}
                                        label={"Suffix"}
                                        options={SUFFIX}
                                        placeholder={"Select Suffix"}
                                    />
                                    <InputBox
                                        md={3}
                                        name={"firstName"}
                                        label={"First Name"}
                                        placeholder={"Enter First Name"}
                                    />
                                    <InputBox
                                        md={3}
                                        name={"middleName"}
                                        label={"Middle Name"}
                                        placeholder={"Enter Middle Name"}
                                    />
                                    <InputBox
                                        md={3}
                                        name={"lastName"}
                                        label={"Last Name"}
                                        placeholder={"Enter Last Name"}
                                    />
                                    <SelectBox
                                        md={2}
                                        name={"gender"}
                                        label={"Gender"}
                                        options={GENDER}
                                        placeholder={"Select Gender"}
                                    />
                                    <InputBox
                                        name={"ssn"}
                                        label={"SSN"}
                                        type={"number"}
                                        placeholder={"Enter SSN"}
                                    />

                                    <InputDatePicker
                                        name={"dob"}
                                        label={"Date of Birth"}
                                    />
                                    <InputBox
                                        name={"phone"}
                                        label={"Phone"}
                                        type={"number"}
                                        placeholder={"Enter Phone Number"}
                                    />

                                    <InputBox
                                        name={"email"}
                                        label={"Email"}
                                        placeholder={"john@example.com"}
                                    />
                                    <SelectBox
                                        md={"4"}
                                        name={"education"}
                                        label={"Highest grade completed"}
                                        options={EDU_GRADES}
                                    />
                                    <InputBox
                                        md={"8"}
                                        name={"lastSchool"}
                                        label={"Last school attended"}
                                        placeholder={
                                            "Last school attended (name, city, and state)"
                                        }
                                    />

                                    <SelectBox
                                        name={"address_country"}
                                        label={"Country"}
                                        options={COUNTRIES}
                                    />
                                    <SelectBox
                                        name={"address_state"}
                                        label={"State"}
                                        options={SEL_STATES}
                                    />
                                    <InputBox
                                        md={"6"}
                                        name={"address_street"}
                                        label={"Street address"}
                                        placeholder={"Type Street Address"}
                                    />
                                    <InputBox
                                        name={"address_line2"}
                                        label={"Apt"}
                                    />
                                    <InputBox
                                        name={"address_city"}
                                        label={"City"}
                                        placeholder={""}
                                    />

                                    <InputBox
                                        name={"address_postalCode"}
                                        label={"Postal Code"}
                                        type={"number"}
                                    />
                                    <InputDatePicker
                                        type={"date"}
                                        name={"address_residenceSince"}
                                        label={"Residence Since"}
                                        onDateChange={(date) => {
                                            const days = getDays(date);
                                            if (days > 3 * 365) {
                                                values.previousAddress = true
                                                this.props.saveDriverPersonalAddress([]);
                                                this.props.updateRegistration({ personalAddress: [] });
                                            } else {
                                                values.previousAddress = false
                                            }
                                        }}
                                    />
                                </Row>

                                {Array.isArray(addressesArray) &&
                                    addressesArray.map((item, i) => (
                                        <Alert
                                            key={i}
                                            color="info"
                                            style={
                                                {
                                                    width: "100%", marginTop: 10,
                                                    backgroundColor: "#F2F3F4", borderColor: "#E5E7E9"
                                                }
                                            }
                                        >
                                            <Row>
                                                <Col md={"3"}>
                                                    {`${item.street} `}
                                                    {`${item.city}, `}
                                                    {`${item.state}-${item.postalCode
                                                        }`}
                                                    {` ${item.country}`}
                                                </Col>
                                                <Col md={"4"}>
                                                    Residence from:-{" "}
                                                    {`${formatDate(
                                                        item.residenceFrom
                                                    )}`}
                                                </Col>
                                                <Col md={"4"}>
                                                    Residence to:-{" "}
                                                    {`${formatDate(
                                                        item.residenceTo
                                                    )}`}
                                                </Col>
                                                <Col md={"1"}>
                                                    <i
                                                        className="fa fa-trash fa-lg text-danger"
                                                        aria-hidden="true"
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            this.toggleConfirm(
                                                                item
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Alert>
                                    ))}

                                {errors.previousAddress && values.address_residenceSince && (
                                    <Row style={{ marginTop: 20 }}>
                                        <Col md={"8"} >
                                            <Alert
                                                color="danger"
                                                style={{ width: "100%" }}
                                            >
                                                We need to collect the previous
                                                3 year(s) of residence history.
                                                Please click on "ADD PAST
                                                ADDRESS" to add your past
                                                addresss
                                            </Alert>
                                        </Col>
                                        <Col md={"4"}>
                                            <Button
                                                style={{
                                                    ...STYLES.BUTTON_NEXT,
                                                    marginTop: 15
                                                }}
                                                onClick={this.toggle}
                                            >
                                                {"ADD PAST ADDRESS"}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                <Row style={{ marginTop: 20 }}>
                                    {/* <div id="wrapper" style ={{
                                        border: "1px solid #888",
                                        display: "flex",
                                        padding: "20px",
                                        margin: 0
                                    }}>
                                        <label for="yes_no_radio">Do you agree to the terms?</label>
                                        <p>
                                            <input type="radio" name="yes_no" checked>Yes</input>
                                        </p>
                                        <p>
                                            <input type="radio" name="yes_no">No</input>
                                        </p>
                                    </div> */}
                                    <InputCheckbox
                                        name={"promotions"}
                                        label={promotionsText}
                                        checked={values.promotions}
                                        marginTop={isEdit ? "25" : "20"}
                                    />
                                    <InputCheckbox
                                        name={"communincation"}
                                        label={communincationText}
                                        checked={values.communincation}
                                        marginTop={isEdit ? "25" : "20"}
                                    />
                                </Row>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    : <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (Requirement)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (General)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const initialValues = _get(state, "registrationData.personal", {});
    return {
        business: _get(state, "appData.business", {}),
        initialValues: {
            ...defaultValues,
            ...initialValues
        },
        addresses: _get(state, "registrationData.personalAddress", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { updateRegistration, saveDriverPersonalAddress },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PersonalForm)
);
