import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";


export class Information extends React.Component {
    render() {
        const { info } = this.props
        return (
            <Col xs="12" className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="General Information"
                        onClick={() => { this.props.onEdit("General") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name="Have you spoken to a recruiter?" value={info.recruiter ? "Yes" : "No"} />
                        {info.recruiter ?
                            <TableRow name="If yes, who?" value={info.recruiterName} />
                            : null}
                        <TableRow name="Date available to start?" value={formatDate(info.startDate)} />
                        <TableRow name="What position are you applying for?" value={info.position} />
                        <TableRow name="Are you applying for a Team Position?" value={info.teamPosition ? "Yes" : "No"} />
                        {info.teamPosition ?
                            <TableRow name="What is your Team Partner's name?" value={info.teamName} />
                            : null}
                        <TableRow name="Do you have a current TWIC card?" value={info.twicCard ? "Yes" : "No"} />
                        {/* <TableRow name="Expiration Date" value="" /> */}
                        <TableRow name="Are you able to read, write and speak English in compliance with FMCSRS?" value={info.fmcsr ? "Yes" : "No"} />
                        <TableRow name="How did you hear about us?" value={info.hearAboutUs} />
                        <TableRow name="Have you ever been known by any other name?" value={info.otherName ? "Yes" : "No"} />
                        {info.otherName ?
                            <TableRow name="If yes, what?" value={info.driversOtherName} />
                            : null}
                        <TableRow name="Are you legally eligible for employment in the United States?" value={info.eligible ? "Yes" : "No"} />
                        {/* <TableRow name="If you heard about us from, a Driver, an Employee, an Owner Operator, or aNewspaper please enter the name. If you chose Other, please explain." value="" /> */}
                    </tbody>
                </Table>
            </Col>
        );
    }
}

export default Information;
