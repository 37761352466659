import React from "react";
import { Col, Table, } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate, getDays } from "../../../utils/momentUtil";
import { formatPhoneNumber, formatSSN } from "../../../utils/Formator";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        const days = getDays(info.address_residenceSince);
        const isPast3 = days > 3 * 365;
        return (
            <Col xs="12">
                <Table bordered style={{ fontSize: "12px" }}>
                    <TableHeader name="Personal Information"
                        onClick={() => { this.props.onEdit("Personal") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name="Driver Name" value={info.firstName ? ((info.suffix ? info.suffix + " " : "") + info.firstName + " " + (info.middleName ? info.middleName + " " : "") + info.lastName) : ""} />
                        <TableRow name="Gender" value={info.gender} />
                        <TableRow name="Current Address" value={info.address_street} />
                        <TableRow name="City, State/Province Zip/Postal" value={info.address_city ? info.address_city + ", " + info.address_state + " " + info.address_postalCode : ""} />
                        <TableRow name="Residence Since" value={`${formatDate(info.address_residenceSince)}`} />
                        <TableRow name="Residence 3 years or longer" value={isPast3 ? "Yes" : "No"} />
                        <TableRow name="SSN/SIN" value={formatSSN(info.ssn)} />
                        <TableRow
                            name="Highest grade completed"
                            value={info.education}
                        />
                        <TableRow
                            name="Last school attended (name, city, and state)"
                            value={info.lastSchool}
                        />
                        <TableRow
                            name="Date of Birth"
                            value={`${formatDate(info.dob)}`}
                        />
                        <TableRow name="Phone" value={formatPhoneNumber(info.phone)} />
                        <TableRow name="Email" value={info.email} />
                    </tbody>
                </Table>
            </Col>
        );
    }
}

export default Information;
