import React from "react";
import { Row, Col } from "reactstrap";
import ContentForm from "./GeneralForm";
export class ContentContainer extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col
                        xs="12"
                        style={{
                            margin: "10px auto",
                            fontSize: 12,
                            fontWeight: 200
                        }}
                    >
                        <h5
                            className="text-center"
                            style={{ margin: "15px auto" }}
                        >
                            General Information
                        </h5>
                    </Col>
                    <Col md="12">
                        <ContentForm />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentContainer;
