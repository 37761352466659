import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./Header";
import _get from "lodash/get";
import { getBusiness } from "./actions/app/appAction";
import DisclaimerContainer from "./containers/Driver/DisclaimerContainer";
import RequirementContainer from "./containers/Driver/RequirementContainer";
import PersonalContainer from "./containers/Driver/PersonalContainer";
import GeneralContainer from "./containers/Driver/GeneralContainer";
import LicenseContainer from "./containers/Driver/LicenseContainer";
import MilitaryContainer from "./containers/Driver/MilitaryContainer";
import HistoryContainer from "./containers/Driver/HistoryContainer";
import HosContainer from "./containers/Driver/HosContainer";
import MVRContainer from "./containers/Driver/MVRContainer";
import FMCSRContainer from "./containers/Driver/FMCSRContainer";
import ReviewContainer from "./containers/Driver/ReviewContainer";
import GoogleSignIn from "./GoogleSignIn";
import GoogleSignOut from "./GoogleSignIn/SignOut";
import { DRIVER_BASE_URL } from "./utils/constants";

import "./App.css";

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            isReview: false
        };
    }

    hideHeader = () => {
        this.setState({
            isReview: true
        });
    };
    showHeader = () => {
        this.setState({
            isReview: false
        });
    };

    async componentDidMount() {
        const businessKey = _get(this, "props.match.params.businessKey");
        await this.props.getBusiness(businessKey);
    }

    toggleSignIn = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    render() {
        const { isSignInOpen } = this.props
        const wrapperStyles = {
            padding: "10px 25px",
            width: "100%",
            maxWidth: 700,
            margin: "10px auto",
            backgroundColor: "white",
            minHeight: 400,
            border: " 1px solid #e4e7ea",
            borderRadius: 10,
            display: "block"
        };
        const header =
            <div>
                {isSignInOpen ? null : <GoogleSignOut />}
                <Header business={this.props.business} />
                {isSignInOpen ? <GoogleSignIn /> : null}
            </div>
            ;

        return (
            <div className="app">
                <div className="" style={wrapperStyles}>
                    {header}
                    <div id="contentWrapper">
                        <Switch>
                            <Route path={`${DRIVER_BASE_URL}/:businessKey/disclaimer`}                            >
                                <DisclaimerContainer
                                    isSigned={!isSignInOpen}
                                    onClick={() => { }}
                                />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/requirement`}
                            >
                                <RequirementContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/personal`}
                            >
                                <PersonalContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/general`}
                            >
                                <GeneralContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/license`}
                            >
                                <LicenseContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/military`}
                            >
                                <MilitaryContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/history`}
                            >
                                <HistoryContainer />
                            </Route>
                            <Route path={`${DRIVER_BASE_URL}/:businessKey/hos`}>
                                <HosContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/fmcsr`}
                            >
                                <FMCSRContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/mvr`}
                            >
                                <MVRContainer />
                            </Route>
                            <Route
                                path={`${DRIVER_BASE_URL}/:businessKey/review`}
                            >
                                <ReviewContainer
                                    business={this.props.business}
                                    isSigned={!isSignInOpen}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", ""),
        isSignInOpen: _get(state, "appData.isSignInOpen", true)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBusiness
            // getRecruiting
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
