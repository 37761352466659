import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

export class Header extends Component {
    componentDidMount() {
    }
    componentDidUpdate() {
        const business = this.props.business || {};
        if (!business.name) {
            this.props.history.push("/")
        }
    }
    render() {
        const nameStyles = {
            marginBottom: 5
        };
        const addressStyles = {
            fontWeight: 200,
            fontSize: 12
        };
        const business = this.props.business || {};
        const src = business.logo || "https://osrit.com/images/logo_s.png";
        const name = business.name;
        const dot = business.dot;
        const phone = business.phone;
        const addressLine1 = business.line1;
        const addressLine2 = `${business.city} ${business.state}-${business.zip
            }`;

        return (
            <Row
                style={{
                    borderBottom: "1px solid lightgrey",
                    padding: "10px 0px",
                    marginTop: 10,
                    marginBottom: 10
                }}
            >
                <Col xs="4">
                    <img src={src} width="30" height="30" alt="" />
                </Col>
                <Col xs="8" className="text-right">
                    {dot && <h6 style={nameStyles}>DOT# {dot}</h6>}
                    <h6 style={nameStyles}>{name}</h6>
                    {addressLine1 && (
                        <div style={addressStyles}>{addressLine1}</div>
                    )}
                    {addressLine2 && (
                        <div style={addressStyles}>{addressLine2}</div>
                    )}

                    {phone && <div style={addressStyles}>Phone: {phone}</div>}
                </Col>
            </Row>
        );
    }
}

export default withRouter(Header);
