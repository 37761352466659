import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";
import { formatPhoneNumber } from "../../../utils/Formator";

export class Information extends React.Component {
    render() {
        const info_ = this.props.info[0]
        return (
            <Col xs={12} className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Training"
                        onClick={() => { this.props.onEdit("History") }}
                        status={this.props.status}
                    />
                    {this.props.info.length === 1 ?
                        <tbody>
                            <TableRow name="School Name" value={info_.schoolName} />
                            <TableRow name="Start Date" value={formatDate(info_.start)} />
                            <TableRow name="End Date" value={formatDate(info_.end)} />
                            <TableRow name="City, State/Province" value={info_.city + " " + info_.state} />
                            <TableRow name="Country" value={info_.country} />
                            <TableRow name="Phone" value={formatPhoneNumber(info_.schoolPhone)} />
                            <TableRow name="Instruction Hours" value={info_.instruction} />
                            <TableRow name="GPA" value={info_.gpa} />
                        </tbody>
                        :
                        this.props.info.map((item, i) => (
                            <tbody>
                                <TableRow name={"Training " + (i + 1)} value="##" />
                                <TableRow name="School Name" value={item.schoolName} />
                                <TableRow name="Start Date" value={formatDate(item.start)} />
                                <TableRow name="End Date" value={formatDate(item.end)} />
                                <TableRow name="City, State/Province" value={item.city + " " + item.state} />
                                <TableRow name="Country" value={item.country} />
                                <TableRow name="Phone" value={formatPhoneNumber(item.schoolPhone)} />
                                <TableRow name="Instruction Hours" value={item.instruction} />
                                <TableRow name="GPA" value={item.gpa} />
                            </tbody>
                        ))
                    }
                </Table>
            </Col>

        );
    }
}

export default Information;
