import React from "react";
import _get from "lodash/get";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from "../../../utils/constants";
import EmployementContent from "./EmployementContent";
import UnemployementContent from "./UnemployementContent";
import TrainingContent from "./TrainingContent";
import { connect } from "react-redux";

class HistoryContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            errorMsg: "",
            successMsg: ""
        };
    }

    onAddressModalClose = address => {
        let addresses = _get(this, "props.addresses", []);
        addresses.push(address);
        this.props.saveDriverPersonalAddress(addresses);
        this.props.updateRegistration({ personalAddress: addresses });
        this.toggle();
    };
    onAddressDelete = item => {
        let addresses = _get(this, "props.addresses", []);
        const items = addresses.filter(i => i !== item);
        this.props.saveDriverPersonalAddress(items);
        this.props.updateRegistration({ personalAddress: items });
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        const { isEdit, onClose } = this.props
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/license`;
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/military`;
        return (
            <div>
                <div>
                    <Row>
                        <Col
                            xs="12"
                            style={{ margin: "10px auto", fontSize: 12 }}
                        >
                            <h5
                                style={{
                                    marginTop: 5,
                                    marginBottom: 10,
                                    textAlign: "center"
                                }}
                            >
                                {"Driving History"}
                            </h5>
                        </Col>
                    </Row>
                </div>
                <EmployementContent />
                <UnemployementContent />
                <TrainingContent />

                {isEdit ?
                    <Row style={STYLES.BUTTON_WRAPPER}>
                        <Col xs="6">
                            <button
                                onClick={onClose}
                                className="btn btn-warning btn-sm"
                                style={STYLES.BUTTON_BACK}
                            >
                                CANCEL
                                        </button>
                        </Col>
                        <Col xs="6">
                            <button
                                className="btn btn-primary btn-sm"
                                style={STYLES.BUTTON_NEXT}
                                onClick={onClose}
                            >
                                SAVE
                                        </button>
                        </Col>
                    </Row> :
                    <Row style={STYLES.BUTTON_WRAPPER}>
                        <Col xs="6">
                            <Link
                                to={backUrl}
                                className="btn btn-warning btn-sm"
                                style={STYLES.BUTTON_BACK}
                            >
                                BACK (License)
                        </Link>
                        </Col>
                        <Col xs="6">
                            <Link
                                to={nextUrl}
                                className="btn btn-primary btn-sm"
                                style={STYLES.BUTTON_NEXT}
                            >
                                NEXT (Military)
                        </Link>
                        </Col>
                    </Row>}
            </div>
        );
    }
}
export default withRouter(connect()(HistoryContent));
