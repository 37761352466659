import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { } from "reactstrap";
import Personal from "../Driver/PersonalContainer/PesronalForm"
import General from "../Driver/GeneralContainer/GeneralForm"
import History from "../Driver/HistoryContainer/HistoryContent"
import Licenses from "../Driver/LicenseContainer/LicenseForm"
import USMilitary from "../Driver/MilitaryContainer/MilitaryForm"
import MVR from "../Driver/MVRContainer/MVRForm"
import FMCSR from "../Driver/FMCSRContainer/FMCSRForm"

import CarrierGeneral from '../Carrier/GeneralContainer/GeneralForm';
import Contacts from '../Carrier/ContactContainer/ContactForm';
import DriverEqup from '../Carrier/DriverEqipContainer/DriverEqipForm';
import HOS from '../Carrier/HosContainer/HosForm';
import More from '../Carrier/MoreDetailContainer/MoreDetailsForm';


class CommonEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  renderForm() {
    const { title, onClose, type } = this.props
    if (type === "driver") {
      if (title === "Personal") {
        return (<Personal isEdit={true} onClose={onClose} />)
      }
      else if (title === "General") {
        return (<General isEdit={true} onClose={onClose} />)
      }
      else if (title === "History") {
        return (<History isEdit={true} onClose={onClose} />)
      }
      else if (title === "License") {
        return (<Licenses isEdit={true} onClose={onClose} />)
      }
      else if (title === "Military") {
        return (<USMilitary isEdit={true} onClose={onClose} />)
      }
      else if (title === "MVR") {
        return (<MVR isEdit={true} onClose={onClose} />)
      }
      else if (title === "FMCSR") {
        return (<FMCSR isEdit={true} onClose={onClose} />)
      }
    } else {
      if (title === "General") {
        return (<CarrierGeneral isEdit={true} onClose={onClose} />)
      }
      else if (title === "HOS") {
        return (<HOS isEdit={true} onClose={onClose} />)
      }
      else if (title === "Contact") {
        return (<Contacts isEdit={true} onClose={onClose} />)
      }
      else if (title === "Driver and Eqipment") {
        return (<DriverEqup isEdit={true} onClose={onClose} />)
      }
      else if (title === "More") {
        return (<More isEdit={true} onClose={onClose} />)
      }
    }

  }
  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={{ minWidth: "60%" }}
        toggle={this.props.onClose}
        className={""}
      >
        <ModalHeader toggle={this.props.onClose}>Edit {this.props.title} Information</ModalHeader>
        <ModalBody style={{ padding: 30 }}>
          {this.renderForm()}
        </ModalBody>
      </Modal>
    );
  }
}


export default CommonEditModal