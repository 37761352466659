export const defaultValues = {
  usbond: '',
  cabond: '',
  scac: '',
  smcTariffCode: '',
  // isapproved: '',
  // csaapproved: '',
  // iscontract: '',
  // req1099: '',
  // ishazmat: '',
  // ishazmat: '',
};

export function validate(values) {
  let errors = {};
  // if (!values.usbond) {
  //   errors.usbond = 'Required.';
  // }
  // if (!values.cabond) {
  //   errors.cabond = 'Required.';
  // }
  // if (!values.scac) {
  //   errors.scac = 'Required.';
  // }
  // if (!values.smcTariffCode) {
  //   errors.smcTariffCode = 'Required.';
  // }
  // if (!values.isapproved) {
  //   errors.isapproved = 'Required.';
  // }
  // if (!values.csaapproved) {
  //   errors.csaapproved = 'Required.';
  // }
  // if (!values.iscontract) {
  //   errors.iscontract = 'Required.';
  // }
  // if (!values.req1099) {
  //   errors.req1099 = 'Required.';
  // }
  // if (!values.ishazmat) {
  //   errors.ishazmat = 'Required.';
  // }
  // if (!values.ishazmat) {
  //   errors.ishazmat = 'Required.';
  // }

  return errors;
}
