import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { capitalize } from '../../../utils/capitalize';

export class DriversInformation extends React.Component {
  render() {
    const info = this.props.info[0]
    return (
      <Col xs={12} className="html2pdf__page-break">
        <Table bordered style={{ fontSize: "12px" }} >
          <TableHeader name="Drivers Information"
            onClick={() => { this.props.onEdit("Driver and Eqipment") }}
            status={this.props.status}
          />
          {this.props.info.length === 1 ?
            <tbody>
              <TableRow name='License Type' value={info.license ? capitalize(info.license) : "N/A"} />
              <TableRow name='Count' value={info.count ? info.count : "N/A"} />
              <TableRow name='Year' value={info.year ? info.year : "N/A"} />
              {info.endorsements && <TableRow name='Endorsements' value={capitalize(info.endorsements)} />}
              {info.notes && <TableRow name='Notes' value={capitalize(info.notes)} />}
            </tbody>
            :
            this.props.info.map((item, i) => (
              <tbody>
                <TableRow name={capitalize(item.license)} value="##" />
                <TableRow name='Count' value={item.count ? item.count : "N/A"} />
                <TableRow name='Year' value={item.year ? item.year : "N/A"} />
                {item.endorsements && <TableRow name='Endorsements' value={capitalize(item.endorsements)} />}
                {item.notes && <TableRow name='Notes' value={capitalize(item.notes)} />}
              </tbody>
            ))
          }
        </Table>
      </Col>

    );

  }
}

export default DriversInformation;
