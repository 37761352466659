import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import DocumentsContent from './DocumentsContent';

import _get from 'lodash/get';
import ProgressBar from '../../../components/ProgressBar';

export class DocumentsContainer extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs="12">
            <ProgressBar percentage={100} />
          </Col>

          <Col xs='12'>
            <DocumentsContent />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: _get(state, 'app.business', {}),
  };
};
export default withRouter(connect(mapStateToProps, {})(DocumentsContainer));
