import React from 'react';
import { Col } from 'reactstrap';
import GeneralInformation from './GeneralInformation';
import PhysicalADDInformation from './PhysicalADDInformation';
import MailingADDInformation from './MailingADDInformation';
import ContactsInformation from './ContactsInformation';
import DriversInformation from './DriversInformation';
import TrailersInformation from './TrailersInformation';
import TrucksInformation from './TrucksInformation';
import HOSInformation from './HOSInformation';
import MoreDetailsInformation from './MoreDetailsInformation';
import { formatPhoneNumber } from '../../../utils/Formator';
import Heading from '../../../components/Heading/Heading';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import CommonEditModal from '../../common/CommonEditModal';

export class ReviewContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onEdit = (title) => {
    this.setState({ isEdit: true, title })
  }

  render() {
    const { data, business = {} } = this.props;
    let { status, businessKey } = this.props;
    const { isEdit, title } = this.state;
    let isSubmitted = false;
    if (data.status === 'SUBMITTED') {
      status = true;
      isSubmitted = true;
    }
    const url = `${CARRIER_BASE_URL}/${businessKey}/`;

    const details = {
      logo: business.logourl,
      name: business.name,
      address: `${business.line1} ${business.city}, ${business.state}-${business.zip}`,
      disContact: `Ph:- ${formatPhoneNumber(business.phone)}, Email:- ${business.email
        }`,
      invContact: `Ph:- ${business.invphone}, Email:- ${business.invemail}`,
    };

    return (
      <div>
        {isEdit &&
          <CommonEditModal
            isOpen={isEdit}
            title={title}
            type={"carrier"}
            onClose={() => { this.setState({ isEdit: false, title: "" }) }}
          />
        }

        <div>
          <Col xs='12' style={{ marginTop: 10 }}>
            <Heading md='12' details={details} />
          </Col>

        </div>
        <div>
          <div xs='12' style={{ margin: '10px auto', fontSize: 12 }}>
            <h5
              id='title'
              style={{
                padding: 10,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              {isSubmitted
                ? 'Your Application is under review'
                : status
                  ? 'Carrier Application'
                  : 'Review and Submit Your Info'}
            </h5>
          </div>
          <GeneralInformation info={data.general}
            onEdit={this.onEdit}
            url={url} status={status} />
          <PhysicalADDInformation
            info={data.physicalAddress}
            url={url}
            status={status}
            onEdit={this.onEdit}
          />
          <MailingADDInformation
            info={data.mailingAddress}
            url={url}
            onEdit={this.onEdit}
            status={status}
          />
          <MoreDetailsInformation
            info={data.moreDetails}
            url={url}
            onEdit={this.onEdit}
            status={status}
          />

          {data.contacts.length > 0 && (
            <div style={{ width: '100%' }}>
              <ContactsInformation
                info={data.contacts}
                url={url}
                onEdit={this.onEdit}
                status={status}
              />

            </div>
          )}
          {data.driverTypes.length > 0 && (
            <div style={{ width: '100%' }}>
              <DriversInformation
                info={data.driverTypes}
                url={url}
                onEdit={this.onEdit}
                status={status}
              />
            </div>
          )}
          {data.truckTypes.length > 0 && (
            <div style={{ width: '100%' }}>
              <TrucksInformation
                info={data.truckTypes}
                url={url}
                onEdit={this.onEdit}
                status={status}
              />

            </div>
          )}
          {data.trailerTypes.length > 0 && (
            <div style={{ width: '100%' }}>
              <TrailersInformation
                info={data.trailerTypes}
                url={url}
                onEdit={this.onEdit}
                status={status}
              />

            </div>
          )}
          {data.hos.length > 0 && (
            <div style={{ width: '100%' }}>
              <HOSInformation
                onEdit={this.onEdit}
                info={data.hos} url={url} status={status} />

            </div>
          )}

          {data.documents.length > 0 && (
            <div style={{ width: '100%' }}>
              {data.documents.map((item, i) => (
                ""))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ReviewContent;