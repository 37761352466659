const STYLES = {
  BUTTON_WRAPPER: {
    textAlign: 'center',
    marginTop: 10,
    padding: 10,
  },
  LABEL_TEXT: {
    textAlign: 'center',
    margin: '25px auto',
    textTransform: 'uppercase',
  },
  BUTTON_BACK: {
    fontSize: 12,
    fontWeight: 100,
  },
  BUTTON_NEXT: {
    fontSize: 12,
    fontWeight: 100,
    backgroundColor: '#03A9F4',
    borderColor: '#03A9F4',
    color: 'white',
  },
  BUTTON_DANGER: {
    fontSize: 12,
    fontWeight: 100,
  },
  BUTTON_MED: {
    minWidth: 150,
  },
};

export default STYLES;
