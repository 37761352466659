import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import LicenseContent from "./LicenseContent";
import ProgressBar from "../../../components/ProgressBar";
import _get from "lodash/get";

export class LicenseContainer extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ProgressBar percentage={30} />
                    </Col>
                    <Col xs="12">
                        <LicenseContent />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {})
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(LicenseContainer)
);
