import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import SelectBox from '../../../components/SelectBox/SelectBox';
import {
  TRAILER_TYPES
} from '../../../utils/constants';
import STYLES from '../STYLES';

const defaultValues = {
  trailerType: '',
  count: '',
  year: '',
  notes: '',
  none: '',
  hazmat: '',
  tanker: 'US',
  doubleTripple: '',
  xEndorsement: '',
  other: '',
};

function validate(values) {
  let errors = {};
  if (!values.trailerType) {
    errors.trailerType = 'Required.';
  }
  if (!values.count) {
    errors.count = 'Required.';
  }
  if (!values.year) {
    errors.year = 'Required.';
  }

  return errors;
}

class TrailerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = async (values) => {
    this.props.onClose(values);
  };

  render() {
    let { initialValues } = this.props;
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={''}
      >
        <ModalHeader toggle={this.props.toggle}>ADD TRAILER</ModalHeader>
        <ModalBody style={{ padding: 30 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={validate}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <SelectBox
                          name={'trailerType'}
                          options={TRAILER_TYPES}
                          md='4'
                          label='Trailer Type'
                        />
                        <InputBox md='4' name={'count'} label={'Count'} type="number" />
                        <InputBox md='4' name={'year'} label={'Year'} type="number" />
                        <InputBox md='12' name={'notes'} label={'Notes'} />
                      </Row>
                    </Col>
                    <Col className='text-center'>
                      <button
                        color='primary'
                        type='submit'
                        className='btn btn-primary btn-sm'
                        style={STYLES.BUTTON_NEXT}
                      >
                        {'SAVE TRAILER'}
                      </button>
                      <div className='text-center'>
                        <Button color='link' onClick={this.props.toggle}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
    },
    business: _get(state, 'appData.business', {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrailerModal);
