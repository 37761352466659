// https://github.com/shoaibkhan94/reactstrap-formik
import * as React from "react";
import _get from "lodash/get";

import { FormFeedback, Input, Label } from "reactstrap";

/*let handleBlur = event => {
    if (this.ignoreNextBlur === true) {
        // The parent components are relying on the bubbling of the event.
        event.stopPropagation();
        this.ignoreNextBlur = false;
        event.target.name = this.props.name;
        return;
    }
};*/

const ReactstrapSelectInput = ({
  field,
  form: { touched, errors, values },
  disabled = false,
  ...props
}) => {
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);

  //let error = errors[field.name];
  //let touch = touched[field.name];
  // let valid = values[field.name];

  return (
    <div>
      <Label for={props.inputprops.id} className={"label-color sr-only"}>
        {props.label}
      </Label>
      <Input
        id={props.inputprops.id}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touch && error)}
        placeholder={props.label}
        style={{ background: disabled ? "#d1dbe1" : "white" }}
        disabled={disabled}
      >
        <option value="">{props.inputprops.defaultOption}</option>
        {props.inputprops.options.map((option, index) => {
          const {
            prefix = "",
            selVal,
            selLabel,
            selLabelTwo,
            selLabelSmall,
            selLabelSmallTwo
          } = props.inputprops;

          const optionValue = option[selVal] || option['value'];
          const optionLabel = selLabelTwo
            ? `${prefix} ${option[selLabel]} ${option[selLabelTwo]}`
            : `${prefix} ${option[selLabel]}`;
          const optionLabelSm = selLabelSmallTwo
            ? `${option[selLabelSmall]} ${option[selLabelSmallTwo]}`
            : option[selLabelSmall]
              ? option[selLabelSmall]
              : "";

          return (
            <option value={optionValue} key={index}>
              {optionLabel}
              {optionLabelSm ? ` (${optionLabelSm})` : ""}
            </option>
          );
        })}
      </Input>
      {touch && error && <FormFeedback style={{ fontSize: "12px" }}>{error}</FormFeedback>}
    </div>
  );
};

export default ReactstrapSelectInput;
