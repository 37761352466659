import _get from "lodash/get";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import FMCSRContent from "./FMCSRContent";
import ProgressBar from "../../../components/ProgressBar";

export class FMCSRContainer extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ProgressBar percentage={95} />
                    </Col>
                    <Col xs="12">
                        <FMCSRContent />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {})
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(FMCSRContainer)
);
