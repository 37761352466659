import React from "react";
import { Formik } from "formik";
import { Row, Col, Button } from "reactstrap";
import STYLES from "../STYLES";

class Wizard extends React.Component {
    static Page = ({ children, parentState }) => {
        return children(parentState);
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues
        };
    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
        ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, bag) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            this.next(values);
            bag.setSubmitting(false);
        }
    };

    render() {
        const { children } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];

        const isLastPage = page === React.Children.count(children) - 1;
        return (
            <Formik
                initialValues={values}
                enableReinitialize={false}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                render={props => (
                    <form onSubmit={props.handleSubmit}>
                        {React.cloneElement(activePage, { parentState: { ...props } })}
                        <Row style={STYLES.BUTTON_WRAPPER} >
                            {/* {page > 0 && (
                                <Col xs="6">
                                    <Button

                                        type="button" onClick={this.previous}
                                        style={STYLES.BUTTON_BACK}
                                    >
                                        « Previous
                                    </Button>
                                </Col>
                            )} */}
                            {/* {page === 0 && (
                                <Col xs="6"> </Col>)} */}
                            {/* <Col xs="6"> </Col> */}
                            {!isLastPage && (
                                <Col xs="12">
                                    <Button type="submit"
                                        style={{ ...STYLES.BUTTON_NEXT, width: 200 }}
                                    >Next »</Button>
                                </Col>)}
                            {isLastPage && (
                                <Col xs="12">
                                    <Button type="submit" disabled={props.isSubmitting}
                                        style={{ ...STYLES.BUTTON_NEXT, width: 200 }}
                                    >
                                        Submit
                                        </Button>
                                </Col>
                            )}
                        </Row>
                    </form>
                )}
            />
        );
    }
}

export default Wizard
