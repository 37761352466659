
import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        return (
            <Col xs="12" className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Vehicle Accident Record"
                        onClick={() => { this.props.onEdit("License") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name="Type of Accident/ Incident" value={info.typeOfAccident} />
                        <TableRow name="Date of Accident / Incident" value={formatDate(info.accidentDate)} />
                        <TableRow name="Hazmat Accident/ Incident" value={info.hazmat ? "Yes" : "No"} />
                        <TableRow name="Was the vehicle towed away?" value={info.towed ? "Yes" : "No"} />
                        <TableRow name="City" value={info.address_city} />
                        <TableRow name="State/Province" value={info.state} />
                        <TableRow name="Were you in a commercial vehicle?" value={info.isCommercial ? "Yes" : "No"} />
                        <TableRow name="If yes, was this a Department of Transportation recordable accident? Were you at fault?" value={info.fault ? "Yes" : "No"} />
                        <TableRow name="Were you ticketed?" value={info.ticketed ? "Yes" : "No"} />
                        <TableRow name="Description" value={info.comments} />
                    </tbody>
                </Table>
            </Col>

        );
    }
}

export default Information;
