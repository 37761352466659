import React from "react";
import { Row, Col, Alert } from "reactstrap";
import { formatDate } from "../../../../utils/momentUtil";
class EmploymnetItem extends React.Component {
    render() {
        const { onDeleteClick, item } = this.props;
        return (
            <Alert
                color="info"
                style={{
                    width: "100%", marginTop: 10, fontSize: 12,
                    backgroundColor: "#F2F3F4", borderColor: "#E5E7E9"
                }}
            >
                <Row>
                    <Col md={"3"}>Start: {`${formatDate(item.start)}`}</Col>
                    <Col md={"3"}>End: {`${formatDate(item.end)}`}</Col>
                    <Col md={"5"}>Company: {item.companyName}</Col>

                    <Col md={"1"}>
                        <i
                            className="fa fa-trash fa-lg text-danger"
                            aria-hidden="true"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                onDeleteClick();
                            }}
                        />
                    </Col>
                </Row>
            </Alert>
        );
    }
}
export default EmploymnetItem;
