import axios from "../axios";
import axiosCarrier from "../axiosCarrier";
import _get from "lodash/get";
import * as TYPES from "./searchActionTypes";

export function setCarrier(value) {
    return { type: TYPES.SEARCH_CARRIER, value };
}

export function searchCarrier(params) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/onboarding/search`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            dispatch(setCarrier(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}


export function searchSafer({ mc, dot }) {
    return async (dispatch, getState) => {
        try {
            let params = {};
            if (mc) {
                params.mc = mc;
            } else if (dot) {
                params.dot = dot;
            }
            const response = await axiosCarrier({
                url: `/search`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            return _response;
        } catch (error) {
            return error;
        }
    };
}

