import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import ProgressBar from "../../../components/ProgressBar";
import _get from "lodash/get";
import HistoryContent from "./HistoryContent";

export class HistoryContainer extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ProgressBar percentage={40} />
                    </Col>
                    <Col xs="12">
                        <HistoryContent />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {})
    };
};
export default withRouter(connect(mapStateToProps, {})(HistoryContainer));