import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import STYLES from "../STYLES";
import ContentContainer from "./ContentContainer";
import _get from "lodash/get";
import { CARRIER_BASE_URL } from "../../../utils/constants";

export class DisclaimerContainer extends React.Component {
    render() {
        const businessKey = _get(this, "props.match.params.businessKey");
        const url = `${CARRIER_BASE_URL}/${businessKey}/requirement`;
        const cancelUrl = ``;
        const { isSigned } = this.props;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ContentContainer {...this.props} />
                    </Col>
                </Row>
                <Row style={STYLES.BUTTON_WRAPPER}>
                    <Col xs="6">
                        <Link
                            to={cancelUrl}
                            className="btn btn-warning btn-sm"
                            style={STYLES.BUTTON_BACK}
                        >
                            CANCEL
                        </Link>
                    </Col>
                    <Col xs="6">
                        {isSigned ? (
                            <Link
                                to={url}
                                className="btn btn-info btn-sm"
                                style={STYLES.BUTTON_NEXT}
                            >
                                NEXT(REQUIREMENTS)
                            </Link>
                        ) : (
                                <span>
                                    Click on Google sign in button to continue.
                                </span>
                            )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {})
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(DisclaimerContainer)
);
