import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import STYLES from '../STYLES';
import { isValidEmail } from '../../../utils/Formator';

const defaultValues = {
  provider: '',
  contact: '',
  phone: '',
  email: '',
};

function validate(values) {
  let errors = {};
  if (!values.provider) {
    errors.provider = 'Required.';
  }
  if (!values.contact) {
    errors.contact = 'Required.';
  }
  if (!values.phone) {
    errors.phone = 'Required.';
  }
  if (values.email && !isValidEmail) {
    errors.email = "Invalid email address.";
  }
  return errors;
}

class HosModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = async (values) => {
    this.props.onClose(values);
  };

  render() {
    let { initialValues } = this.props;
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={''}
      >
        <ModalHeader toggle={this.props.toggle}>ADD HOS</ModalHeader>
        <ModalBody style={{ padding: 30 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={validate}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col>
                          <Row>
                            <InputBox
                              md={3}
                              name={'provider'}
                              label={'Provider'}
                            />
                            <InputBox
                              md={3}
                              name={'contact'}
                              label={'Contact'}
                            />
                            <InputBox md={3} name={'phone'} label={'Phone'} type="number" />
                            <InputBox md={3} name={'email'} label={'Email'} />
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col className='text-center'>
                      <button
                        color='primary'
                        type='submit'
                        className='btn btn-primary btn-sm'
                        style={STYLES.BUTTON_NEXT}
                      >
                        {'SAVE HOS'}
                      </button>
                      <div className='text-center'>
                        <Button color='link' onClick={this.props.toggle}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
    },
    business: _get(state, 'appData.business', {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HosModal);
