import { isUnvalidDate } from "../../../../../utils/momentUtil";

export const defaultValues = {
    start: "",
    end: "",
    schoolName: "",
    schoolPhone: "",
    instruction: "",
    gpa: "",
    city: "",
    country: "US",
    state: ""
}
export function validate(values) {
    let errors = {};
    if (isUnvalidDate(values.start, values.end)) {
        errors.end = "Select valid date"
    }
    if (!values.start) {
        errors.start = "Required."
    }
    if (!values.end) {
        errors.end = "Required."
    }
    if (!values.schoolName) {
        errors.schoolName = "Required."
    }
    if (!values.schoolPhone) {
        errors.schoolPhone = "Required."
    } else if ((values.schoolPhone + "").length !== 10) {
        errors.schoolPhone = "Not valid."
    }
    if (!values.instruction) {
        errors.instruction = "Required."
    }
    if (!values.gpa) {
        errors.gpa = "Required."
    }
    if (!values.city) {
        errors.city = "Required."
    }
    if (!values.country) {
        errors.country = "Required."
    }
    if (!values.state) {
        errors.state = "Required."
    }
    console.log(errors)
    return errors;
}
