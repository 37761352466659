import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { updateRegistration } from "../../../actions/registration/registrationActions";
import TrainingModal from "./modals/TrainingModal/TrainingModal";
import TrainingItem from "./items/TrainingItem";
import ConfirmationModal from "../../../modals/ConfirmationModal"

class TrainingContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isConfirmOpen: false,
            errorMsg: "",
            successMsg: ""
        };
    }
    onModalClose = training => {
        let trainings = _get(this, "props.training", []);
        trainings.push(training);
        this.props.updateRegistration({ training: trainings });
        this.toggle();
    };
    onItemDelete = ({ item }) => {
        let trainings = _get(this, "props.training", []);
        const items = trainings.filter(i => i !== item);
        this.props.updateRegistration({ training: items });
        this.toggleConfirm({ item: null })
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
    toggleConfirm = ({ item }) => {
        this.setState({
            isConfirmOpen: !this.state.isConfirmOpen,
            item: item
        });
    };

    render() {
        const { training } = this.props;

        return (
            <div>
                <div>
                    <Row style={{
                        marginTop: 8
                    }}>
                        <Col style={{ fontSize: 10, marginTop: 2 }}>
                            <h5
                                style={{
                                    textAlign: "left",
                                    textDecorationColor: 'red'
                                }}
                            >
                                {"Add Training Details :"}
                            </h5>
                        </Col>
                        <Col sm="4" style={{ textAlign: "right" }}
                        >
                            <div
                            >
                                {training.length < 10 ?
                                    <button
                                        style={{

                                            width: 100,
                                            borderWidth: 0.5
                                        }}
                                        color="primary"
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                        onClick={this.toggle}
                                    >Add Details</button>
                                    : <span style={{
                                        fontSize: 10,
                                        color: "red"
                                    }}>
                                        Can't add more then 10
                                                        </span>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>

                <TrainingModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    onClose={this.onModalClose}
                />
                < ConfirmationModal
                    message={"Are you sure to delete this training?"}
                    isOpen={this.state.isConfirmOpen}
                    toggle={() => this.toggleConfirm({ item: null })}
                    onConfirm={() => this.onItemDelete({ item: this.state.item })}
                />


                {training.map((item, i) => (
                    <TrainingItem
                        onDeleteClick={() => this.toggleConfirm({ item: item })}
                        item={item}
                    />
                ))}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        training: _get(state, "registrationData.training", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ updateRegistration }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TrainingContent)
);
