import { isValidEmail } from "../../../utils/Formator";

export const defaultValues = {
  name: "",
  dot: "",
  mc: "",
  carrierType: "",
  businessType: "",
  email: "",
  phone: "",
  fax: "",
  website: "",
  physicalAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
  },
  mailingAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: 'US',
  }
};

export function validate(values) {
  let errors = {};
  if (!values.name) {
    errors.name = 'Required.';
  }
  if (!values.dot) {
    errors.dot = 'Required.';
  }
  if (!values.mc) {
    errors.mc = 'Required.';
  }
  if (!values.ein) {
    errors.ein = 'Required.';
  }
  if (values.email && !isValidEmail(values.email)) {
    errors.email = "Invalid email address.";
  }
  if (!values.businessType) {
    errors.businessType = 'Required.';
  }
  if (!values.phone) {
    errors.phone = 'Required.';
  }
  if (!values.carrierType) {
    errors.carrierType = 'Required.';
  }

  if (!values.physicalAddress.line1) {
    errors.physicalAddress = errors.physicalAddress || {}
    errors.physicalAddress.line1 = 'Required.';
  }
  if (!values.physicalAddress.city) {
    errors.physicalAddress = errors.physicalAddress || {}
    errors.physicalAddress.city = 'Required.';
  }
  if (!values.physicalAddress.state) {
    errors.physicalAddress = errors.physicalAddress || {}
    errors.physicalAddress.state = 'Required.';
  }
  if (!values.physicalAddress.zip) {
    errors.physicalAddress = errors.physicalAddress || {}
    errors.physicalAddress.zip = 'Required.';
  }
  if (!values.physicalAddress.country) {
    errors.physicalAddress = errors.physicalAddress || {}
    errors.physicalAddress.country = 'Required.';
  }

  if (!values.mailingAddress.line1) {
    errors.mailingAddress = errors.mailingAddress || {}
    errors.mailingAddress.line1 = 'Required.';
  }
  if (!values.mailingAddress.city) {
    errors.mailingAddress = errors.mailingAddress || {}
    errors.mailingAddress.city = 'Required.';
  }
  if (!values.mailingAddress.state) {
    errors.mailingAddress = errors.mailingAddress || {}
    errors.mailingAddress.state = 'Required.';
  }
  if (!values.mailingAddress.zip) {
    errors.mailingAddress = errors.mailingAddress || {}
    errors.mailingAddress.zip = 'Required.';
  }
  if (!values.mailingAddress.country) {
    errors.mailingAddress = errors.mailingAddress || {}
    errors.mailingAddress.country = 'Required.';
  }
  console.log(errors)
  return errors;
}
