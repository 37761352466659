import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import { validate, defaultValues } from './formHelpers';
import STYLES from '../STYLES';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from '../../../actions/carrier/carrierActions';

class MoreDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMsg: '',
      successMsg: '',
      isChecked: false,
    };
  }

  onSubmit = async (values) => {
    await this.props.updateRegistration({ moreDetails: values });
    const businessKey = _get(this, 'props.match.params.businessKey');
    const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/documents`;
    const { isEdit, onClose } = this.props
    if (isEdit) {
      onClose()
    } else {
      this.props.history.push(nextUrl);
    }
  };

  handleCLICK = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  render() {
    let { initialValues, nextUrl, isEdit, onClose } = this.props;
    const businessKey = _get(this, 'props.match.params.businessKey');
    const backUrl = `${CARRIER_BASE_URL}/${businessKey}/hos`;

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Row>
                      <InputBox md={3} name={'usbond'} label={'US Bond'} />
                      <InputBox md={3} name={'cabond'} label={'CA Bond'} />
                      <InputBox md={3} name={'scac'} label={'Scac'} />
                      <InputBox
                        md={3}
                        name={'smcTariffCode'}
                        label={'Smc Tariff Code'}
                      />
                    </Row>
                  </Col>
                </Row>
                {
                  isEdit ?
                    <Row style={STYLES.BUTTON_WRAPPER}>
                      <Col xs="6">
                        <button
                          onClick={onClose}
                          className="btn btn-warning btn-sm"
                          style={STYLES.BUTTON_BACK}
                        >
                          CANCEL
                                        </button>
                      </Col>
                      <Col xs="6">
                        <button
                          className="btn btn-primary btn-sm"
                          style={STYLES.BUTTON_NEXT}
                          type="submit"
                        >
                          SAVE
                                        </button>
                      </Col>
                    </Row>
                    :

                    <Row style={STYLES.BUTTON_WRAPPER}>
                      <Col xs='6'>
                        <Link
                          to={backUrl}
                          className='btn btn-warning btn-sm'
                          style={STYLES.BUTTON_BACK}
                        >
                          BACK(HOS)
                    </Link>
                      </Col>
                      <Col xs='6'>
                        <button
                          to={nextUrl}
                          className='btn btn-primary btn-sm'
                          style={STYLES.BUTTON_NEXT}
                          type='submit'
                        >
                          NEXT (DOCUMENTS)
                    </button>
                      </Col>
                    </Row>
                }
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
      ..._get(state, 'carrierData.moreDetails', {}),
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateRegistration,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MoreDetailsForm)
);
