import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../../../components/InputBox/InputBox";
import InputCheckbox from "../../../../../components/InputCheckbox/InputCheckbox";
import InputDatePicker from "../../../../../components/InputDatePicker/InputDatePicker";
import STYLES from "../../../STYLES";
import { defaultValues, validate } from "./formHelpers"
import { CA_STATES, US_STATES, COUNTRIES } from "../../../../../utils/constants";
import SelectBox from "../../../../../components/SelectBox/SelectBox";

class TrainingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        let { initialValues } = this.props;
        const graduateTxt = "Did you graduate?";
        const federalMotorTxt =
            "Were you subject to the Federal Motor Carrier or Transport Canada Safety Regulations while attending this truck school?";
        const sensitiveTxt =
            "Did you perform any safety sensitive functions at this truck school, regulated by DOT, and subject to drug and alcohol testing?";
        const skillTxt =
            "Which of the following skills were trained in your program? (select all that apply)";

        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD TRAINING DETAILS
                </ModalHeader>
                <ModalBody style={{ padding: 30 }}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            const SEL_STATES =
                                values.country === "CA" ? CA_STATES : US_STATES
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <React.Fragment>
                                            <InputDatePicker
                                                type={"date"}
                                                name={"start"}
                                                label={"Training start"}
                                            />
                                            <InputDatePicker
                                                type={"date"}
                                                name={"end"}
                                                label={"Training end"}
                                            />
                                            <InputBox
                                                name={"schoolName"}
                                                label={"School name"}
                                                placeholder={"Enter School Name"}
                                            />
                                            <InputBox
                                                name={"schoolPhone"}
                                                label={"School phone number"}
                                                isPhone={true}
                                                type={"number"}

                                            />
                                            <InputBox
                                                name={"instruction"}
                                                label={"Hours of Instruction"}
                                                placeholder={"Enter Hours of Instruction"}
                                            />
                                            <InputBox
                                                md={"2"}
                                                name={"gpa"}
                                                label={"GPA"}
                                                placeholder={"Enter GPA"}
                                            />
                                            <InputBox
                                                name={"city"}
                                                label={"Training school city"}
                                                placeholder={"Enter Training school city"}
                                            />
                                            <SelectBox
                                                md={"2"}
                                                name={"country"}
                                                label={"Country"}
                                                options={COUNTRIES}
                                                marginTop="25"
                                            />
                                            <SelectBox
                                                md={"2"}
                                                name={"state"}
                                                label={"State"}
                                                options={SEL_STATES}
                                                marginTop="25"
                                            />
                                            <Row
                                                md={"12"}
                                                style={{
                                                    marginTop: 10,
                                                    width: "100%"
                                                }}
                                            />
                                            <InputCheckbox
                                                marginTop={22}
                                                name={"isGraduate"}
                                                label={graduateTxt}
                                                checked={values.isGraduate}
                                            />
                                            <InputCheckbox
                                                marginTop={22}
                                                name={"isFederalMotorTxt"}
                                                label={federalMotorTxt}
                                                checked={values.isFederalMotorTxt}
                                            />
                                            <InputCheckbox
                                                name={"isSensitiveTxt"}
                                                marginTop={22}
                                                label={sensitiveTxt}
                                                checked={values.isSensitiveTxt}
                                            />
                                            <h5>{skillTxt}</h5>
                                            <InputCheckbox
                                                marginTop={22}
                                                md={6}
                                                name={"borderCrossing"}
                                                label={"Border Crossing"}
                                                checked={values.borderCrossing}
                                            />
                                            <InputCheckbox
                                                marginTop={22}
                                                md={6}
                                                name={"logBooks"}
                                                label={"Log Books"}
                                                checked={values.logBooks}
                                            />

                                            <InputCheckbox
                                                md={6}
                                                marginTop={22}
                                                name={"federalRegulations"}
                                                label={"Federal Motor Carrier Regulations"}
                                                checked={values.federalRegulations}
                                            />
                                            <InputCheckbox
                                                marginTop={22}
                                                md={6}
                                                name={"hazardousMaterials"}
                                                label={"Hazardous Materials"}
                                                checked={values.hazardousMaterials}
                                            />
                                        </React.Fragment>
                                    </Row>
                                    <Col className="text-center">
                                        <button
                                            color="primary"
                                            type="submit"
                                            className="btn btn-primary btn-sm"
                                            style={STYLES.BUTTON_NEXT}
                                        >
                                            {"SAVE TRAINING"}
                                        </button>
                                        <div className="text-center">
                                            <Button
                                                color="link" onClick={this.props.toggle}>
                                                Cancel
                                                </Button>
                                        </div>
                                    </Col>

                                </Form>
                            );
                        }}
                    </Formik>

                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingModal);
