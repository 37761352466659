import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import InputRadiaButton from "../../../components/InputRadiaButton/InputRadiaButton";
// import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { validate, defaultValues } from "./formHelpers";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL, RADIOBTN_VALS } from '../../../utils/constants';
import { updateRegistration } from "../../../actions/registration/registrationActions";
// import Label from "reactstrap/lib/Label";


const BTN = {
    design: {
        color: "#dc3545",
        fontSize: "12px"
    }
};
class AddCompanyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: ""
        };
    }

    onSubmit = async values => {
        await this.props.updateRegistration({ general: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/license`;
        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };

    render() {
        let { initialValues, isEdit, onClose } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/personal`;

        const recruiterText = `Have you spoken to a recruiter?`;
        const teamPositionText = `Are you applying for a Team Position?`;
        const twicCardText = `Do you have a current TWIC card?`;
        const fmcsrText = `Are you able to read, write and speak English in compliance with FMCSRs?`;
        const otherNameText = "Have you ever been known by any other name?";
        const eligibleText = "Are you legally eligible for employment in the United States?";

        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>

                                    <Col md="12">
                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"recruiter"}
                                            label={recruiterText}
                                            options={RADIOBTN_VALS}
                                        />
                                        {errors.recruiter ? <p style={BTN.design}>{errors.recruiter}</p> : null}
                                        {values.recruiter ?
                                            (<InputBox
                                                md={12}
                                                name={"recruiterName"}
                                                label={"If yes, who?"}
                                                marginTop={isEdit ? "25" : "20"}
                                            />)
                                            : null}
                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"teamPosition"}
                                            label={teamPositionText}
                                            options={RADIOBTN_VALS}
                                        />
                                        {errors.teamPosition ? <p style={BTN.design}>{errors.teamPosition}</p> : null}

                                        {values.teamPosition ?
                                            (<InputBox
                                                md={12}
                                                name={"teamName"}
                                                label="What is your Team Partner's name?"
                                                marginTop={isEdit ? "25" : "20"}
                                            />)
                                            : null}
                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"twicCard"}
                                            label={twicCardText}
                                            options={RADIOBTN_VALS}
                                        />
                                        {errors.twicCard ? <p style={BTN.design}>{errors.twicCard}</p> : null}
                                        {/* <InputCheckbox
                                            name={"twicCard"}
                                            label={twicCardText}
                                            checked={values.twicCard}
                                            marginTop={isEdit ? "25" : "20"}
                                        /> */}
                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"fmcsr"}
                                            label={fmcsrText}
                                            options={RADIOBTN_VALS}
                                        /> {errors.fmcsr ? <p style={BTN.design}>{errors.fmcsr}</p> : null}

                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"otherName"}
                                            label={otherNameText}
                                            options={RADIOBTN_VALS}
                                        />{errors.otherName ? <p style={BTN.design}>{errors.otherName}</p> : null}
                                        {values.otherName ?
                                            (<InputBox
                                                md={12}
                                                name={"driversOtherName"}
                                                label={"If yes, what?"}
                                                marginTop={isEdit ? "25" : "20"}
                                            />)
                                            : null}

                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"eligible"}
                                            label={eligibleText}
                                            options={RADIOBTN_VALS}
                                        /> {errors.eligible ? <p style={BTN.design}>{errors.eligible}</p> : null}

                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <InputBox
                                                md={6}
                                                name={"position"}
                                                label={"What position are you applying for?"}
                                            />
                                            <InputDatePicker
                                                md={6}
                                                onlyFutureDate={true}
                                                type={"date"}
                                                name={"startDate"}
                                                label={"Date available to start? "}
                                            />
                                        </Row>
                                        <Row>
                                            <InputBox
                                                md={12}
                                                name={"hearAboutUs"}
                                                label={"How did you hear about us?"}
                                            />

                                        </Row>
                                        <Row>
                                            <InputBox
                                                md={12}
                                                name={"drivingAwards"}
                                                label={
                                                    "Please list any safe driving awards you have received?"
                                                }
                                            />
                                            <InputBox
                                                md={12}
                                                name={"customerServiceAwards"}
                                                label={
                                                    "Please list any customer service awards you have received?"
                                                }
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (Personal)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (License)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues,
            ..._get(state, "registrationData.general", {})
        },
        business: _get(state, "appData.business", {}),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddCompanyForm)
);
