import React from "react";
import { Row, Col } from "reactstrap";
import PesronalForm from "./PesronalForm";

export class PersonalContent extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            style={{
                                marginTop: 5,
                                marginBottom: 10,
                                textAlign: "center"
                            }}
                        >
                            {"Personal information"}
                        </h5>
                        <PesronalForm />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PersonalContent;
