import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../../../components/InputBox/InputBox";
import InputDatePicker from "../../../../../components/InputDatePicker/InputDatePicker";
import STYLES from "../../../STYLES";
import { defaultValues, validate } from "./formHelpers"

class UnemploymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        let { initialValues } = this.props;
        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD UNEMPLOYMENT DETAILS
                </ModalHeader>
                <ModalBody
                    style={{ padding: 30 }}
                >
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <React.Fragment>
                                            <InputDatePicker
                                                md={3}
                                                type={"date"}
                                                name={"start"}
                                                label={"Unemployment start"}
                                            />
                                            <InputDatePicker
                                                type={"date"}
                                                md={3}
                                                name={"end"}
                                                label={"Unemployment end"}
                                            />
                                            <InputBox
                                                md={6}
                                                name={"comments"}
                                                label={"Comments"}
                                                placeholder={"Enter Comments"}
                                            />
                                        </React.Fragment>

                                        <Col style={{
                                            marginTop: 10,
                                            marginBottom: 5
                                        }}
                                            className="text-center">
                                            <button
                                                color="primary"
                                                type="submit"
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                            >
                                                {"SAVE UNEMPLOYMENT"}
                                            </button>
                                            <div className="text-center">
                                                <Button
                                                    color="link" onClick={this.props.toggle}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>

                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnemploymentModal);
