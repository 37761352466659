import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { capitalize } from '../../../utils/capitalize';

export class MailingADDInformation extends React.Component {
  render() {
    const { info } = this.props;
    return (
      <Col xs='12' >
        <Table bordered style={{ fontSize: '12px' }}>
          <TableHeader name='Mailing Address'
            onClick={() => { this.props.onEdit("General") }}
            status={this.props.status}
          />

          <tbody>
            <TableRow
              name='Line 1'
              value={info.line1 ? capitalize(info.line1) : "N/A"}
            />
            <TableRow
              name='Line 2'
              value={info.line2 ? capitalize(info.line2) : "N/A"}
            />
            <TableRow
              name='City'
              value={info.city ? capitalize(info.city) : "N/A"}
            />
            <TableRow
              name='State'
              value={info.state ? capitalize(info.state) : "N/A"}
            />
            <TableRow name='zip' value={info.zip ? info.zip : "N/A"} />
            <TableRow
              name='Country'
              value={info.country ? capitalize(info.country) : "N/A"}
            />
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default MailingADDInformation;
