import React from "react";
import { Field } from "formik";
import { Row, Col } from "reactstrap";
// import { values } from "pdf-lib";

const STYLES = {
    formRow: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    label: {
        display: "block",
        marginTop: 5,
        marginBottom: 0,
        lineHeight: 1.5,
        fontSize: "14px",
    },
    labelRadio: {
        display: "block",
        marginLeft: 20,
        marginTop: -23,
        fontSize: "14px",
        paddingRight: "0"
    }
};

const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    options,
    id,
    label,
    xsInput = 12,
    mdInput,
    className,
    disabled,
    ...props
}) => {
    return (
        <Row>
            {options.map((item, i) => (
                <Col key={i} xs={xsInput} md={mdInput}>
                    <input
                        name={name}
                        id={id}
                        type="radio"
                        value={item.id.toString()}
                        checked={value.toString() === item.id.toString()}
                        disabled={disabled}
                        onChange={e => {
                            // onChange(e === "true");÷=
                            // props.onChange(e === "true");
                            setFieldValue(name, e.target.value === "true")
                        }}
                    />
                    <label htmlFor={item.id.toString()} style={STYLES.labelRadio}>
                        {item.label}
                    </label>
                </Col>
            ))}
        </Row>
    );
};

const InputDatePicker = ({
    name,
    label,
    disabled,
    text,
    hint,
    options,
    type = "text",
    xs = "12",
    md = "12",
    mdInput = "12",
    labelClass = "",
    onChange = () => { }
}) => {
    return (
        <Col xs={xs} md={md} style={STYLES.formRow}>
            <div style={{ marginBottom: "5px" }}>
                <label htmlFor={name} style={STYLES.label} className="radio-label">
                    {label}
                </label>
            </div>
            <Field
                disabled={disabled}
                type={type}
                name={name}
                label={label}
                text={text}
                hint={hint}
                options={options}
                mdInput={mdInput}
                labelClass={labelClass}
                component={Checkbox}
                onChange={onChange}
            />
        </Col>
    );
};

export default InputDatePicker;