import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { formatPhoneNumber } from '../../../utils/Formator';
import { capitalize } from '../../../utils/capitalize';

export class ContactsInformation extends React.Component {
  render() {
    const info = this.props.info[0]
    return (
      <Col xs={12} className="html2pdf__page-break">
        <Table bordered style={{ fontSize: "12px" }}        >
          <TableHeader name="Contact Information"
            onClick={() => { this.props.onEdit("Contacts") }}
            status={this.props.status}
          />
          {this.props.info.length === 1 ?
            <tbody>
              <TableRow name='First Name' value={info.firstname ? capitalize(info.firstname) : "N/A"} />
              <TableRow name='Last Name' value={info.lastname ? capitalize(info.lastname) : "N/A"} />
              <TableRow name='Role' value={info.role ? capitalize(info.role) : "N/A"} />
              <TableRow name='Mobile' value={info.mobile ? formatPhoneNumber(info.mobile) : "N/A"} />
              <TableRow name='Email' value={info.email ? info.email : "N/A"} />
              {info.area && (<TableRow name='Area' value={capitalize(info.area)} />)}
              {info.fax && < TableRow name='Fax' value={formatPhoneNumber(info.fax)} />}
              {info.notes && <TableRow name='Notes' value={capitalize(info.notes)} />}
            </tbody>
            :
            this.props.info.map((item, i) => (
              <tbody>
                <TableRow name={capitalize(item.role)} value="##" />
                <TableRow name='First Name' value={item.firstname ? capitalize(item.firstname) : "N/A"} />
                <TableRow name='Last Name' value={item.lastname ? capitalize(item.lastname) : "N/A"} />
                <TableRow name='Mobile' value={item.mobile ? formatPhoneNumber(item.mobile) : "N/A"} />
                <TableRow name='Email' value={item.email ? item.email : "N/A"} />
                {item.area && (<TableRow name='Area' value={capitalize(item.area)} />)}
                {item.fax && < TableRow name='Fax' value={formatPhoneNumber(item.fax)} />}
                {item.notes && <TableRow name='Notes' value={capitalize(item.notes)} />}
              </tbody>
            ))
          }
        </Table>
      </Col>

    );
  }
}

export default ContactsInformation;
