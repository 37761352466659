import _get from "lodash/get";
import axios from "./axios";
import * as TYPES from "./appActionTypes";
import { getRegistration as getDriverRegistration } from "../registration/registrationActions";
import { getRegistration as getCarrierRegistration } from "../carrier/carrierActions";

export const setBusiness = value => ({
    type: TYPES.BUSINESS,
    value
});

export const setRecruitingType = value => ({
    type: TYPES.RECRUITING_TYPE,
    value
});

export const setGoogleUser = value => ({
    type: TYPES.GOOGLE_USER,
    value
});

//osritapi.com/api/businesses/public/AsritsolutionsLLC?access_token=rXTHzWvJTE8LWlwau5kl5EnaOLkuoxREKwnqRZK9C7zHG4shgTQqOqvDcZ9kMkAJ

export function getBusiness(dot, mc) {
    return async (dispatch, getState) => {
        let params = {};
        if (dot) {
            params.dot = dot;
            dispatch(setRecruitingType("DRIVER"));
        } else if (mc) {
            dispatch(setRecruitingType("CARRIER"));
            params.mc = mc;
        }
        try {
            const response = await axios({
                url: `/businesses/search`,
                method: "get",
                params
            });
            const _response = response.data;

            dispatch(setBusiness(_response));
            return _response;
        } catch (e) {
            return e;
        }
    };
}

export function updateGoogleUser(user) {
    return async (dispatch, getState) => {
        try {
            const recruitingType = _get(getState(), "appData.recruitingType");
            dispatch(setGoogleUser(user));
            if (recruitingType === "CARRIER") {
                dispatch(getCarrierRegistration());
            } else if (recruitingType === "DRIVER") {
                dispatch(getDriverRegistration());
            }
            return user;
        } catch (e) {
            return e;
        }
    };
}

// export function fetchApplicationHistory(key) {
//     return async (dispatch, getState) => {
//         // try {
//         return true;
//         // } catch (e) {
//         //     return e;
//         // }
//     };
// }
