import React from "react";
import { Card, CardBody, CardSubtitle, Button } from "reactstrap";
import { pdfOptions } from "../../../../constants"
import html2pdf from "html2pdf.js";

class CertifyInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: false,
            sending: false,
            isOpen: false,
            isSubmitOpen: false,
            success: false,
            error: false
        };
    }
    onPrint = async () => {
        this.setState({
            downloading: true
        });
        //const { filename } = this.props;
        const element = document.getElementById("info");
        pdfOptions.filename = "Certification of Information.pdf";
        await html2pdf()
            .set(pdfOptions)
            .from(element)
            .toPdf()
            .save();
        this.setState({
            downloading: false,
        });
    };


    render() {
        return (
            <div>
                <Card
                    style={{
                        fontSize: 12,
                        maxHeight: 200,
                        overflow: "scroll",
                        marginBottom: 15
                    }}
                >
                    <CardBody
                        id="info">
                        <CardSubtitle>
                            <h4 className="text-center">
                                Certification of Information
                        </h4>
                        </CardSubtitle>
                        <div style={{ textAlign: 'justify' }}>
                            <div>
                                Para información en español, visite <a href="https://www.consumerfinance.gov/learnmore/">
                                    www.consumerfinance.gov/learnmore </a>
                                o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W.,
                               Washington, DC 20552.
                       <h6 className="text-center">
                                    A Summary of Your Rights Under the Fair Credit Reporting Act
                                             </h6>
                                <div> The federal Fair Credit Reporting Act (FCRA) promotes the accuracy,
                                fairness, and privacy of information in the files of consumer reporting
                                agencies. There are many types of consumer reporting agencies, including
                                credit bureaus and specialty agencies (such as agencies that sell information
                                about check writing histories, medical records, and rental history records).
                        Here is a summary of your major rights under the FCRA. <b>For more information,
                                    including information about additional rights, go to <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore </a>
                                        or write to: Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.</b></div>

                                <ul style={{ marginTop: 15, textAlign: "justify" }}>
                                    <li>
                                        <b>You must be told if information in your file has been used
                                against you.</b> Anyone who uses a credit report or another type
                                                                                                                                        of consumer report to deny your application for credit, insurance,
                                                                                                                                        or employment - or to take another adverse action against you - must
                                                                                                                                        tell you, and must give you the name, address, and phone number of the
                                                                                                                                        agency that provided the information.
                            </li>
                                    <li>
                                        By providing us with your personal data,
                                        including sensitive personal data such as on
                                        your health and personal circumstances.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>You have the right to know what is in your file.</b> You may
                                        request and obtain all the information about you in the
                                        files of a consumer reporting agency (your "file disclosure").
                                        You will be required to provide proper identification, which may
                                        include your Social Security number. In many cases, the disclosure
                                        will be free. You are entitled to a free file disclosure if:

                               <ul> <li
                                        >a person has taken adverse action against you because of
                                   information in your credit report;</li>
                                            <li> you are the victim of identity theft and place a fraud alert in your file;</li>
                                            <li>  your file contains inaccurate information as a result of fraud;</li>
                                            <li> you are on public assistance;</li>
                                            <li> you are unemployed but expect to apply for employment within 60 days.</li></ul>
                                    </li>
                                    <li
                                        className="html2pdf__page-break"
                                        style={{
                                            marginTop: 1,
                                            textAlign: "justify"
                                        }}>
                                        In addition, all consumers are entitled to one free disclosure
                                        every 12 months upon request from each nationwide credit bureau
                                        and from nationwide specialty consumer reporting agencies.
                                        See <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore </a> for additional information.
                            </li>
                                    <li
                                        style={{
                                            marginTop: 1,
                                            textAlign: "justify"
                                        }}>
                                        <b>You have the right to ask for a credit score.</b> Credit scores are
                                        numerical summaries of your credit-worthiness based on information
                                        from credit bureaus. You may request a credit score from consumer
                                        reporting agencies that create scores or distribute scores used in
                                        residential real property loans, but you will have to pay for it.
                                        In some mortgage transactions, you will receive credit score information
                                        for free from the mortgage lender.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>You have the right to dispute incomplete or inaccurate information.</b>
                                        If you identify information in your file that is incomplete or
                                        inaccurate, and report it to the consumer reporting agency,
                                        the agency must investigate unless your dispute is frivolous.
                                        See <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a> for an explanation of dispute procedures.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>Consumer reporting agencies must correct or delete inaccurate,
                                incomplete, or unverifiable information.</b> Inaccurate, incomplete,
                                                                                                                                        or unverifiable information must be removed or corrected, usually
                                                                                                                                        within 30 days. However, a consumer reporting agency may continue
                                                                                                                                        to report information it has verified as accurate.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>Consumer reporting agencies may not report outdated negative
                                information.</b> In most cases, a consumer reporting agency may not
                                                                                                                                        report negative information that is more than seven years old, or
                                                                                                                                        bankruptcies that are more than 10 years old.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>Access to your file is limited.</b> A consumer reporting agency may
                                        provide information about you only to people with a valid need – usually
                                        to consider an application with a creditor, insurer, employer, landlord,
                                        or other business. The FCRA specifies those with a valid need for access.
                            </li>
                                    <li style={{
                                        marginTop: 1,
                                        textAlign: "justify"
                                    }}>
                                        <b>You must give your consent for reports to be provided to employers.</b>
                                        A consumer reporting agency may not give out information about you
                                        to your employer, or a potential employer, without your written consent
                                        given to the employer. Written consent generally is not required in
                                        the trucking industry. For more information, go to <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore.</a>
                                    </li>
                                    <li
                                        className="html2pdf__page-break"
                                        style={{
                                            marginTop: 1,
                                            textAlign: "justify"
                                        }}>
                                        <b> You may limit "prescreened" offers of credit and insurance you get
                                based on information in your credit report.</b> Unsolicited “prescreened”
                                                                                                                                        offers for credit and insurance must include a toll-free phone number
                                                                                                                                        you can call if you choose to remove your name and address form the
                                                                                                                                        lists these offers are based on. You may opt out with the nationwide
                                                                                                                                        credit bureaus at 1-888-5-OPTOUT (1-888-567-8688).
                            </li>
                                    <h6 style={{
                                        marginTop: 5,
                                        textAlign: 'center'
                                    }}>CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE</h6>
                                    <div style={{
                                        textAlign: "justify"
                                    }}><b>You have a right to place a "security freeze" on your credit report,
                                    which will prohibit a consumer reporting agency from releasing information
                            in your credit report without your express authorization.</b> The security
                                                                                                                                                                                        freeze is designed to prevent credit, loans, and services from being
                                                                                                                                                                                        approved in your name without your consent. However, you should be
                                                                                                                                                                                        aware that using a security freeze to take control over who gets access
                                                                                                                                                                                        to the personal and financial information in your credit report may delay,
                                                                                                                                                                                        interfere with, or prohibit the timely approval of any subsequent request or
                                                                                                                                                                                        application you make regarding a new loan, credit, mortgage, or any other
                                account involving the extension of credit.</div><br />
                                    <div style={{
                                        textAlign: "justify"
                                    }}>As an alternative to a security freeze, you have the right to place
                                                                                an initial or extended fraud alert on your credit file at no cost. An
                                                                                initial fraud alert is a 1-year alert that is placed on a consumer's
                                                                                credit file. Upon seeing a fraud alert display on a consumer's credit
                                                                                file, a business is required to take steps to verify the consumer’s
                                                                                identity before extending new credit. If you are a victim of identity
                                                                                theft, you are entitled to an extended fraud alert, which is a fraud
                                alert lasting 7 years.</div><br />
                                    <div
                                        style={{
                                            textAlign: "justify"
                                        }}>A security freeze does not apply to a person or entity, or
                                            its affiliates, or collection agencies acting on behalf of
                                            the person or entity, with which you have an existing account
                                            that requests information in your credit report for the purposes of
                                            reviewing or collecting the account. Reviewing the account includes
                                            activities related to account maintenance, monitoring, credit line
                                increases, and account upgrades and enhancements.</div>
                                    <li style={{
                                        textAlign: "justify"
                                    }}><b>You may seek damages from violators.</b> If a consumer reporting agency,
                                                                            or, in some cases, a user of consumer reports or a furnisher of
                                                                            information to a consumer reporting agency violates the FCRA, you
                                    may be able to sue in state or federal court.</li>
                                    <li
                                        style={{
                                            textAlign: "justify"
                                        }}>Identity theft victims and active duty military personnel have additional rights. For more information, </li>
                                    <div
                                        className="html2pdf__page-break"
                                        style={{
                                            textAlign: "justify"
                                        }}> visit <a href="https://www.consumerfinance.gov/learnmore/">visit www.consumerfinance.gov/learnmore.</a>
                                        <b>States may enforce the FCRA, and many states have their own
                                        consumer reporting laws. In some cases, you may have more
                                        rights under state law. For more information, contact your
                                        state or local consumer protection agency or your state Attorney
                                General. For information about your federal rights, contact:</b> </div>
                                </ul>
                            </div>

                            <table style={{
                                height: "100%",
                                border: "1px solid black",
                                textAlign: "justify"

                            }}>
                                <div style={{
                                    textAlign: "justify",
                                    marginLeft: 10,
                                    marginRight: 10
                                }}>
                                    <h6>A Summary of Your Rights Under the Fair Credit Reporting Act</h6>
                                    <div>
                                        Para información en español, visite <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a> o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <b> A Summary of Your Rights Under the Fair Credit Reporting Act</b></div>
                                    <div style={{
                                        textAlign: "justify",
                                        marginLeft: '5',
                                        marginRight: '5'
                                    }}>The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness,
                                                                                and privacy of information in the files of consumer reporting agencies. There are
                                                                                many types of consumer reporting agencies, including credit bureaus and specialty
                                                                                agencies (such as agencies that sell information about check writing histories,
                                                                                medical records, and rental history records). Here is a summary of your major
                                                                                rights under the FCRA. For more information, including information about additional
                                        rights, go to <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a> or write to: Consumer Financial
                                          Protection Bureau, 1700 G Street N.W., Washington, DC 20552.</div>
                                    <li
                                        style={{
                                            textAlign: "justify",
                                            marginLeft: '5',
                                            marginRight: '5'
                                        }}>
                                        You must be told if information in your file has been used against you.
                                        Anyone who uses a credit report or another type of consumer report to deny
                                        your application for credit, insurance, or employment - or to take another
                                        adverse action against you - must tell you, and must give you the name, address,
                                        and phone number of the agency that provided the information.
                                          </li>
                                    <li>
                                        You have the right to know what is in your file. You may request and obtain all
                                        the information about you in the files of a consumer reporting agency
                                        (your "file disclosure"). You will be required to provide proper identification,
                                        which may include your Social Security number. In many cases, the disclosure
                                        will be free. You are entitled to a free file disclosure if:
                                          </li>
                                    <ul>
                                        <li>
                                            <li>a person has taken adverse action against you because of information
                                                      in your credit report;</li>
                                        </li>
                                        <li>
                                            you are the victim of identity theft and place a fraud alert in your file;
                                              </li>
                                        <li>
                                            your file contains inaccurate information as a result of fraud;
                                              </li>
                                        <li>
                                            you are on public assistance;
                                              </li>
                                        <li>
                                            you are unemployed but expect to apply for employment within 60 days.
                                              </li>
                                    </ul>
                                    <li>
                                        In addition, all consumers are entitled to one free disclosure every 12
                                        months upon request from each nationwide credit bureau and from nationwide
                                        specialty consumer reporting agencies. See <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a>
                                        for additional information.
                                    </li>
                                    <li
                                        className="html2pdf__page-break"
                                    >
                                        You have the right to ask for a credit score. Credit scores are numerical
                                        summaries of your credit-worthiness based on information from credit bureaus.
                                        You may request a credit score from consumer reporting agencies that create
                                        scores or distribute scores used in residential real property loans, but you
                                        will have to pay for it. In some mortgage transactions, you will receive
                                        credit score information for free from the mortgage lender.
                                    </li>
                                    <li>
                                        You have the right to dispute incomplete or inaccurate information.
                                        If you identify information in your file that is incomplete or inaccurate,
                                        and report it to the consumer reporting agency, the agency must investigate
                                        unless your dispute is frivolous. See <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a> for
                                        an explanation of dispute procedures.
                                    </li>
                                    <li>
                                        Consumer reporting agencies must correct or delete inaccurate, incomplete,
                                        or unverifiable information. Inaccurate, incomplete, or unverifiable
                                        information must be removed or corrected, usually within 30 days. However,
                                        a consumer reporting agency may continue to report information it has verified
                                        as accurate.
                                    </li>
                                    <li>
                                        Consumer reporting agencies may not report outdated negative information.
                                        In most cases, a consumer reporting agency may not report negative
                                        information that is more than seven years old, or bankruptcies that
                                        are more than 10 years old.
                                    </li>
                                    <li>
                                        Access to your file is limited. A consumer reporting agency may provide
                                        information about you only to people with a valid need – usually to
                                        consider an application with a creditor, insurer, employer, landlord,
                                        or other business. The FCRA specifies those with a valid need for access.
                                    </li>
                                    <li>
                                        You must give your consent for reports to be provided to employers.
                                        A consumer reporting agency may not give out information about you
                                        to your employer, or a potential employer, without your written
                                        consent given to the employer. Written consent generally is not
                                        required in the trucking industry. For more information, go to
                                        <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore.</a>
                                    </li>
                                    <li>
                                        You may limit "prescreened" offers of credit and insurance you get
                                        based on information in your credit report. Unsolicited “prescreened”
                                        offers for credit and insurance must include a toll-free phone number
                                        you can call if you choose to remove your name and address form the
                                        lists these offers are based on. You may opt out with the nationwide
                                        credit bureaus at 1-888-5-OPTOUT (1-888-567-8688).
                                    </li>
                                    <li>The following FCRA right applies with respect to nationwide consumer
                                    reporting agencies:</li>
                                    <div style={{
                                        marginTop: 10
                                    }}><b>CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE</b></div>
                                    <div
                                        className="html2pdf__page-break"
                                        style={{
                                            marginTop: 10
                                        }}>
                                        <b>You have a right to place a "security freeze" on your credit report,
                                        which will prohibit a consumer reporting agency from releasing
                                        information in your credit report without your express authorization.</b>
                                        The security freeze is designed to prevent credit, loans, and services
                                        from being approved in your name without your consent. However, you
                                        should be aware that using a security freeze to take control over who
                                        gets access to the personal and financial information in your credit
                                        report may delay, interfere with, or prohibit the timely approval of
                                        any subsequent request or application you make regarding a new loan,
                                        credit, mortgage, or any other account involving the extension of credit.
                                    </div>
                                    <div style={{
                                        marginTop: 10
                                    }}>
                                        As an alternative to a security freeze, you have the right to place an initial
                                        or extended fraud alert on your credit file at no cost. An initial fraud alert
                                        is a 1-year alert that is placed on a consumer's credit file. Upon seeing a fraud
                                        alert display on a consumer's credit file, a business is required to take steps
                                        to verify the consumer’s identity before extending new credit. If you are a
                                        victim of identity theft, you are entitled to an extended fraud alert, which
                                        is a fraud alert lasting 7 years.
                                    </div>
                                    <div style={{
                                        marginTop: 10
                                    }}>
                                        A security freeze does not apply to a person or entity, or its affiliates,
                                        or collection agencies acting on behalf of the person or entity, with which
                                        you have an existing account that requests information in your credit report
                                        for the purposes of reviewing or collecting the account. Reviewing the account
                                        includes activities related to account maintenance, monitoring, credit line
                                        increases, and account upgrades and enhancements.
                                    </div>
                                    <li>
                                        A security freeze does not apply to a person or entity, or its affiliates,
                                        or collection agencies acting on behalf of the person or entity, with which
                                        you have an existing account that requests information in your credit report
                                        for the purposes of reviewing or collecting the account. Reviewing the account
                                        includes activities related to account maintenance, monitoring, credit line
                                        increases, and account upgrades and enhancements.
                                    </li>
                                    <li>
                                        <b>You may seek damages from violators.</b> If a consumer reporting agency, or, in some cases, a user of consumer reports or a furnisher of information to a consumer reporting agency violates the FCRA, you may be able to sue in state or federal court.
                                    </li>
                                    <li>
                                        <b>Identity theft victims and active duty military personnel have
                                        additional rights.</b> For more information, visit <a href="https://www.consumerfinance.gov/learnmore/">www.consumerfinance.gov/learnmore</a> </li>
                                    <div> <b>States may enforce the FCRA, and many states have their own consumer reporting laws.
                                    In some cases, you may have more rights under state law. For more information,
                                    contact your state or local consumer protection agency or your state Attorney
                                        General. For information about your federal rights, contact:</b></div>

                                    <div
                                        className="html2pdf__page-break"
                                        style={{
                                            borderTop: "1px solid black"
                                        }}>
                                        <b>By checking this box, I (a) acknowledge that I have read and understand the
                                        federal FCRA Summary of Rights and have been given the opportunity to copy/print
                                        the Summary of Rights and (b) agree to use an electronic signature to demonstrate
                                        my consent. An electronic signature is as legally binding as an ink signature.</b>
                                    </div>
                                </div>
                            </table>
                            <table style={{
                                border: "1px solid black",
                                width: "100%",
                                height: "100%",
                                textAlign: "justify"
                            }}>
                                <tr>
                                    <th style={{
                                        height: "100%",
                                        width: "75%",
                                        border: "1px solid black",
                                        borderRight: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        TYPE OF BUSINESS:
                                </th>

                                    <th style={{
                                        height: "100%",
                                        width: "25%",
                                        border: "1px solid black",
                                        borderLeft: "1px solid black"
                                    }}>
                                        CONTACT:
                                </th>
                                </tr>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            1.a. Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates
                                        </div>
                                        <div>
                                            b. Such affiliates that are not banks, savings associations, or credit unions also should list, in addition to the CFPB:
                                        </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}><div>
                                            a. Consumer Financial Protection Bureau
                                            1700 G Street, N.W.
                                            Washington, DC 20552
                                       </div>
                                        <div>
                                            b. Federal Trade Commission
                                            Consumer Response Center
                                            600 Pennsylvania Avenue, N.W.
                                            Washington, DC 20580
                                            (877) 382-4357
                                        </div></td>
                                </tr>
                                <tr
                                    className="html2pdf__page-break"
                                >
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "justify"
                                        }}>
                                        <div>
                                            2. To the extent not included in item 1 above:
                                    </div>
                                        <div>
                                            a. National banks, federal savings associations, and federal branches and federal agencies of foreign banks
                                    </div>
                                        <div>
                                            b. State member banks, branches and agencies of foreign banks (other than federal branches, federal agencies, and Insured State Branches of Foreign Banks), commercial lending companies owned or controlled by foreign banks, and organizations operating under section 25 or 25A of the Federal Reserve Act.
                                    </div>
                                        <div>
                                            c. Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations
                                    </div>
                                        <div>
                                            d. Federal Credit Unions
                                    </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            a. Office of the Comptroller of the Currency
                                            Customer Assistance Group
                                            1301 McKinney Street, Suite 3450
                                            Houston, TX 77010-9050
                                      </div>
                                        <div>
                                            b. Federal Reserve Consumer Help Center
                                            P.O. Box 1200
                                            Minneapolis, MN 55480
                                      </div>
                                        <div>
                                            c. FDIC Consumer Response Center
                                            1100 Walnut Street, Box #11
                                            Kansas City, MO 64106
                                      </div>
                                        <div>
                                            d. National Credit Union Administration
                                            Office of Consumer Financial Protection (OCFP)
                                            Division of Consumer Compliance Policy and Outreach
                                            1775 Duke Street
                                            Alexandria, VA 22314
                                      </div>
                                    </td>
                                </tr>
                                <tr
                                >
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "justify"
                                        }}>
                                        <div>
                                            3. Air Carriers
                                        </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            Asst. General Counsel for Aviation Enforcement & Proceedings
                                            Aviation Consumer Protection Division
                                            Department of Transportation
                                            1200 New Jersey Avenue, S.E.
                                            Washington, DC 20590
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            4. Creditors Subject to the Surface Transportation Board
                                        </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            Office of Proceedings, Surface Transportation Board
                                            Department of Transportation
                                            395 E Street, S.W.
                                            Washington, DC 20423
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            5. Creditors Subject to the Packers and Stockyards Act, 1921
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{
                                            border: "1px solid black",
                                            borderLeft: "1px solid black",
                                            textAlign: "justify"
                                        }}>
                                            Nearest Packers and Stockyards Administration area supervisor
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            6. Small Business Investment Companies
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            Associate Deputy Administrator for Capital Access
                                            United States Small Business Administration
                                            409 Third Street, S.W., Suite 8200
                                            Washington, DC 20416
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className="html2pdf__page-break"
                                >
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            7. Brokers and Dealers
                                        </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        Securities and Exchange Commission
                                        100 F Street, N.E.
                                        Washington, DC 20549
                                    </td>
                                </tr>
                                <tr
                                >
                                    <td
                                        style={{
                                            border: "1px solid black",
                                            textAlign: "justify"
                                        }}>
                                        <div>
                                            8. Federal Land Banks, Federal Land Bank Associations, Federal Intermediate Credit Banks, and Production Credit Associations
                                        </div>
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            Farm Credit Administration
                                            1501 Farm Credit Drive
                                            McLean, VA 22102-5090
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            9. Retailers, Finance Companies, and All Other Creditors Not Listed Above
                                        </div>
                                    </td>
                                    <td tyle={{
                                        border: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "justify"
                                    }}>
                                        <div>
                                            Federal Trade Commission
                                            Consumer Response Center
                                            600 Pennsylvania Avenue, N.W.
                                            Washington, DC 20580
                                            (877) 382-4357
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div className="text-center" style={{
                                marginBottom: 15,
                                marginTop: 15
                            }}>
                                {this.state.downloading ? null :
                                    <Button size="sm"
                                        disabled={this.state.downloading}
                                        onClick={this.onPrint}>
                                        PRINT
                                    </Button>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    };
}
export default CertifyInformation;
