import _get from 'lodash/get';
import * as TYPES from './actionTypes';
import axiosNext from './axiosNext';
import { URLS } from '../../constants';
import { dummyData } from './dummyData';

export function setLoading(value) {
  return { type: TYPES.LOADING, value };
}

export function setRecruiting(value) {
  return { type: TYPES.CARRIER, value };
}

export function onRegisterCarrier(value) {
  return {
    type: TYPES.REGISTER_CARRIER,
    value,
  };
}

export function setRegisterId(value) {
  return {
    type: TYPES.CARRIER_REGISTER_ID,
    value,
  };
}
export function setRegisterStatus(value) {
  return {
    type: TYPES.CARRIER_STATUS,
    value,
  };
}

export function updateGeneralInfo(value) {
  return {
    type: TYPES.CARRIER_GENERAL,
    value,
  };
}
export function updateContactInfo(value) {
  return {
    type: TYPES.CARRIER_CONTACT,
    value,
  };
}
export function updatePA(value) {
  return {
    type: TYPES.CARRIER_PA,
    value,
  };
}
export function updateMA(value) {
  return {
    type: TYPES.CARRIER_MA,
    value,
  };
}
export function updateDriversInfo(value) {
  return {
    type: TYPES.CARRIER_DRIVERS,
    value,
  };
}
export function updateTrucksInfo(value) {
  return {
    type: TYPES.CARRIER_TRUCKS,
    value,
  };
}
export function updateTrailersInfo(value) {
  return {
    type: TYPES.CARRIER_TRAILERS,
    value,
  };
}
export function updateHosInfo(value) {
  return {
    type: TYPES.CARRIER_HOS,
    value,
  };
}
export function updateDocuments(value) {
  return {
    type: TYPES.CARRIER_DOCS,
    value,
  };
}
export function updateMoreDetails(value) {
  return {
    type: TYPES.CARRIER_MORE_DETAILS,
    value,
  };
}


export function getRegistration() {
  return async (dispatch, getState) => {
    const googleAuth = _get(getState(), 'appData.googleUser', {});
    const businessId = _get(getState(), 'appData.business.id');

    try {
      const response = await axiosNext({
        url: `/secure/recruiting/${businessId}/carrier`,
        method: 'get',
        headers: {
          'X-G-Email': googleAuth.email,
          'X-G-Authorization': googleAuth.token,
        },
      });
      let _response = _get(response, 'data', {});

      dispatch(setRegisterId(_response._id));
      dispatch(setRegisterStatus(_response.status));

      dispatch(updateGeneralInfo(_response.general));
      dispatch(updatePA({ ...dummyData.physicalAddress, ..._response.physicalAddress }));
      dispatch(updateMA({ ...dummyData.mailingAddress, ..._response.mailingAddress }));
      dispatch(updateContactInfo(_response.contacts));
      dispatch(updateDriversInfo(_response.driverTypes));
      dispatch(updateTrucksInfo(_response.truckTypes));
      dispatch(updateTrailersInfo(_response.trailerTypes));
      dispatch(updateHosInfo(_response.hos));
      dispatch(updateDocuments(_response.documents));
      dispatch(updateMoreDetails(_response.moreDetails));

      return _response;
    } catch (error) {
      const statusCode = _get(error, 'response.status');
      const statusText = _get(error, 'response.statusText');
      const message =
        _get(error, 'response.data.errors[0].message') ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function updateRegistration(params) {
  return async (dispatch, getState) => {
    try {
      if (params.general) {
        dispatch(updateGeneralInfo(params.general));
      }
      if (params.physicalAddress) {
        dispatch(updatePA(params.physicalAddress));
      }
      if (params.mailingAddress) {
        dispatch(updateMA(params.mailingAddress));
      }
      if (params.contacts) {
        dispatch(updateContactInfo(params.contacts));
      }
      if (params.driverTypes) {
        dispatch(updateDriversInfo(params.driverTypes));
      }
      if (params.truckTypes) {
        dispatch(updateTrucksInfo(params.truckTypes));
      }
      if (params.trailerTypes) {
        dispatch(updateTrailersInfo(params.trailerTypes));
      }
      if (params.hos) {
        dispatch(updateHosInfo(params.hos));
      }
      if (params.documents) {
        dispatch(updateDocuments(params.documents));
      }
      if (params.moreDetails) {
        dispatch(updateMoreDetails(params.moreDetails));
      }

      const googleAuth = _get(getState(), 'appData.googleUser', {});
      if (params.status === 'SUBMITTED') {
        dispatch(setRegisterStatus(params.status));
      } else if (googleAuth.email) {
        dispatch(_updateRegistration(params));
      }
      return params;
    } catch (error) {
      return {};
    }
  };
}

export function _updateRegistration(params) {
  return async (dispatch, getState) => {
    try {
      const googleAuth = _get(getState(), 'appData.googleUser', {});
      const businessId = _get(getState(), 'appData.business.id');

      params._id = _get(getState(), 'carrierData.registerId');
      params.businessId = businessId;

      const response = await axiosNext({
        url: `/secure/recruiting/${businessId}/carrier`,
        method: 'post',
        headers: {
          'X-G-Email': googleAuth.email,
          'X-G-Authorization': googleAuth.token,
        },
        data: params,
      });
      let _response = _get(response, 'data', {});
      return _response;
    } catch (error) {
      const statusCode = _get(error, 'response.status');
      const statusText = _get(error, 'response.statusText');
      const message =
        _get(error, 'response.data.errors[0].message') ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function uploadDocuments(params) {
  return async (dispatch, getState) => {
    try {
      const googleAuth = _get(getState(), 'appData.googleUser', {});
      const businessId = _get(getState(), 'appData.business.id');

      params._id = _get(getState(), 'carrierData.registerId');
      params.businessId = businessId;

      const response = await axiosNext({
        url: `/secure/recruiting/${businessId}/upload`,
        method: 'post',
        headers: {
          'X-G-Email': googleAuth.email,
          'X-G-Authorization': googleAuth.token,
        },
        data: params,
      });
      let _response = _get(response, 'data', {});
      return _response;
    } catch (error) {
      const statusCode = _get(error, 'response.status');
      const statusText = _get(error, 'response.statusText');
      const message =
        _get(error, 'response.data.errors[0].message') ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function sendEmail(params) {
  return async (dispatch, getState) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(params),
        redirect: 'follow',
      };
      const _response = await fetch(`${URLS.CF_API}/email`, requestOptions);
      return await _response.json();
    } catch (error) {
      const statusCode = _get(error, 'response.status');
      const statusText = _get(error, 'response.statusText');
      const message =
        _get(error, 'response.data.errors[0].message') ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
