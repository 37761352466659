// import * as moment from 'moment';
import * as cookie from "react-cookie";
const moment = require("moment-timezone");

export const DRIVER_BASE_URL = `/driver`;
export const CARRIER_BASE_URL = `/carrier`;

export const smsFromNo = "+16503979677";

export const masks = {
    minHrMask: [/[0-9]/, /[0-9]/, ":", /[0-6]/, /[0-9]/],
    minHrAMPMMask: [
        /[0-1]/,
        /\d/,
        ":",
        /[0-6]/,
        /\d/,
        " ",
        /[a,p,A,P]/,
        /[m,M]/
    ],
    phoneUSMask: [
        "(",
        /[2-9]/,
        /[0-9]/,
        /[0-9]/,
        ")",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/
    ]
};

export const SUFFIX = [
    { value: "Jr", label: "Jr" },
    { value: "II", label: "II" },
    { value: "Sr", label: "Sr" },
    { value: "III", label: "II" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" }
];
export const GENDER = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
];

export const availableTime = [
    { value: "12:00 am", label: "12:00 AM" },
    { value: "12:30 am", label: "12:30 AM" },
    { value: "01:00 am", label: "1:00 AM" },
    { value: "01:30 am", label: "1:30 AM" },
    { value: "02:00 am", label: "2:00 AM" },
    { value: "02:30 am", label: "2:30 AM" },
    { value: "03:00 am", label: "3:00 AM" },
    { value: "03:30 am", label: "3:30 AM" },
    { value: "04:00 am", label: "4:00 AM" },
    { value: "04:30 am", label: "4:30 AM" },
    { value: "05:00 am", label: "5:00 AM" },
    { value: "05:30 am", label: "5:30 AM" },
    { value: "06:00 am", label: "6:00 AM" },
    { value: "06:30 am", label: "6:30 AM" },
    { value: "07:00 am", label: "7:00 AM" },
    { value: "07:30 am", label: "7:30 AM" },
    { value: "08:00 am", label: "8:00 AM" },
    { value: "08:30 am", label: "8:30 AM" },
    { value: "09:00 am", label: "9:00 AM" },
    { value: "09:30 am", label: "9:30 AM" },
    { value: "10:00 am", label: "10:00 AM" },
    { value: "10:30 am", label: "10:30 AM" },
    { value: "11:00 am", label: "11:00 AM" },
    { value: "11:30 am", label: "11:30 AM" },
    { value: "12:00 pm", label: "12:00 PM" },
    { value: "12:30 pm", label: "12:30 PM" },
    { value: "01:00 am", label: "1:00 PM" },
    { value: "01:30 am", label: "1:30 PM" },
    { value: "02:00 pm", label: "2:00 PM" },
    { value: "02:30 pm", label: "2:30 PM" },
    { value: "03:00 pm", label: "3:00 PM" },
    { value: "03:30 pm", label: "3:30 PM" },
    { value: "04:00 pm", label: "4:00 PM" },
    { value: "04:30 pm", label: "4:30 PM" },
    { value: "05:00 pm", label: "5:00 PM" },
    { value: "05:30 pm", label: "5:30 PM" },
    { value: "06:00 pm", label: "6:00 PM" },
    { value: "06:30 pm", label: "6:30 PM" },
    { value: "07:00 pm", label: "7:00 PM" },
    { value: "07:30 pm", label: "7:30 PM" },
    { value: "08:00 pm", label: "8:00 PM" },
    { value: "08:30 pm", label: "8:30 PM" },
    { value: "09:00 pm", label: "9:00 PM" },
    { value: "09:30 pm", label: "9:30 PM" },
    { value: "10:00 pm", label: "10:00 PM" },
    { value: "10:30 pm", label: "10:30 PM" },
    { value: "11:00 pm", label: "11:00 PM" },
    { value: "11:30 pm", label: "11:30 PM" }
];

export const searchYear = [
    { value: "2020", label: "2020", start: "2020-01-01", end: "2020-12-31" },
    { value: "2019", label: "2019", start: "2019-01-01", end: "2019-12-31" },
    { value: "2018", label: "2018", start: "2018-01-01", end: "2018-12-31" },
    { value: "2017", label: "2017", start: "2017-01-01", end: "2017-12-31" },
    { value: "2016", label: "2016", start: "2016-01-01", end: "2016-12-31" },
    { value: "2015", label: "2015", start: "2015-01-01", end: "2015-12-31" },
    { value: "2014", label: "2014", start: "2014-01-01", end: "2014-12-31" },
    { value: "custom", label: "Custom (Select dates)" }
];

export const searchIftaYear = [
    { value: "2018", label: "2018", start: "2018-01-01", end: "2018-12-31" },
    {
        value: "Q4_2018",
        label: "Q4 2018",
        start: "2018-10-01",
        end: "2018-12-31"
    },
    {
        value: "Q3_2018",
        label: "Q3 2018",
        start: "2018-07-01",
        end: "2018-09-30"
    },
    {
        value: "Q2_2018",
        label: "Q2 2018",
        start: "2018-04-01",
        end: "2018-06-30"
    },
    {
        value: "Q1_2018",
        label: "Q1 2018",
        start: "2018-01-01",
        end: "2018-03-31"
    },

    { value: "2017", label: "2017", start: "2017-01-01", end: "2017-12-31" },
    {
        value: "Q4_2017",
        label: "Q4 2017",
        start: "2017-10-01",
        end: "2017-12-31"
    },
    {
        value: "Q3_2017",
        label: "Q3 2017",
        start: "2017-07-01",
        end: "2017-09-30"
    },
    {
        value: "Q2_2017",
        label: "Q2 2017",
        start: "2017-04-01",
        end: "2017-06-30"
    },
    {
        value: "Q1_2017",
        label: "Q1 2017",
        start: "2017-01-01",
        end: "2017-03-31"
    },

    { value: "2016", label: "2016", start: "2016-01-01", end: "2016-12-31" },
    { value: "2015", label: "2015", start: "2015-01-01", end: "2015-12-31" },
    { value: "2014", label: "2014", start: "2014-01-01", end: "2014-12-31" },
    { value: "custom", label: "Custom (Select dates)" }
];

export const year = [
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
    { value: "2014", label: "2014" },
    { value: "2013", label: "2013" },
    { value: "2012", label: "2012" },
    { value: "2011", label: "2011" },
    { value: "2010", label: "2010" },
    { value: "2009", label: "2009" },
    { value: "2008", label: "2008" },
    { value: "2007", label: "2007" },
    { value: "2006", label: "2006" }
];

export const dumpTruckTypes = [
    { value: "dayCabDouble", code: "DC-DA", label: "Day Cab Double Axle" },
    { value: "semiBottom", code: "SB", label: "Semi Bottom" },
    { value: "superDump", code: "SD", label: "Super Dump" },
    { value: "10Wheeler", code: "10W", label: "10 Wheeler" },
    { value: "endDump", code: "ED", label: "End Dump" },
    { value: "transfer", code: "TR", label: "Transfer" },
    { value: "flatBed", code: "FB", label: "Flat Bed" },
    { value: "doubleBottomDumps", code: "DBD", label: "Double Bottom Dumps" },
    { value: "hsTransfer", code: "HST", label: "HS Transfer" },
    { value: "dropAxle", code: "DA", label: "Drop Axle" }
];

export const bussType = [
    { value: "llc", label: "Limited Liability Company" },
    { value: "cpt", label: "Cooperative" },
    { value: "corp", label: "Corporation" },
    { value: "part", label: "Partnership" },
    { value: "scorp", label: "S Corporation" },
    { value: "sprop", label: "Sole Proprietorship" }
];

export const timeZones = [
    { value: "America/New_York", label: "Eastern Standard Time (EST)" },
    { value: "America/Chicago", label: "Central Standard Time (CST)" },
    { value: "America/Denver", label: "Mountain Standard Time (MST)" },
    { value: "America/Los_Angeles", label: "Pacific Standard Time (PST)" }
];

export const COUNTRIES = [
    { value: "US", label: "US", labelDep: "United States" },
    { value: "CA", label: "CA", labelDep: "Canada" }
];

export const US_STATES = [
    { labelDep: "Alabama", value: "AL", label: "AL" },
    { labelDep: "Alaska", value: "AK", label: "AK" },
    { labelDep: "American Samoa", value: "AS", label: "AS" },
    { labelDep: "Arizona", value: "AZ", label: "AZ" },
    { labelDep: "Arkansas", value: "AR", label: "AR" },
    { labelDep: "California", value: "CA", label: "CA" },
    { labelDep: "Colorado", value: "CO", label: "CO" },
    { labelDep: "Connecticut", value: "CT", label: "CT" },
    { labelDep: "Delaware", value: "DE", label: "DE" },
    { labelDep: "District Of Columbia", value: "DC", label: "DC" },
    { labelDep: "Federated States Of Micronesia", value: "FM", label: "FM" },
    { labelDep: "Florida", value: "FL", label: "FL" },
    { labelDep: "Georgia", value: "GA", label: "GA" },
    { labelDep: "Guam", value: "GU", label: "GU" },
    { labelDep: "Hawaii", value: "HI", label: "HI" },
    { labelDep: "Idaho", value: "ID", label: "ID" },
    { labelDep: "Illinois", value: "IL", label: "IL" },
    { labelDep: "Indiana", value: "IN", label: "IN" },
    { labelDep: "Iowa", value: "IA", label: "IA" },
    { labelDep: "Kansas", value: "KS", label: "KS" },
    { labelDep: "Kentucky", value: "KY", label: "KY" },
    { labelDep: "Louisiana", value: "LA", label: "LA" },
    { labelDep: "Maine", value: "ME", label: "ME" },
    { labelDep: "Marshall Islands", value: "MH", label: "MH" },
    { labelDep: "Maryland", value: "MD", label: "MD" },
    { labelDep: "Massachusetts", value: "MA", label: "MA" },
    { labelDep: "Michigan", value: "MI", label: "MI" },
    { labelDep: "Minnesota", value: "MN", label: "MN" },
    { labelDep: "Mississippi", value: "MS", label: "MS" },
    { labelDep: "Missouri", value: "MO", label: "MO" },
    { labelDep: "Montana", value: "MT", label: "MT" },
    { labelDep: "Nebraska", value: "NE", label: "NE" },
    { labelDep: "Nevada", value: "NV", label: "NV" },
    { labelDep: "New Hampshire", value: "NH", label: "NH" },
    { labelDep: "New Jersey", value: "NJ", label: "NJ" },
    { labelDep: "New Mexico", value: "NM", label: "NM" },
    { labelDep: "New York", value: "NY", label: "NY" },
    { labelDep: "North Carolina", value: "NC", label: "NC" },
    { labelDep: "North Dakota", value: "ND", label: "ND" },
    { labelDep: "Northern Mariana Islands", value: "MP", label: "MP" },
    { labelDep: "Ohio", value: "OH", label: "OH" },
    { labelDep: "Oklahoma", value: "OK", label: "OK" },
    { labelDep: "Oregon", value: "OR", label: "OR" },
    { labelDep: "Palau", value: "PW", label: "PW" },
    { labelDep: "Pennsylvania", value: "PA", label: "PA" },
    { labelDep: "Puerto Rico", value: "PR", label: "PR" },
    { labelDep: "Rhode Island", value: "RI", label: "RI" },
    { labelDep: "South Carolina", value: "SC", label: "SC" },
    { labelDep: "South Dakota", value: "SD", label: "SD" },
    { labelDep: "Tennessee", value: "TN", label: "TN" },
    { labelDep: "Texas", value: "TX", label: "TX" },
    { labelDep: "Utah", value: "UT", label: "UT" },
    { labelDep: "Vermont", value: "VT", label: "VT" },
    { labelDep: "Virgin Islands", value: "VI", label: "VI" },
    { labelDep: "Virginia", value: "VA", label: "VA" },
    { labelDep: "Washington", value: "WA", label: "WA" },
    { labelDep: "West Virginia", value: "WV", label: "WV" },
    { labelDep: "Wisconsin", value: "WI", label: "WI" },
    { labelDep: "Wyoming", value: "WY", label: "WY" }
];

export const CA_STATES = [
    { value: "AB", label: "AB", labelDep: "Alberta", country: "CA" },
    { value: "BC", label: "BC", labelDep: "British Columbia", country: "CA" },
    { value: "MB", label: "MB", labelDep: "Manitoba", country: "CA" },
    { value: "NB", label: "NB", labelDep: "New Brunswick", country: "CA" },
    {
        value: "NL",
        label: "NL",
        labelDep: "Newfoundland and Labrador",
        country: "CA",
        alt: ["Newfoundland", "Labrador"]
    },
    { value: "NS", label: "NS", labelDep: "Nova Scotia", country: "CA" },
    { value: "NU", label: "NU", labelDep: "Nunavut", country: "CA" },
    {
        value: "NT",
        label: "NT",
        labelDep: "Northwest Territories",
        country: "CA"
    },
    { value: "ON", label: "ON", labelDep: "Ontario", country: "CA" },
    {
        value: "PE",
        label: "PE",
        labelDep: "Prince Edward Island",
        country: "CA"
    },
    { value: "QC", label: "QC", labelDep: "Quebec", country: "CA" },
    { value: "SK", label: "SK", labelDep: "Saskatchewan", country: "CA" },
    { value: "YT", label: "YT", labelDep: "Yukon", country: "CA" }
];

export const otherPaymentDedTypes = [
    { value: "diesel", label: "Diesel" },
    { value: "yard", label: "Yard Rent" },
    { value: "toll", label: "Toll" },
    { value: "ifta", label: "Ifta" },
    { value: "iftaPermit", label: "Ifta Permit" },
    { value: "cabCardDotFee", label: "Cab Card Dot Free" },
    { value: "stateTax", label: "State Tax" },
    { value: "oregonTax", label: "Oregon Tax" },
    { value: "licensePlate", label: "license Plate" },
    { value: "tractorInstallment", label: "Tractor Installment" },
    { value: "tractorInsurance", label: "Tractor Insurance" },
    { value: "tractorRent", label: "Tractor Rent" },
    { value: "tractorRepair", label: "Tractor Repair" },
    { value: "trailerInstallment", label: "Trailer Installment" },
    { value: "trailerInsurance", label: "Trailer Insurance" },
    { value: "trailerRent", label: "Trailer Rent" },
    { value: "trailerRepair", label: "Trailer Repair" },
    { value: "healthInsurance", label: "Health Insurance" },
    { value: "fastTrack", label: "Fast Track" },
    { value: "prePass", label: "Pre Pass" },
    { value: "trafficTicket", label: "Traffic Ticket" },
    { value: "scaleTicketByDriver", label: "Scale Ticket Driver" },
    { value: "stateEntryPermit", label: "State Entry Permit" },
    { value: "other", label: "Other" }
];

export const otherPaymentRemTypes = [
    { value: "toll", label: "Toll" },
    { value: "diesel", label: "Diesel" },
    { value: "repair", label: "Repair" },
    { value: "meal", label: "Meal" },
    { value: "layover", label: "Layover" },
    { value: "catScaleRefund", label: "Cat Scale Refund" },
    { value: "trafficTicket", label: "Traffic Ticket" },
    { value: "stateEntryPermit", label: "State Entry Permit" },
    { value: "other", label: "Other" }
];

export const otherPaymenTypes = [
    { value: "diesel", label: "Diesel", tractor: true, trailor: true },
    { value: "def", label: "Def", tractor: true },
    { value: "yard", label: "Yard Rent", tractor: true, trailor: true },
    { value: "toll", label: "Toll", tractor: true, trailor: true },
    { value: "ifta", label: "Ifta", tractor: true },
    { value: "iftaPermit", label: "Ifta Permit", tractor: true },
    { value: "cabCardDotFee", label: "Cab Card Dot Free", tractor: true },
    { value: "stateTax", label: "State Tax", tractor: true },
    { value: "oregonTax", label: "Oregon Tax", tractor: true },
    {
        value: "licensePlate",
        label: "license Plate",
        tractor: true,
        trailor: true
    },
    {
        value: "tractorInstallment",
        label: "Tractor Installment",
        tractor: true
    },
    { value: "tractorInsurance", label: "Tractor Insurance", tractor: true },
    { value: "tractorRent", label: "Tractor Rent", tractor: true },
    { value: "tractorRepair", label: "Tractor Repair", tractor: true },
    { value: "tractorWash", label: "Tractor Wash", tractor: true },
    {
        value: "trailerInstallment",
        label: "Trailer Installment",
        trailor: true
    },
    { value: "trailerInsurance", label: "Trailer Insurance", trailor: true },
    { value: "trailerRent", label: "Trailer Rent", trailor: true },
    { value: "trailerRepair", label: "Trailer Repair", trailor: true },
    { value: "trailerWash", label: "Trailer Wash", trailor: true },
    { value: "healthInsurance", label: "Health Insurance" },
    { value: "fastTrack", label: "Fast Track", type: "tractor", tractor: true },
    { value: "prePass", label: "Pre Pass", type: "tractor", tractor: true },
    { value: "trafficTicket", label: "Traffic Ticket" },
    { value: "scaleTicketByDriver", label: "Scale Ticket Driver" },
    { value: "stateEntryPermit", label: "State Entry Permit" },
    { value: "meal", label: "Meal" },
    { value: "catScaleRefund", label: "Cat Scale Refund" },
    { value: "other", label: "Other" }
];

export const trailerManufacturer = [
    {
        value: "greatDaneLimitedPartnership",
        label: "Great Dane Limited Partnership"
    },
    {
        value: "wabashNationalCorporation",
        label: "Wabash National Corporation"
    },
    {
        value: "utilityTrailerManufacturing",
        label: "Utility Trailer Manufacturing"
    },
    { value: "amthorInternational", label: "Amthor International" },
    { value: "polarTankTrailer", label: "Polar Tank Trailer" },
    { value: "pneumaticTankTrailer", label: "Pneumatic Tank Trailer" },
    { value: "senecaTank", label: "Seneca Tank" },
    { value: "other", label: "Other" }
];

export const trailerType = [
    { value: "22Van", label: "22' Van", refer: false },
    { value: "48Reefer", label: "48' Reefer", refer: true },
    { value: "53Reefer", label: "53' Reefer", refer: true },
    { value: "53Van", label: "53' Van" },
    { value: "airFreight", label: "Air Freight" },
    { value: "animalCarrier", label: "Animal Carrier", refer: true },
    { value: "anyEquipment", label: "Any Equipment" },
    { value: "autocarrier", label: "Auto carrier" },
    { value: "boatHaulingTrailer", label: "Boat Hauling Trailer" },
    { value: "containerTrailer", label: "Container Trailer" },
    { value: "curtainSiders", label: "Curtain Siders" },
    { value: "curtainVan", label: "Curtain Van" },
    { value: "driveAway", label: "Drive Away" },
    { value: "dumpTrucks", label: "Dump Trucks" },
    { value: "endDump", label: "End Dump" },
    { value: "flatbed", label: "Flatbed" },
    { value: "flatBedAirRide", label: "FlatBed Air-Ride" },
    { value: "flatbedBlanketWrapped", label: "Flatbed Blanket Wrapped" },
    { value: "flatbedIntermodal", label: "Flatbed Intermodal" },
    { value: "flatbedOrVan", label: "Flatbed or Van" },
    { value: "flatbedOrVentedVan", label: "Flatbed or Vented Van" },
    {
        value: "flatbedOverDimensionloads",
        label: "Flatbed over-dimension loads"
    },
    { value: "flatbedWithSides", label: "Flatbed With Sides" },
    { value: "flatbedStepDeckOrVan", label: "Flatbed, Step Deck, or Van" },
    { value: "flatbedVanOrReefer", label: "Flatbed, Van, or Reefer" },
    {
        value: "flatbedVentedVanOrReefer",
        label: "Flatbed, Vented Van, or Reefer"
    },
    { value: "haulAndTowUnit", label: "Haul and Tow Unit" },
    { value: "hazardousMaterialLoad", label: "Hazardous Material load" },
    { value: "hotShot", label: "Hot Shot" },
    { value: "landollFlatbed", label: "Landoll Flatbed" },
    { value: "liveBottomTrailer", label: "Live Bottom Trailer" },
    { value: "movingVan", label: "Moving Van" },
    { value: "multiAxleHeavyHauler", label: "Multi-Axle Heavy Hauler" },
    { value: "oceanFreight", label: "Ocean Freight" },
    { value: "openTop", label: "Open Top" },
    { value: "openTopVan", label: "Open Top Van" },
    {
        value: "refrigeratedCarrier",
        label: "Refrigerated Carrier",
        refer: true
    },
    {
        value: "refrigeratedIntermodal",
        label: "Refrigerated Intermodal",
        refer: true
    },
    { value: "removableGooseNeck", label: "Removable Goose Neck" },
    { value: "rollerBed", label: "Roller Bed" },
    { value: "specializedTrailers", label: "Specialized Trailers" },
    { value: "stepDeck", label: "Step Deck" },
    { value: "stepDeckExtendable", label: "Step Deck Extendable" },
    { value: "stepDeckOrFlat", label: "Step Deck or Flat" },
    {
        value: "stepDeckOrRemovableGooseneck",
        label: "Step Deck or Removable Gooseneck"
    },
    {
        value: "stepDeckOverDimensionLoad",
        label: "Step Deck over-dimension load"
    },
    { value: "stepDeckWLoading Ramps", label: "Step Deck w/Loading Ramps" },
    { value: "stepDeckWRamps", label: "Step Deck w/Ramps" },
    { value: "straightVan", label: "Straight Van" },
    { value: "tanker", label: "Tanker" },
    { value: "other", label: "Other" }
];

export const tractorManufacturer = [
    { value: "freightliner", label: "Freightliner" },
    { value: "international", label: "International" },
    { value: "kenworth", label: "Kenworth" },
    { value: "mack", label: "Mack" },
    { value: "peterbilt", label: "Peterbilt" },
    { value: "volvo", label: "Volvo" },
    { value: "other", label: "Other" }
];

export const ExpenseType = [
    { value: "assets", label: "Assets" },
    { value: "bankCharges", label: "BankCharges" },
    { value: "comission", label: "Comission" },
    { value: "fuel", label: "Fuel" },
    { value: "def", label: "Def" },
    { value: "lateFee", label: "LateFee" },
    { value: "legal", label: "Legal" },
    { value: "lumper", label: "Lumper" },
    { value: "parts", label: "Parts" },
    { value: "rent", label: "RentPayment" },
    { value: "repair", label: "Repair" },
    { value: "restack", label: "Restack" },
    { value: "office", label: "Office" },
    { value: "service", label: "Service" },
    { value: "scale", label: "Scale" },
    { value: "toll", label: "Toll" },
    { value: "truckWash", label: "Truck Wash" },
    { value: "travel", label: "Travel" },
    { value: "other", label: "Other" }
];

export const EldExpenseType = [
    { value: "devices", label: "Devices" },
    { value: "cables", label: "Cables" },
    { value: "marketing", label: "Marketing" },
    { value: "subscription", label: "Subscription" },
    { value: "other", label: "Other" }
];

export const PaymentMethod = [
    { value: "cash", label: "Cash" },
    { value: "check", label: "Check" },
    { value: "comCheck", label: "ComCheck" },
    { value: "fuelCard", label: "FuelCard" },
    { value: "credit", label: "DebitorCredit" },
    { value: "online", label: "Online" },
    { value: "tcheck", label: "TCheck" },
    { value: "wireTransfer", label: "WireTransfer" },
    { value: "other", label: "Other" }
];

export function getSupportedYears(count = 5) {
    const currentYear = moment(new Date(), "DD/MM/YYYY").year();
    let allYears = [];

    for (let i = 0; i < count; i++) {
        allYears.push(currentYear - i);
    }
    return allYears;
}

export function getSupportedMonth() {
    let allMonths = [];
    const months = moment.months();

    months.forEach((item, i) => {
        allMonths.push({
            label: item,
            value: 1 + i
        });
    });

    return allMonths;
}

export function getEqpYears() {
    const currentYear = moment(new Date(), "DD/MM/YYYY").year();
    let allMonth = [];

    for (let i = -1; i < 20; i++) {
        allMonth.push(currentYear - i);
    }
    return allMonth;
}

export const dHaulerRatePayType = [
    { value: "hourly", label: "Hourly" },
    { value: "load", label: "Load" },
    { value: "tons", label: "Tons" },
    { value: "custom", label: "Custom" }
];

export const hearAboutUsItems = [
    { value: "other", label: "Other" },
    { value: "craigList", label: "Craig List" },
    { value: "radio", label: "Radio" }
];

export const EDU_GRADES = [
    { label: "PhD", value: "PhD" },
    { label: "MBA", value: "MBA" },
    { label: "MS", value: "MS" },
    { label: "MA", value: "MA" },
    { label: "College - 5+ ", value: "College - 5+ " },
    { label: "College - 4", value: "College - 4" },
    { label: "College - 3", value: "College - 3" },
    { label: "College - 2", value: "College - 2" },
    { label: "College - 1", value: "College - 1" },
    { label: "GED", value: "GED" },
    { label: "Grade 12", value: "Grade 12" },
    { label: "Grade 11", value: "Grade 11" },
    { label: "Grade 10", value: "Grade 10" },
    { label: "Grade 9", value: "Grade 9" },
    { label: "Grade 8", value: "Grade 8" },
    { label: "Grade 7", value: "Grade 7" },
    { label: "Grade 6", value: "Grade 6" },
    { label: "Grade 5", value: "Grade 5" },
    { label: "Grade 4", value: "Grade 4" },
    { label: "Grade 3", value: "Grade 3" },
    { label: "Grade 2", value: "Grade 2" },
    { label: "Grade 1", value: "Grade 1" }
];

export const appliedPositionItems = [
    { value: "companyDriver", label: "Company Driver" }
];

export const LICENSE_CLASS_ITEM = [
    { label: "Class A", value: "Class A" },
    { label: "Class AZ", value: "Class AZ" },
    { label: "Class B", value: "Class B" },
    { label: "Class C", value: "Class C" },
    { label: "Class D", value: "Class D" },
    { label: "Class E", value: "Class E" },
    { label: "Class 1", value: "Class 1" },
    { label: "Class 2", value: "Class 2" },
    { label: "Class 3", value: "Class 3" },
    { label: "Class 4", value: "Class 4" },
    { label: "Class 5", value: "Class 5" }
];

export const MILITARY_BRANCHED = [
    { label: "Army", value: "Army" },
    { label: "Army Reserve", value: "Army Reserve" },
    { label: "Army National Guard", value: "Army National Guard" },
    { label: "Air Force", value: "Air Force" },
    { label: "Air Force Reserve", value: "Air Force Reserve" },
    { label: "Air National Guard", value: "Air National Guard" },
    { label: "Marines", value: "Marines" },
    { label: "Marine Corps Reserve", value: "Marine Corps Reserve" },
    { label: "Navy", value: "Navy" },
    { label: "Navy Reserve", value: "Navy Reserve" },
    { label: "Coast Guard", value: "Coast Guard" },
    { label: "Coast Guard Reserve", value: "Coast Guard Reserve" },
    { label: "Merchant Marines", value: "Merchant Marines" },
    { label: "National Guard", value: "National Guard" }
];

export const hoursOfInstructionItems = [
    { label: "0" },
    { label: "10+" },
    { label: "20+" },
    { label: "30+" },
    { label: "40+" },
    { label: "50+" },
    { label: "60+" },
    { label: "70+" },
    { label: "80+" },
    { label: "90+" },
    { label: "100+" },
    { label: "110+" },
    { label: "120+" },
    { label: "130+" },
    { label: "140+" },
    { label: "150+" },
    { label: "160+" },
    { label: "170+" },
    { label: "180+" },
    { label: "190+" },
    { label: "200+" }
];

export const violationItems = [
    { label: "Violation" },
    { label: "Incident/Accident" }
];

export const INSURANCE = [
    { id: "cargoinsurance", label: "Cargo Insurance" },
    { id: "liabilityinsurance", label: "Liability Insurance" }
]
export const CHARGES_DETAILS = [
    { label: "Careless/Reckless Driving", value: "Careless/Reckless Driving" },
    { label: "Driving Without A License", value: "Driving Without A License" },
    { label: "DUI/OWI", value: "DUI/OWI" },
    { label: "Failure to Obey Signal", value: "Failure to Obey Signal" },
    { label: "Following Too Closely", value: "Following Too Closely" },
    { label: "Improper Lane Change", value: "Improper Lane Change" },
    { label: "Seat Belt", value: "Seat Belt" },
    { label: "Speeding", value: "Speeding" },
    { label: "Too Fast for Conditions", value: "Too Fast for Conditions" },
    {
        label: "Violation Resulting from Accident",
        value: "Violation Resulting from Accident"
    },
    {
        label: "Driving with Suspended License",
        value: "Driving with Suspended License"
    },
    {
        label: "Failure to Report Accident",
        value: "Failure to Report Accident"
    },
    { label: "Failure to Yield", value: "Failure to Yield" },
    { label: "Improper Passing", value: "Improper Passing" },
    { label: "Improper Turn", value: "Improper Turn" },
    { label: "Improper Backing", value: "Improper Backing" },
    {
        label: "No Insurance / Proof of Insurance",
        value: "No Insurance / Proof of Insurance"
    },
    { label: "Leaving Scene of Accident", value: "Leaving Scene of Accident" },
    { label: "Defective Equipment", value: "Defective Equipment" },
    { label: "Hazmat Violation", value: "Hazmat Violation" },
    { label: "Lane Violation", value: "Lane Violation" },
    { label: "Overweight", value: "Overweight" },
    { label: "Cell Phone Usage", value: "Cell Phone Usage" },
    { label: "Texting While Driving", value: "Texting While Driving" },
    { label: "Inattentive Driving", value: "Inattentive Driving" },
    { label: "Other", value: "Other" }
];

export const FINE_AMOUNTS = [
    { label: "$0 - $99", value: "$0 - $99" },
    { label: "$100 - $199", value: "$100 - $199" },
    { label: "$200 - $299", value: "$200 - $299" },
    { label: "$300 - $399", value: "$300 - $399" },
    { label: "$400 - $499", value: "$400 - $499" },
    { label: "$500+", value: "$500+" }
];


export const TYPE_OF_ACCIDENT = [
    { label: "Non-Injury", value: "Non-Injury" },
    { label: "Injury", value: "Injury" },
    { label: "Fatality", value: "Fatality" }
];


export const TRUCK_TYPES = [
    { id: "sleeper", label: "Sleeper" },
    { id: "dayCab", label: "Day Cab" },
    { id: "semiBottom", label: "Semi Bottom" },
    { id: "endDump", label: "End Dump" },
    { id: "doubleBottom", label: "Double Bottom" },
    { id: "superDump", label: "Super Dump" },
    { id: "superTag", label: "Super Tag" },
    { id: "transfer", label: "Transfer" },
    { id: "highSide", label: "High Side" },
    { id: "tenWheeler", label: "Ten Wheeler" },
    { id: "flatBed", label: "Flat Bed" },
    { id: "superTen", label: "Super Ten" }
];

export const TRAILER_TYPES = [
    { id: "reefer", label: "Reefer" },
    { id: "dryVan", label: "Dry Van" },
    { id: "container", label: "Container" },
    { id: "dumpTrucks", label: "Dump Trucks" },
    { id: "endDump", label: "End Dump" },
    { id: "flatbed", label: "Flatbed" },
    { id: "straightVan", label: "Straight Van" },
    { id: "tanker", label: "Tanker" },
    { id: "other", label: "Other" }
];

export const DOCUMENTS_TYPES = [
    { id: "i9", label: "I-9" },
    { id: "liabilityInsurance", label: "Liability Insurance" },
    { id: "cargoInsurance", label: "Cargo Insurance" },
    { id: "usDot", label: "US Dot" },
    { id: "mcp", label: "MCP" }
];

export const CARRIER_TYPE = [
    { id: "usAuthorization", label: "US Authorization" },
    { id: "canadian", label: "Canadian" },
    { id: "mexican", label: "Mexican" },
    { id: "common", label: "Common" },
    { id: "intermodal", label: "Intermodal" },
    { id: "localCarrier", label: "Local Carrier" },
    { id: "airFreight", label: "Air Freight" },
    { id: "oceanFreight", label: "Ocean Freight" }
];

export const BUSINESS_TYPES = [
    { id: "llc", label: "LLC - Limited Liability Company" },
    { id: "corp", label: "Inc - Corporation" },
    { id: "dba", label: "DBA - Doing Business As" },
    { id: "cpt", label: "Cooperative" },
    { id: "part", label: "Partnership" },
    { id: "scorp", label: "S Corporation" },
    { id: "sprop", label: "Sole Proprietorship" }
];

export const ROLE = [
    { id: "dispatcher", label: "DISPACHER" },
    { id: "billing", label: "BILLING" },
    { id: "emergency", label: "EMERGENCY" }
]

export const ENDORSEMENTS = [
    { id: "none", label: "None" },
    { id: "hazmat", label: "Hazmat" },
    { id: "tanker", label: "Tanker" },
    { id: "doubletriples", label: "Double/Triples" },
    { id: "xEndorsement", label: "X Endorsement" },
    { id: "other", label: "Other" }
]
export const RADIOBTN_VALS = [
    { id: true, label: "Yes" },
    { id: false, label: "No" },
];


function getDomain() {
    const url = window.location.hostname;
    let hostname = url.split(".").slice(-2);
    return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

const URLS = {
    CORE_API_KEY: process.env.REACT_APP_CORE_API_KEY,
    CORE_API: process.env.REACT_APP_CORE_API,
    NEXT_API: process.env.REACT_APP_NEXT_API,
    CF_API: process.env.REACT_APP_CF_API,
    CS_API: process.env.REACT_APP_CARRIER_SEARCH_API,
    APP_NEXT_WEB: process.env.REACT_APP_NEXT_WEB,
    APP_GPS_WEB: process.env.REACT_APP_GPS_WEB,
    APP_COMPLIANCE_WEB: process.env.REACT_APP_COMPLIANCE_WEB,
    APP_RECRUITING_WEB: process.env.REACT_APP_RECRUITING_WEB,
    APP_HOME_WEB: process.env.REACT_APP_HOME_WEB
};

const BASE_NAV = {
    SETTING: "/setting",
    ACCOUNTING: "/accounting",
    INTEGRATION: "/integration",
    INVOICE: "/billing/invoice",
    BILLING: "/billing",
    BILLING_DUMP: "/billing-dump",
    COMPANY: "/company",
    // DISPATCH: "/broker",
    DISPATCH: "/dispatch",
    BROKERAGE: "/brokerage",
    DUMP: "/dump",
    DRIVER: "/driver",
    CARRIER: "/carrier",
    EQUIPMENT: "/equipment",
    TRACKING: "/tracking",
    APPS: "/apps",
    ELD: "/eld",
    USERS: "/setting/users",
    EXPENSE: "/expense",
    PROFILE: "/setting/profile",
    PAYCHECK: "/paycheck",
    DOCUMENTS: "/documents",
    DASHBOARD: "/dashboard",
    SMS: "/sms",
    BUSINESS: "/business",
    BANK_ACCOUNT: "/baccount",
    SHIPPER: "/shippers",
    CHAT: "/chat",
    EMAIL: "/email",
    REPORTS: "/reports"
};

const cookies = new cookie.Cookies();

const getAccessToken = function getAccessToken() {
    return cookies.get("token", {
        path: "/",
        domain: getDomain()
    });
};

const getJwtToken = function getJwtToken() {
    return cookies.get("jwtToken", {
        path: "/",
        domain: getDomain()
    });
};

const pdfOptions = {
    enableLinks: true,
    margin: [0.5, 0.5, 0.5, 0.5],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true,
        useCORS: true
    },
    jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
        compressPDF: true
    }
};

export { BASE_NAV, URLS, getAccessToken, getJwtToken, getDomain, pdfOptions };
