import _get from "lodash/get";
import React from "react";
import { Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateGoogleUser } from "../actions/app/appAction";

function GoogleSignOut({ googleUserEmail, business, updateGU }) {
    async function signOut() {
        const auth2 = window.gapi.auth2.getAuthInstance();
        await auth2.signOut();
        window.location.reload();
    }

    return (
        <Row>
            <Col xs="12" md="12" style={{ padding: 10, textAlign: "right" }}>
                <Button
                    size="sm"
                    onClick={signOut}
                    color="danger"
                    style={{
                        fontWeight: 100,
                        padding: "2px 10px"
                    }}
                >
                    {"SIGN OUT"}
                </Button>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        googleUserEmail: _get(state, "appData.googleUser.email", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ updateGU: updateGoogleUser }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleSignOut);
