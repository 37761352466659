import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        return (
            <Col xs="12">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="U.S. Military"
                        onClick={() => { this.props.onEdit("Military") }}
                        status={this.props.status}
                    />
                    {
                        //  SS Carrier LLCPLEASE DO NOT CONTACT
                    }
                    <tbody>
                        <TableRow name="Branch of Service" value={info.branch} />
                        <TableRow name="Start Date" value={formatDate(info.start)} />
                        <TableRow name="End Date" value={formatDate(info.end)} />
                        <TableRow name="Can you obtain your DD214?" value={info.isDd214 ? "Yes" : "No"} />
                        <TableRow name="Rank at discharge" value={info.rank} />
                    </tbody>
                </Table>
            </Col>

        );
    }
}

export default Information;
