import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { capitalize } from '../../../utils/capitalize';

export class TrucksInformation extends React.Component {

  render() {
    const info = this.props.info[0]
    return (
      <Col xs={12} className="html2pdf__page-break">
        <Table bordered style={{ fontSize: "12px" }}        >
          <TableHeader name="Trucks Information"
            onClick={() => { this.props.onEdit("Driver and Eqipment") }}
            status={this.props.status}
          />
          {this.props.info.length === 1 ?
            <tbody>
              <TableRow name='Truck Type' value={info.truckType ? capitalize(info.truckType) : "N/A"} />
              <TableRow name='Count' value={info.count ? info.count : "N/A"} />
              <TableRow name='Year' value={info.year ? info.year : "N/A"} />
              <TableRow name='notes' value={info.notes ? capitalize(info.notes) : "N/A"} />
            </tbody>
            :
            this.props.info.map((item, i) => (
              <tbody>
                <TableRow name={capitalize(item.truckType)} value="##" />
                <TableRow name='Count' value={info.count ? info.count : "N/A"} />
                <TableRow name='Year' value={info.year ? info.year : "N/A"} />
                <TableRow name='notes' value={info.notes ? capitalize(info.notes) : "N/A"} />
              </tbody>
            ))
          }
        </Table>
      </Col>

    );
  }

}

export default TrucksInformation;
