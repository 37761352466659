import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { COUNTRIES, US_STATES, CA_STATES, TYPE_OF_ACCIDENT } from "../../../utils/constants";
import STYLES from "../STYLES";

const defaultValues = {
    accidentDate: "",
    typeOfAccident: "",
    hazmat: "",
    towed: "",
    comments: "",
    address_city: "",
    country: "US",
    state: "",
    isCommercial: "",
    fault: "",
    ticketed: ""
};

function validate(values) {
    let errors = {};
    if (!values.accidentDate) {
        errors.accidentDate = "Required.";
    }
    if (!values.typeOfAccident) {
        errors.typeOfAccident = "Required.";
    }

    if (!values.comments) {
        errors.comments = "Required.";
    }

    if (!values.country) {
        errors.country = "Required.";
    }
    if (!values.address_city) {
        errors.address_city = "Required.";
    }
    if (!values.state) {
        errors.state = "Required.";
    }

    return errors;
}

class AccidentalModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        let { initialValues } = this.props;
        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD ACCIDENT
                </ModalHeader>
                <ModalBody
                    style={{ padding: 30 }}
                >
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            const SEL_STATES =
                                values.country === "CA" ? CA_STATES : US_STATES;
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <InputDatePicker
                                                    md={6}
                                                    type={"date"}
                                                    name={"accidentDate"}
                                                    label={"Date of Accident / Incident"}
                                                />
                                                <SelectBox
                                                    md={6}
                                                    name={"typeOfAccident"}
                                                    label={"Type of Accident / Incident"}
                                                    options={TYPE_OF_ACCIDENT}
                                                />
                                            </Row>
                                            <Row style={{
                                                marginTop: 10,
                                                marginBottom: 5
                                            }}>
                                                <InputCheckbox
                                                    marginTop={22}
                                                    md={6}
                                                    name={"hazmat"}
                                                    label={"Hazmat Accident / Incident?"}
                                                />
                                                <InputCheckbox
                                                    marginTop={22}
                                                    md={6}
                                                    name={"towed"}
                                                    label={"Was the vehicle towed away?"}
                                                />
                                            </Row>
                                            <Row style={{
                                                marginTop: 10,
                                                marginBottom: 5
                                            }}>
                                                <InputBox
                                                    md={"6"}
                                                    name={"comments"}
                                                    label={"Comments"}
                                                    placeholder={"Enter Comments"}

                                                />

                                                <InputBox
                                                    md={"6"}
                                                    name={"address_city"}
                                                    label={"City"}
                                                    placeholder={"City"}
                                                />


                                                <SelectBox
                                                    md={"6"}
                                                    name={"country"}
                                                    label={"Country"}
                                                    options={COUNTRIES}
                                                />
                                                <SelectBox
                                                    md={"6"}
                                                    name={"state"}
                                                    label={"State"}
                                                    options={SEL_STATES}
                                                />
                                            </Row>

                                            <Row style={{
                                                marginTop: 10,
                                                marginBottom: 5
                                            }}>
                                                <InputCheckbox
                                                    marginTop={22}
                                                    md={6}
                                                    name={"isCommercial"}
                                                    label={"Were you in a Commercial Vehicle?"}
                                                />
                                                <InputCheckbox
                                                    md={3}
                                                    marginTop={22}
                                                    name={"fault"}
                                                    label={"Were you at fault? "}

                                                />
                                                <InputCheckbox
                                                    md={3}
                                                    marginTop={22}
                                                    name={"ticketed"}
                                                    label={"Were you ticketed? "}

                                                />
                                            </Row>
                                        </Col>
                                        <Col className="text-center">
                                            <button
                                                color="primary"
                                                type="submit"
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                            >
                                                {"SAVE ACCIDENT"}
                                            </button>
                                            <div className="text-center">
                                                <Button
                                                    color="link" onClick={this.props.toggle}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccidentalModal);
