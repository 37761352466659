import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow";
import TableHeader from "../../../components/TableHeader";
import { formatDate } from "../../../utils/momentUtil";

export class LicenseInformation extends React.Component {
    render() {
        const { info } = this.props;

        return (
            <Col xs="12" className="html2pdf__page-break">
                <Table bordered style={{ fontSize: "12px" }}>
                    <TableHeader name="License"
                        onClick={() => { this.props.onEdit("License") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow
                            name="License Number"
                            value={info.licenseNo}
                        />
                        <TableRow
                            name="State/Province"
                            value={info.licenseState}
                        />
                        <TableRow name="Country" value={info.licenseCountry} />
                        <TableRow name="License Class" value={info.licenseClass} />
                        <TableRow name="License Expiration Date" value={formatDate(info.licenseExp)} />
                        <TableRow name="Medical Expiration Date" value={formatDate(info.physicalExp)} />
                        {/* <TableRow name="Commercial Driver License" value="" /> */}

                        <TableRow name="Endorsements" value="##" />
                        <TableRow name="Tanker Endorsement" value={info.tanker ? "Yes" : "No"} />
                        <TableRow name="Doubles Triples Endorsement" value={info.doublesTriples ? "Yes" : "No"} />
                        <TableRow name="X Endorsement" value={info.xEndorsement ? "Yes" : "No"} />
                        {info.xEndorsement ?
                            <TableRow name="X Endorsement Expiry" value={formatDate(info.endorsementExp)} />
                            : null}
                        <TableRow name="Hazmat Endorsement" value={info.hazmat ? "Yes" : "No"} />
                        {info.xEndorsement ?
                            <TableRow name="Hazmat Endorsement Expiry" value={formatDate(info.hazmatExp)} />
                            : null}
                        <TableRow name="Other Endorsement" value={info.other ? "Yes" : "No"} />
                    </tbody>
                </Table>
            </Col>
        );
    }
}

export default LicenseInformation;
