import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import STYLES from '../STYLES';

const defaultValues = {
  license: '',
  count: '',
  year: '',
  notes: '',
  none: '',
  hazmat: '',
  tanker: 'US',
  doubleTripple: '',
  xEndorsement: '',
  other: '',
  selectedFile: null,
};

// function validate(values) {
//   let errors = {};

//   return errors;
// }

class DocumentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = async (values) => {
    this.props.onClose(values);
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <br />
          <h6>File Details:</h6>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified:{' '}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <p>Choose before Pressing the Upload button</p>
        </div>
      );
    }
  };

  render() {
    let { initialValues } = this.props;
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={''}
      >
        <ModalHeader toggle={this.props.toggle}>ADD DRIVER FILES</ModalHeader>
        <ModalBody style={{ padding: 30 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            // validate={validate}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={'12'}>
                      <Row>
                        <InputBox md={4} name={'types'} label={'Type'} />
                        <InputBox md={4} name={'name'} label={'Name'} />
                        <InputBox md={4} name={'notes'} label={'Notes'} />
                      </Row>
                      <input type='file' onChange={this.onFileChange} />
                      <button
                        color='primary'
                        type='submit'
                        className='btn btn-primary btn-sm pull-right'
                        style={STYLES.BUTTON_NEXT}
                        onClick={this.onFileUpload}
                      >
                        Upload!
                      </button>
                      {this.fileData()}
                    </Col>

                    <Col className='text-center'>
                      <button
                        color='primary'
                        type='submit'
                        className='btn btn-primary btn-sm'
                        style={STYLES.BUTTON_NEXT}
                      >
                        {'SAVE FILE'}
                      </button>
                      <div className='text-center'>
                        <Button color='link' onClick={this.props.toggle}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
    },
    business: _get(state, 'appData.business', {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsModal);
