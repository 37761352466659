import React from "react";
import { Col, Row, Button } from "reactstrap";
import STYLES from "../../containers/Driver/STYLES";

class TableHeader extends React.Component {
    render() {
        const { name, status, onClick } = this.props
        return (

            < thead >
                <tr>
                    <td
                        style={{
                            padding: "6px",
                            fontSize: 18
                        }}
                        colSpan="2"
                    >
                        <Row style={{ paddingRight: 15 }}>
                            <Col md={10}>{name}</Col>
                            <Col md={2} style={{
                                textAlign: "right",
                            }}>
                                {!status ?
                                    <Button
                                        size="sm"
                                        className="btn btn-sm"
                                        onClick={onClick}
                                        style={{ ...STYLES.BUTTON_NEXT, color: "white" }}
                                    >
                                        Edit
                                    </Button> : null}
                            </Col>
                        </Row>
                    </td>
                </tr>
            </thead >
        );
    }
}
export default TableHeader;