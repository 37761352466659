import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { formatPhoneNumber } from '../../../utils/Formator';
import { capitalize } from '../../../utils/capitalize';

export class Information extends React.Component {
  render() {
    const { info } = this.props;
    return (
      <Col xs='12' >
        <Table bordered style={{ fontSize: '12px' }}>
          <TableHeader
            name='General Information'
            onClick={() => { this.props.onEdit("General") }}
            status={this.props.status}
          />
          <tbody>
            <TableRow
              name='Carrier Name'
              value={info.name ? capitalize(info.name) : "N/A"}
            />
            <TableRow
              name='Carrier Type'
              value={info.carriertype ? capitalize(info.carriertype) : "N/A"}
            />
            <TableRow
              name='Business Type'
              value={info.businessType ? capitalize(info.businessType) : "N/A"}
            />
            <TableRow name='DOT#' value={info.dot ? info.dot : "N/A"} />
            <TableRow name='MC#' value={info.mc ? info.mc : "N/A"} />
            <TableRow name='EIN#' value={info.ein ? info.ein : "N/A"} />
            <TableRow name='Email' value={info.email ? info.email : "N/A"} />
            <TableRow
              name='Phone'
              value={info.phone ? formatPhoneNumber(info.phone) : "N/A"}
            />
            <TableRow
              name='Fax'
              value={info.fax ? formatPhoneNumber(info.fax) : "N/A"}
            />
            <TableRow
              name='Website'
              value={info.website ? info.website : "N/A"}
            />
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default Information;
