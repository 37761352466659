import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import STYLES from '../STYLES';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from '../../../actions/carrier/carrierActions';
import HosModal from './HosModal';
import HosView from './HosView';
import ConfirmationModal from '../../../modals/ConfirmationModal';

class HosForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMsg: '',
      successMsg: '',
      isOpen: false,
      isConfirmOpen: false,
    };
  }

  onModalClose = (data) => {
    const value = _get(this, 'props.hos', []);
    value.push(data);
    this.props.updateRegistration({ hos: value });
    this.toggle();
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  onItemDelete = ({ item, type }) => {
    if (type === 1) {
      const value = _get(this, 'props.hos', []);
      const items = value.filter((i) => i !== item);
      this.props.updateRegistration({ hos: items });
    }
    this.toggleConfirm({ item: null });
  };

  toggleConfirm = ({ item }) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      item: item,
    });
  };

  render() {
    const businessKey = _get(this, 'props.match.params.businessKey');
    const backUrl = `${CARRIER_BASE_URL}/${businessKey}/driversAndEqipments`;
    const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/moreDetails`;
    const { hos, isEdit, onClose } = this.props;
    const { isConfirmOpen, isOpen } = this.state;

    return (
      <div>
        {isConfirmOpen && (
          <ConfirmationModal
            message={'Are you sure to delete this item?'}
            isOpen={isConfirmOpen}
            toggle={() => this.toggleConfirm({ item: null })}
            onConfirm={() =>
              this.onItemDelete({
                item: this.state.item,
              })
            }
          />
        )}

        {isOpen && (
          <HosModal
            isOpen={isOpen}
            toggle={this.toggle}
            onClose={this.onModalClose}
          />
        )}

        <Row>
          <Col md={12}>
            <Row style={{ marginTop: 12, }} >
              <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                <h5
                  style={{ textAlign: 'left', }} >
                  HOS
                </h5>
              </Col>
              <Col md={4} style={{ textAlign: 'right' }}>
                <div>
                  {hos.length < 10 ? (
                    <Button
                      style={{
                        width: 100,
                        borderWidth: 0.5,
                      }}
                      color='primary'
                      className='btn btn-primary btn-sm'
                      onClick={this.toggle}
                    >
                      Add
                    </Button>
                  ) : (
                      <span
                        style={{
                          fontSize: 10,
                          color: 'red',
                        }}
                      >
                        Can't add more then 10
                    </span>
                    )}
                </div>
              </Col>
            </Row>

            {hos.map((item, i) => {
              return (
                <HosView
                  md={12}
                  hosInfo={item}
                  onClick={() => this.toggleConfirm({ item: item })}
                />
              );
            })}
          </Col>
        </Row>
        {
          isEdit ?
            <Row style={STYLES.BUTTON_WRAPPER}>
              <Col xs="6">
                <button
                  onClick={onClose}
                  className="btn btn-warning btn-sm"
                  style={STYLES.BUTTON_BACK}
                >
                  CANCEL
                                        </button>
              </Col>
              <Col xs="6">
                <button
                  className="btn btn-primary btn-sm"
                  style={STYLES.BUTTON_NEXT}
                  type="submit"
                >
                  SAVE
                                        </button>
              </Col>
            </Row>
            :

            <Row style={STYLES.BUTTON_WRAPPER}>
              <Col xs='6'>
                <Link
                  to={backUrl}
                  className='btn btn-warning btn-sm'
                  style={STYLES.BUTTON_BACK}
                >
                  BACK (DRIVERS & EQUIPMENTS)
            </Link>
              </Col>
              <Col xs='6'>
                <Link
                  to={nextUrl}
                  className='btn btn-warning btn-sm'
                  style={STYLES.BUTTON_NEXT}
                >
                  NEXT (MORE DETAILS)
            </Link>
              </Col>
            </Row>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: _get(state, 'appData.business', {}),
    hos: _get(state, 'carrierData.hos', []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateRegistration }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HosForm)
);
