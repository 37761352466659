import React from "react";
import _get from "lodash/get";
import { Field } from "formik";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
const STYLES = {
  formRow: {
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 0,
    marginBottom: -10
  },
  labelStyles: {
    marginBottom: -10,
    fontSize: 14,
    color: "black"
  }
};

const InputField = ({
  field,
  form: { touched, errors, values, setFieldValue },
  disabled = false,
  isPhone,
  ...props
}) => {
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);

  // let error = errors[field.name];
  // let touch = touched[field.name];
  // let valid = values[field.name];

  return (
    <FormGroup>
      <Label
        for={field.name}
        className={"label-color"}
        size={props.bsSize}
        style={STYLES.labelStyles}
      >
        {props.label}
      </Label>
      <Input
        id={field.name}
        {...field}
        {...props}
        invalid={Boolean(touch && error)}
        placeholder={props.placeholder || props.label}
        style={{ background: "None" }}
        onBlur={props.onBlur}
      />
      {touch && error && <FormFeedback style={{ fontSize: "12px" }}>{error}</FormFeedback>}
    </FormGroup>
  );
};

const InputBox = ({
  name,
  label,
  placeholder,
  type = "text",
  size = "sm",
  xs = "12",
  md = "3",
  min = "0",
  isPhone = false,
  onBlur = () => { },
}) => {
  return (
    <Col xs={xs} md={md} style={STYLES.formRow}>
      <Field
        type={type}
        min={min}
        bsSize={size}
        name={name}
        label={label}
        placeholder={placeholder}
        component={InputField}
        isPhone={isPhone}
        onBlur={onBlur}
      />
    </Col>
  );
};

export default InputBox;
