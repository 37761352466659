
import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        const suspendedTxt = "Has any license, permit or privilege ever been denied, suspended or revoked for any reason?"
        const withoutlicenseTxt = "Have you ever been convicted of driving during license suspension or revocation, or driving without a valid license or an expired license, or are any charges pending?"
        const alcoholTxt = "Have you ever been convicted for any alcohol or controlled substance related offense while operating a motor vehicle, or are any charges pending?"
        const illegalTxt = "Have you ever been convicted for possession, sale or transfer of an illegal substance(including but not limited to, marijuana, amphetamines, or derivatives thereof) while on duty, or are any charges pending?"
        const recklessTxt = "Have you ever been convicted of reckless driving, careless driving or careless operation of a motor vehicle, or are any charges pending?"
        const alcoholTestTxt = "Have you ever tested positive, or refused to test on drug or alcohol test by an employer to whom you applied, but did not obtain safety - sensitive transportation work covered by DOT agency drug and alcohol testing rules in past three years, or have you ever tested positive or refused to test on any DOT - mandated drug or pre - employment alcohol test?"

        return (
            <Col xs="12">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Motor Vehicle Record"
                        onClick={() => { this.props.onEdit("MVR") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name={suspendedTxt}
                            value={info.suspended ? `${formatDate(info.suspendedDate)} : ${info.suspendedComments}` : "No"} />
                        <TableRow name={withoutlicenseTxt}
                            value={info.withoutlicense ? `${formatDate(info.withoutlicenseDate)} : ${info.withoutlicenseComments}` : "No"} />
                        <TableRow name={alcoholTxt}
                            value={info.alcohol ? `${formatDate(info.alcoholDate)} : ${info.alcoholComments}` : "No"} />
                        <TableRow name={illegalTxt}
                            value={info.illegal ? `${formatDate(info.illegalDate)} : ${info.illegalComments}` : "No"} />
                        <TableRow name={recklessTxt}
                            value={info.reckless ? `${formatDate(info.recklessDate)} : ${info.recklessComments}` : "No"} />
                        <TableRow name={alcoholTestTxt}
                            value={info.alcoholTest ? `${formatDate(info.alcoholTestDate)} : ${info.alcoholTestComments}` : "No"} />

                    </tbody>
                </Table>
            </Col>

        );
    }

}

export default Information;
