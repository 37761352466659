import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Heading extends Component {
    render() {
        const { details } = this.props;
        return (
            <div>
                {details.logo && <center>
                    <img
                        src={details.logo}
                        alt="Logo"
                        style={{
                            width: "60px",
                            height: "60px",
                            marginBottom: "15px"
                        }}
                    />
                </center>}
                <div style={{ textAlign: "center" }} >
                    <h2 style={{ marginBottom: 0 }}>{details.name}</h2>
                    <h6
                        style={{
                            marginBottom: 5,
                            fontSize: 12
                        }}
                    >
                        {details.address}
                    </h6>
                    <h6
                        style={{
                            marginBottom: 20,
                            fontSize: 12
                        }}
                    >
                        {details.disContact}
                    </h6>
                </div>
            </div>
        );
    }
}
export default withRouter(connect()(Heading));
