import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { validate } from "./formHelpers";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from "../../../actions/registration/registrationActions";

class FMCSRForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: ""
        };
    }

    componentDidMount() { }

    onSubmit = async values => {
        await this.props.updateRegistration({ fmcsr: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/review`;

        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };

    render() {
        let { initialValues, isEdit, onClose } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/hos`;
        const confirmOne =
            "Under FMCSR 391.15, are you currently disqualified from driving a commercial motor vehicle? [49 CFR 391.15]";
        const confirmTwo =
            "Has your license or privilege to drive ever been suspended or revoked for any reason? [49 CFR 391.21(b)(9)]*";
        const confirmThree =
            "Have you ever been denied a license, permit, or privilege to operate a motor vehicle?[49 CFR(b)(9)]*";
        const confirmFour =
            "Within the past two years, have you tested positive, or refused to test, on a pre-employment drug or alcohol test by an employer to whom you applied, but did not obtain, safety-sensitive transportation work covered by DOT agency drug and alcohol testing rules? [49 CFR 40.25(j)]*";
        const confirmFive =
            "In the past three (3) years, have you ever been convicted of any of the following offenses committed during on-duty time [49 C.F.R. 391.15 and 49 C.F.R. 395.2] :-";

        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <InputCheckbox
                                        name={"confirmOne"}
                                        label={confirmOne}
                                        checked={values.confirmOne}
                                        marginTop={isEdit ? "25" : "20"}
                                    />
                                    <InputCheckbox
                                        name={"confirmTwo"}
                                        label={confirmTwo}
                                        marginTop={isEdit ? "25" : "20"}
                                        checked={values.confirmTwo}
                                    />
                                    <InputCheckbox
                                        name={"confirmThree"}
                                        label={confirmThree}
                                        checked={values.confirmThree}
                                        marginTop={isEdit ? "25" : "20"}

                                    />
                                    <InputCheckbox
                                        name={"confirmFour"}
                                        label={confirmFour}
                                        checked={values.confirmFour}
                                        marginTop={isEdit ? "25" : "20"}

                                    />
                                    <InputCheckbox
                                        name={"confirmFive"}
                                        label={confirmFive}
                                        checked={values.confirmFive}
                                        marginTop={isEdit ? "25" : "20"}

                                    />
                                    <Col>
                                        <ul>
                                            <li>
                                                Driving a commercial motor
                                                vehicle with a blood alcohol
                                                concentration ("BAC") of .04
                                                percent or more.
                                            </li>
                                            <li>
                                                Driving under the influence of
                                                alcohol, as prescribed by state
                                                law.
                                            </li>
                                            <li>
                                                Refusal to undergo drug and
                                                alcohol testing as required by
                                                any jurisdiction for the
                                                enforcement of Federal Motor
                                                Carrier Safety Act regulations.
                                            </li>
                                            <li>
                                                Driving a commercial motor
                                                vehicle under the influence of
                                                any 21 C.F.R. 1308.11 Schedule I
                                                identified controlled substance,
                                                an amphetamine, a narcotic drug,
                                                a formulation of an amphetamine,
                                                or a derivative of a narcotic
                                                drug.
                                            </li>
                                            <li>
                                                Transportation, possession, or
                                                unlawful use of a 21 C.F.R.
                                                1308.11 Schedule I identified
                                                controlled substance,
                                                amphetamines, narcotic drugs,
                                                formulations of an amphetamine,
                                                or derivatives of narcotic drugs
                                                while you were on duty driving
                                                for a motor carrier.
                                            </li>
                                            <li>
                                                Leaving the scene of an accident
                                                while operating a commercial
                                                motor vehicle.
                                            </li>
                                            <li>
                                                Or any other felony involving
                                                the use of a commercial motor
                                                vehicle.
                                            </li>
                                            <li>
                                                In the past three (3) years,
                                                have you ever been convicted of
                                                any of the following offenses
                                                committed during on-duty time
                                                [49 C.F.R. 391.15 and 49 C.F.R.
                                                395.2].
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (HOS)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (Review)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, "registrationData.fmcsr", {}),
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FMCSRForm)
);
