import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { ROLE } from '../../../utils/constants';
import STYLES from '../STYLES';

const defaultValues = {
  role: '',
  firstname: '',
  lastname: '',
  mobile: '',
  area: '',
  notes: '',
};

function validate(values) {
  let errors = {};
  if (!values.role) {
    errors.role = 'Required.';
  }
  if (!values.firstname) {
    errors.firstname = 'Required.';
  }
  if (!values.lastname) {
    errors.lastname = 'Required.';
  }
  if (!values.mobile) {
    errors.mobile = 'Required.';
  }
  if (!values.area) {
    //errors.area = 'Required.';
  }

  return errors;
}

class InsuranceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = async (values) => {
    this.props.onClose(values);
  };

  render() {
    let { initialValues } = this.props;
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={''}
      >
        <ModalHeader toggle={this.props.toggle}>ADD CONTACT</ModalHeader>
        <ModalBody style={{ padding: 30 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={validate}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col>
                          <Row>
                            <SelectBox
                              options={ROLE}
                              md={2}
                              name={'role'}
                              label={'Role'}
                            />
                            <InputBox
                              md={3}
                              name={'firstname'}
                              label={'First Name'}
                            />
                            <InputBox
                              md={3}
                              name={'lastname'}
                              label={'Last Name'}
                            />
                            <InputBox md={4} name={'email'} label={'Email'} />
                            <InputBox md={3} name={'mobile'} label={'Mobile'} />
                            <InputBox md={3} name={'fax'} label={'Fax'} />
                            <InputBox md={3} name={'area'} label={'Area'} />
                            <InputBox md={3} name={'notes'} label={'Notes'} />
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col className='text-center'>
                      <button
                        color='primary'
                        type='submit'
                        className='btn btn-primary btn-sm'
                        style={STYLES.BUTTON_NEXT}
                      >
                        {'SAVE CONTACT'}
                      </button>
                      <div className='text-center'>
                        <Button color='link' onClick={this.props.toggle}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
    },
    business: _get(state, 'appData.business', {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceModal);
