import React from "react";

export default function TableRow({ name, value }) {
    return (
        (value === "##") ?
            <tr style={{ width: "100%" }}>
                <td
                    colSpan="2"
                    style={{
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        paddingTop: "2px",
                        paddingBottom: "2px"
                    }}>{name}</td>
            </tr>
            :
            <tr style={{ width: "50%" }}>
                <td style={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingTop: "2px",
                    paddingBottom: "2px"
                }}>{name}</td>
                <td style={{
                    width: "50%",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingTop: "2px",
                    paddingBottom: "2px"
                }}>{value}</td>
            </tr>
    );
}