import { isUnvalidDate } from "../../../../../utils/momentUtil";

export const defaultValues = {
    isUnemployment: false,
    start: "",
    end: "",
    comments: "",
    country: "US"
}

export function validate(values) {
    let errors = {};
    values.isUnemployment = true
    if (isUnvalidDate(values.start, values.end)) {
        errors.end = "Select valid date"
    }
    if (values.isUnemployment && !values.start) {
        errors.start = "Required."
    }
    if (values.isUnemployment && !values.end) {
        errors.end = "Required."
    }
    if (values.isUnemployment && !values.comments) {
        errors.comments = "Required."
    }
    return errors;
}
