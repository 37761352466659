import React from 'react';
import { Row, Col } from 'reactstrap';
import MoreDetailsContainer from './MoreDetailsContainer';
import ProgressBar from '../../../components/ProgressBar';

export class MoreDetailContainer extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs="12">
            <ProgressBar percentage={80} />
          </Col>

          <Col xs='12'>
            <MoreDetailsContainer {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default MoreDetailContainer;
