import React from "react";
import { Field } from "formik";
import { Label, Col } from "reactstrap";
import ReactstrapSelect from "./ReactstrapSelect";

const STYLES = {
  formRow: {
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 0
  },
  labelStyles: {
    marginBottom: -10,
    fontSize: 14,
    color: "black",

  }
};

const SelectBox = ({
  name,
  loading,
  labelClass = "",
  options,
  label,
  type = "text",
  size = "sm",
  xs = "12",
  md = "3",
  selVal = "id",
  selLabel = "label",
  prefix,
  selLabelTwo,
  selLabelSmall,
  selLabelSmalltwo,
  placeholder,
}) => {

  return (
    <Col xs={xs} md={md} style={STYLES.formRow}>
      <Label
        size={size}
        for={name}
        className={labelClass}
        style={STYLES.labelStyles}
      >
        {label}
      </Label>
      <Field
        bsSize={size}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={loading}
        component={ReactstrapSelect}
        inputprops={{
          name,
          id: name,
          options,
          defaultOption: label,
          selVal,
          prefix,
          selLabel,
          selLabelTwo,
          selLabelSmall,
          selLabelSmalltwo
        }}
      />
    </Col>
  );
};

export default SelectBox;
