import React from "react";
import { Field } from "formik";
import { Col } from "reactstrap";

let STYLES = {
    formRow: {
        paddingRight: 5,
        paddingLeft: 5
        // marginTop: 10
    },
    label: {
        display: "block",
        marginLeft: 25
        //,marginTop: -18
    }
};

const RadioButton = ({
    field: { name, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    value,
    className,
    checked,
    marginTop,
    ...props
}) => {
    // const error = errors && errors[name];
    var input = <input
        name={name}
        id={id}
        type="radio"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
    />

    if (marginTop) {
        STYLES.label = {
            display: "block",
            marginLeft: 25,
            marginTop: -marginTop
        }
    }
    else {
        STYLES.label = {
            display: "block",
            marginLeft: 25,
            marginTop: -18
        }
    }
    return (
        <div>
            {input}
            <label htmlFor={name} style={STYLES.label}>
                {label}
            </label>
            {/* {(error && value) && (
                <div style={{ marginTop: -10 }} className="text-danger">
                    {error}
                </div>
            )} */}
        </div>
    );
};

const InputRadioButton = ({
    name,
    label,
    hint,
    value,
    checked = false,
    type = "text",
    size = "sm",
    xs = "12",
    md = "12",
    labelClass = "",
    marginTop
}) => {
    return (
        <Col xs={xs} md={md} style={STYLES.formRow}>
            <Field
                type={type}
                name={name}
                label={label}
                hint={hint}
                value={value}
                labelClass={labelClass}
                component={RadioButton}
                marginTop={marginTop}
                checked={checked}
            />
        </Col>
    );
};

export default InputRadioButton;


