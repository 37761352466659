import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import _get from "lodash/get";
import { getBusiness } from "./actions/app/appAction";
import Home from "./containers/Home";

import "./App.css";

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() { }

    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", ""),
        isSignInOpen: _get(state, "appData.isSignInOpen", true)
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { getBusiness }
    )(App)
);
