import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import InputRadioButton from "../../../components/InputRadioButton/InputRadioButton";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import Wizard from "./Wizard";
import SignatureCanvas from "react-signature-canvas";
import InputBox from "../../../components/InputBox/InputBox";
import { getTerms } from "./helpers";

import CertifyInformation1 from "./termsDetails/CertifyInformation1";
//import CertifyInformation4 from "./termsDetails/CertifyInformation4";
//import CertifyInformation5 from "./termsDetails/CertifyInformation5";

class SubmitModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    sigPad = {};
    trim = () => {
        this.setState({
            signURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
        });
    };

    clear = () => {
        this.sigPad.clear()
        delete this.state.signURL
        delete this.state.signDate
        this.setState({})
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        const { name, dot, mc } = this.props;
        const terms = getTerms(this.props);

        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                {/*<ModalHeader toggle={this.props.toggle}>
                    Submit Application
                </ModalHeader>*/}
                <ModalBody style={{ padding: 25 }}>
                    <Wizard
                        initialValues={{
                            fName: "",
                            mName: "",
                            lName: ""
                        }}
                        onSubmit={(values, actions) => {
                            this.props.onClose(this.state.signURL);
                        }}
                    >
                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (values.t1 !== "true") {
                                    errors.t1 = "Required";
                                }
                                return errors;
                            }}
                        >
                            {props => (
                                <React.Fragment>
                                    <Row
                                        style={{
                                            marginBottom: 20
                                        }}
                                    >
                                        <Col
                                            xs="12"
                                            className="text-uppercase"
                                            style={{
                                                textAlign: "center",
                                                marginBottom: 15
                                            }}
                                        >
                                            <h5>{terms.one.header}</h5>
                                        </Col>
                                        <Col xs="12">
                                            <CertifyInformation1 />
                                        </Col>
                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.one.accept}
                                            name="t1"
                                            value={true}
                                        />
                                        {props.errors.t1 && (
                                            <div
                                                className="text-danger"
                                                style={{ paddingLeft: 18, textAlign: "center" }} >
                                                Required.
                                                </div>)}

                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.one.reject}
                                            name="t1"
                                            value={false}
                                        />
                                    </Row>
                                </React.Fragment>
                            )}
                        </Wizard.Page>
                        {/* <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (values.t4 !== "true") {
                                    errors.t4 = "Required";
                                }
                                return errors;
                            }}
                        >
                            {props => (
                                <React.Fragment>
                                    <Row
                                        style={{
                                            marginBottom: 20
                                        }}
                                    >
                                        <Col
                                            xs="12"
                                            className="text-uppercase"
                                            style={{
                                                textAlign: "center",
                                                marginBottom: 15
                                            }}
                                        >
                                            <h5>{terms.four.header}</h5>
                                        </Col>
                                        <Col xs="12">
                                            <CertifyInformation4 />
                                        </Col>
                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.four.accept}
                                            name="t4"
                                            value={true}
                                        />
                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.four.reject}
                                            name="t4"
                                            value={false}
                                        />
                                    </Row>
                                </React.Fragment>
                            )}
                        </Wizard.Page>
                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (values.t5 !== "true") {
                                    errors.t5 = "Required";
                                }
                                return errors;
                            }}
                        >
                            {props => (
                                <React.Fragment>
                                    <Row
                                        style={{
                                            marginBottom: 20
                                        }}
                                    >
                                        <Col
                                            xs="12"
                                            className="text-uppercase"
                                            style={{
                                                textAlign: "center",
                                                marginBottom: 15
                                            }}
                                        >
                                            <h5>{terms.five.header}</h5>
                                        </Col>
                                        <Col xs="12">
                                            <CertifyInformation5 />
                                        </Col>
                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.five.accept}
                                            name="t5"
                                            value={true}
                                        />
                                        <InputRadioButton
                                            marginTop={22}
                                            label={terms.five.reject}
                                            name="t5"
                                            value={false}
                                        />
                                    </Row>
                                </React.Fragment>
                            )}
                        </Wizard.Page> */}

                        <Wizard.Page
                            validate={values => {
                                const errors = {};
                                if (!this.state.signDate) {
                                    errors.signData = "Signature is required.";
                                }
                                if (!values.name) {
                                    //        errors.name = "Required.";
                                } else if (values.name !== name) {
                                    //        errors.name = "Did not matched.";
                                }

                                if (!values.dot) {
                                    //        errors.dot = "Required.";
                                } else if (values.dot !== dot) {
                                    //        errors.dot = "Did not matched.";
                                }
                                if (!values.mc) {
                                    //        errors.mc = "Required.";
                                } else if (values.mc !== mc) {
                                    //        errors.mc = "Did not matched.";
                                }
                                if (!values.term) {
                                    errors.term = "Accept terms.";
                                }
                                return errors;
                            }}
                        >
                            {props => (
                                <React.Fragment>
                                    <Row>
                                        <Col
                                            xs="12"
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <h4>Sign here</h4>
                                            {this.state.signDate ? <Button
                                                size="sm"
                                                style={{
                                                    color: "#00000000",
                                                    backgroundColor: "#00000000",
                                                    border: "none",
                                                    cursor: "none",
                                                    marginRight: 10
                                                }}
                                            >
                                                clear
                                            </Button> : null}

                                            <span style={{ border: "1px solid black" }}>
                                                <SignatureCanvas
                                                    penColor="black"
                                                    backgroundColor="#f8f9fa"
                                                    canvasProps={{
                                                        width: 350,
                                                        height: 100,
                                                        className: "sigCanvas"
                                                    }}
                                                    ref={ref => {
                                                        this.sigPad = ref;
                                                    }}
                                                    onEnd={() => {
                                                        this.trim();
                                                        delete props.errors.signData
                                                        this.setState({
                                                            signDate: new Date()
                                                        });
                                                    }}
                                                />
                                            </span>
                                            {this.state.signDate ? <Button
                                                size="sm"
                                                style={{ marginLeft: 10 }}
                                                color="primary"
                                                onClick={this.clear}
                                            >
                                                Clear
                                            </Button> : null}
                                            {props.errors.signData && (
                                                <div
                                                    className="text-danger"
                                                    style={{
                                                        padding: 10,
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Signature is required.
                                                </div>
                                            )}
                                            <Row style={{
                                                marginLeft: 20,
                                                marginRight: 20
                                            }}>
                                                <InputBox
                                                    md={4}
                                                    name="name"
                                                    placeholder="Carrier Name"
                                                />
                                                <InputBox
                                                    md={4}
                                                    name="dot"
                                                    placeholder="DOT"
                                                />
                                                <InputBox
                                                    md={4}
                                                    name="mc"
                                                    placeholder="MC"
                                                />
                                            </Row>
                                            <div
                                                style={{
                                                    marginLeft: 20,
                                                    textAlign: "left"
                                                }}
                                            >
                                                <InputCheckbox
                                                    type="checkbox"
                                                    name="term"
                                                    marginTop={22}
                                                    label="Accept all the terms and conditions."
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </Wizard.Page>
                    </Wizard>
                    <div className="text-center">
                        <Button
                            size="sm"
                            color="link"
                            onClick={this.props.toggle}
                        >
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitModal);
