import React from "react";
import { Row, Col } from "reactstrap";
// import STYLES from '../STYLES';

export class ContentContainer extends React.Component {
    render() {
        const { business } = this.props;
        const businessName = business.name;
        const driverAge = business.driverAge;
        return (
            <div>
                <Row>
                    <Col xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            style={{
                                marginTop: 25,
                                marginBottom: 25,
                                textAlign: "center"
                            }}
                        >
                            Requirements
                        </h5>
                        <h6>
                            To qualify with {businessName}, you must meet the
                            following criteria:
                        </h6>
                        <ul>
                            <li>Must be {driverAge} years of age or older</li>
                            <li>Must have a valid Class A CDL</li>
                            <li>
                                Preferred - Minimum 1 Year experience driving
                                Class A
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentContainer;
