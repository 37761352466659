import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil"

export class Information extends React.Component {
    render() {
        const info_ = this.props.info[0]
        return (
            <Col xs={12} className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Unemployment"
                        onClick={() => { this.props.onEdit("History") }}
                        status={this.props.status}
                    />
                    {this.props.info.length === 1 ?
                        <tbody>
                            <TableRow name="Start Date" value={formatDate(info_.start)} />
                            <TableRow name="End Date" value={formatDate(info_.end)} />
                            <TableRow name="Comment" value={info_.comments} />
                        </tbody> :
                        this.props.info.map((item, i) => (
                            <tbody>
                                <TableRow name={"Unemployment " + (i + 1)} value="##" />
                                <TableRow name="Start Date" value={formatDate(item.start)} />
                                <TableRow name="End Date" value={formatDate(item.end)} />
                                <TableRow name="Comment" value={item.comments} />
                            </tbody>
                        ))
                    }
                </Table>
            </Col>
        );
    }
}

export default Information;
