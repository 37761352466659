import _get from "lodash/get";
import * as TYPES from "./actionTypes";
import axiosNext from "./axiosNext";
import { URLS } from "../../constants";

export function setLoading(value) {
    return { type: TYPES.LOADING, value };
}

export function setRecruiting(value) {
    return { type: TYPES.RECRUITING, value };
}

export function onRegisterDriver(value) {
    return {
        type: TYPES.REGISTER_DRIVER,
        value
    };
}

export function setRegisterId(value) {
    return {
        type: TYPES.REGISTER_ID,
        value
    };
}
export function setRegisterStatus(value) {
    return {
        type: TYPES.REGISTER_STATUS,
        value
    };
}

export function updateDriverInfo(payload) {
    return {
        type: TYPES.REGISTER_DRIVER_INFO,
        key: payload.key,
        value: payload.value
    };
}

export function saveDriverPersonalInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_PERSONAL,
        value
    };
}

export function saveDriverPersonalAddress(value) {
    return {
        type: TYPES.REGISTER_DRIVER_PERSONAL_ADDRESS,
        value
    };
}

export function updateDriverGeneralInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_GENERAL,
        value
    };
}

export function updateDriverLicenseInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_LICENSE,
        value
    };
}

export function updateDriverViolationInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_VIOLATION,
        value
    };
}
export function updateDriverAccidentInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_ACCIDENT,
        value
    };
}

export function updateDriverMilitaryInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_MILITARY,
        value
    };
}

export function updateDriverEmployedInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_EMPLOYED,
        value
    };
}

export function updateDriverTrainingInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_TRAINING,
        value
    };
}

export function updateDriverHosInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_HOS,
        value
    };
}
export function updateDriverMVRInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_MVR,
        value
    };
}

export function updateDriverUnEmployedInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_UNEMPLOYED,
        value
    };
}

export function updateDriverFmcsrInfo(value) {
    return {
        type: TYPES.REGISTER_DRIVER_FMCSR,
        value
    };
}

export function getRegistration() {
    return async (dispatch, getState) => {
        const googleAuth = _get(getState(), "appData.googleUser", {});
        const businessId = _get(getState(), "appData.business.id");

        try {
            const response = await axiosNext({
                url: `/secure/recruiting/${businessId}/driver`,
                method: "get",
                headers: {
                    "X-G-Email": googleAuth.email,
                    "X-G-Authorization": googleAuth.token
                }
            });
            let _response = _get(response, "data", {});

            dispatch(setRegisterId(_response._id));
            dispatch(setRegisterStatus(_response.status));
            dispatch(saveDriverPersonalInfo(_response.personal));
            dispatch(saveDriverPersonalAddress(_response.personalAddress));
            dispatch(updateDriverGeneralInfo(_response.general));
            dispatch(updateDriverLicenseInfo(_response.license));
            dispatch(updateDriverViolationInfo(_response.violation));
            dispatch(updateDriverAccidentInfo(_response.accident))
            dispatch(updateDriverMilitaryInfo(_response.military));
            dispatch(updateDriverEmployedInfo(_response.employment));
            dispatch(updateDriverTrainingInfo(_response.training));
            dispatch(updateDriverUnEmployedInfo(_response.unemployment));
            dispatch(updateDriverHosInfo(_response.hos));
            dispatch(updateDriverMVRInfo(_response.mvr))
            dispatch(updateDriverFmcsrInfo(_response.fmcsr));

            return _response;
        } catch (error) {
            const statusCode = _get(error, "response.status");
            const statusText = _get(error, "response.statusText");
            const message =
                _get(error, "response.data.errors[0].message") ||
                `${statusCode} ${statusText}`;
            return message;
        }
    };
}

export function updateRegistration(params) {
    return async (dispatch, getState) => {
        try {
            if (params.personal) {
                dispatch(saveDriverPersonalInfo(params.personal));
            }
            if (params.personalAddress) {
                dispatch(saveDriverPersonalAddress(params.personalAddress));
            }
            if (params.general) {
                dispatch(updateDriverGeneralInfo(params.general));
            }
            if (params.employment) {
                dispatch(updateDriverEmployedInfo(params.employment))
            }
            if (params.hos) {
                dispatch(updateDriverHosInfo(params.hos))
            }
            if (params.mvr) {
                dispatch(updateDriverMVRInfo(params.mvr))
            }
            if (params.license) {
                dispatch(updateDriverLicenseInfo(params.license))
            }
            if (params.violation) {
                dispatch(updateDriverViolationInfo(params.violation))
            }
            if (params.accident) {
                dispatch(updateDriverAccidentInfo(params.accident))
            }
            if (params.military) {
                dispatch(updateDriverMilitaryInfo(params.military))
            }
            if (params.training) {
                dispatch(updateDriverTrainingInfo(params.training))
            }
            if (params.unemployment) {
                dispatch(updateDriverUnEmployedInfo(params.unemployment))
            }

            const googleAuth = _get(getState(), "appData.googleUser", {});
            if (params.status === "SUBMITTED") {
                dispatch(setRegisterStatus(params.status))
            }
            else if (googleAuth.email) {
                dispatch(_updateRegistration(params));
            }
            return params;
        } catch (error) {
            return {};
        }
    };
}

export function _updateRegistration(params) {
    return async (dispatch, getState) => {
        try {
            const googleAuth = _get(getState(), "appData.googleUser", {});
            const businessId = _get(getState(), "appData.business.id");

            params._id = _get(getState(), "registrationData.registerId");
            params.businessId = businessId

            const response = await axiosNext({
                url: `/secure/recruiting/${businessId}/driver`,
                method: "post",
                headers: {
                    "X-G-Email": googleAuth.email,
                    "X-G-Authorization": googleAuth.token
                },
                data: params
            });
            let _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const statusCode = _get(error, "response.status");
            const statusText = _get(error, "response.statusText");
            const message =
                _get(error, "response.data.errors[0].message") ||
                `${statusCode} ${statusText}`;
            return message;
        }
    };
}

export function uploadDocuments(params) {
    return async (dispatch, getState) => {
        try {
            const googleAuth = _get(getState(), "appData.googleUser", {});
            const businessId = _get(getState(), "appData.business.id");

            params._id = _get(getState(), "registrationData.registerId");
            params.businessId = businessId

            const response = await axiosNext({
                url: `/secure/recruiting/${businessId}/upload`,
                method: "post",
                headers: {
                    "X-G-Email": googleAuth.email,
                    "X-G-Authorization": googleAuth.token
                },
                data: params
            });
            let _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const statusCode = _get(error, "response.status");
            const statusText = _get(error, "response.statusText");
            const message =
                _get(error, "response.data.errors[0].message") ||
                `${statusCode} ${statusText}`;
            return message;
        }
    };
}

export function sendEmail(params) {
    return async (dispatch, getState) => {
        try {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(params),
                redirect: "follow"
            };
            const _response = await fetch(
                `${URLS.CF_API}/email`,
                requestOptions
            );
            return await _response.json();
        } catch (error) {
            const statusCode = _get(error, "response.status");
            const statusText = _get(error, "response.statusText");
            const message =
                _get(error, "response.data.errors[0].message") ||
                `${statusCode} ${statusText}`;
            return message;
        }
    };
}
