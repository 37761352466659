import React from "react";
import { Row, Col } from "reactstrap";
import LicenseForm from "./LicenseForm";

export class LicenseContent extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            style={{
                                marginTop: 5,
                                marginBottom: 10,
                                textAlign: "center"
                            }}
                        >
                            {"License information"}
                        </h5>
                        <LicenseForm />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default LicenseContent;
