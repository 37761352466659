import React from 'react';
import { Row, Col } from 'reactstrap';
import FileuploadForm from './DocumentsForm';

export class DocumentsContent extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs='12' style={{ margin: '10px auto', fontSize: 12 }}>
            <h5
              style={{
                marginTop: 5,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >Documents</h5>
            <FileuploadForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default DocumentsContent;
