import React from "react";
import { Field } from "formik";
import { Col } from "reactstrap";

let STYLES = {
    formRow: {
        paddingRight: 5,
        paddingLeft: 5
        // marginTop: 10
    },
    label: {
        display: "block",
        marginLeft: 25
        //,marginTop: -18
    }
};

const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    checked,
    disabled,
    className,
    marginTop,
    ...props
}) => {
    const error = errors && errors[name];
    var input
    if (checked === undefined) {
        input = <input
            name={name}
            id={id}
            type="checkbox"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
        />
    } else {
        input = <input
            name={name}
            id={id}
            type="checkbox"
            value={value}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
        />

    }
    if (marginTop) {
        STYLES.label = {
            display: "block",
            marginLeft: 25,
            marginTop: -marginTop
        }
    }
    else {
        STYLES.label = {
            display: "block",
            marginLeft: 25,
            marginTop: -18
        }
    }
    return (
        <div>
            {input}
            <label htmlFor={name} style={STYLES.label}>
                {label} {checked}
            </label>
            {error && (
                <div style={{ marginTop: -10 }} className="text-danger">
                    {error}
                </div>
            )}
        </div>
    );
};

const InputDatePicker = ({
    name,
    label,
    checked,
    disabled = false,
    text,
    hint,
    type = "text",
    size = "sm",
    xs = "12",
    md = "12",
    labelClass = "",
    marginTop
}) => {
    if (disabled)
        checked = false
    return (
        <Col xs={xs} md={md} style={STYLES.formRow}>
            <Field
                type={type}
                name={name}
                label={label}
                text={text}
                checked={checked}
                disabled={disabled}
                hint={hint}
                labelClass={labelClass}
                component={Checkbox}
                marginTop={marginTop}
            />
        </Col>
    );
};

export default InputDatePicker;
