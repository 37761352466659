export const defaultValues = {
    suspended: false,
    suspendedDate: "",
    suspendedComments: "",
    withoutlicense: false,
    withoutlicenseDate: "",
    withoutlicenseComments: "",
    alcohol: false,
    alcoholDate: "",
    alcoholComments: "",
    illegal: false,
    illegalDate: "",
    illegalComments: "",
    reckless: false,
    recklessDate: "",
    recklessComments: "",
    alcoholTest: false,
    alcoholTestDate: "",
    alcoholTestComments: ""
}
export function validate(values) {
    let errors = {};

    if (values.suspended && !values.suspendedDate) {
        errors.suspendedDate = "Required.";
    }
    if (values.suspended && !values.suspendedComments) {
        errors.suspendedComments = "Required.";
    }
    if (values.withoutlicense && !values.withoutlicenseDate) {
        errors.withoutlicenseDate = "Required.";
    }
    if (values.withoutlicense && !values.withoutlicenseComments) {
        errors.withoutlicenseComments = "Required.";
    }
    if (values.alcohol && !values.alcoholDate) {
        errors.alcoholDate = "Required.";
    }
    if (values.alcohol && !values.alcoholComments) {
        errors.alcoholComments = "Required.";
    }
    if (values.illegal && !values.illegalDate) {
        errors.illegalDate = "Required.";
    }
    if (values.illegal && !values.illegalComments) {
        errors.illegalComments = "Required.";
    }
    if (values.reckless && !values.recklessDate) {
        errors.recklessDate = "Required.";
    }
    if (values.reckless && !values.recklessComments) {
        errors.recklessComments = "Required.";
    }
    if (values.alcoholTest && !values.alcoholTestDate) {
        errors.alcoholTestDate = "Required.";
    }
    if (values.alcoholTest && !values.alcoholTestComments) {
        errors.alcoholTestComments = "Required.";
    }
    return errors;
}
