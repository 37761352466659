import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../../../components/InputBox/InputBox";
import SelectBox from "../../../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../../../components/InputDatePicker/InputDatePicker";
import InputCheckbox from "../../../../../components/InputCheckbox/InputCheckbox";
import { COUNTRIES, US_STATES, CA_STATES } from "../../../../../utils/constants";
import STYLES from "../../../STYLES";
import { defaultValues, validate } from "./formHelpers"

class EmploymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        const terminatedText = "Were you terminated/discharged/laid off?";
        const currentEmployerText = "Is this your current employer?";
        const contactEmployerText =
            "May we contact this employer at this time?";
        const isOperateText = "Did you operate a commercial motor vehicle?";

        let { initialValues } = this.props;
        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD EMPLOYMENT DETAILS
                </ModalHeader>
                <ModalBody
                    style={{ padding: 30 }}
                >
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            const SEL_STATES =
                                values.country === "CA" ? CA_STATES : US_STATES;
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            {(
                                                <React.Fragment>

                                                    <Row style={{
                                                        marginTop: 10,
                                                        marginBottom: 5
                                                    }}>
                                                        <InputBox
                                                            md={"4"}
                                                            name={"companyName"}
                                                            label={"Company Name"}
                                                            placeholder={"Enter Company Name"}
                                                        />
                                                        <InputBox
                                                            md={"4"}
                                                            name={"companyPhone"}
                                                            label={"Company Phone"}
                                                            placeholder={"Enter Company Phone Number"}
                                                            type={"number"}
                                                            isPhone={true}
                                                        />
                                                        <InputDatePicker
                                                            md={"4"}
                                                            type={"date"}
                                                            name={"start"}
                                                            label={"Start"}
                                                        />
                                                    </Row>
                                                    <Row >
                                                        <InputDatePicker
                                                            md={"4"}
                                                            type={"date"}
                                                            name={"end"}
                                                            label={"End"}
                                                        />

                                                        <SelectBox
                                                            md={"4"}
                                                            name={"country"}
                                                            label={"Country"}
                                                            options={COUNTRIES}
                                                        />
                                                        <SelectBox
                                                            md={"4"}
                                                            name={"state"}
                                                            label={"State"}
                                                            options={SEL_STATES}
                                                        />
                                                    </Row>

                                                    <Row>
                                                        <InputBox
                                                            md={"9"}
                                                            name={"street"}
                                                            label={"Street address"}
                                                        />
                                                        <InputBox
                                                            name={"line2"}
                                                            label={"Suit"}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <InputBox
                                                            name={"city"}
                                                            md={"3"}
                                                            label={"City"}
                                                        />
                                                        <InputBox
                                                            md={"2"}
                                                            name={"postalCode"}
                                                            label={"Postal Code"}
                                                        />
                                                        <InputBox
                                                            md={"3"}
                                                            name={"position"}
                                                            label={"Position"}
                                                        />
                                                        <InputBox
                                                            md={"4"}
                                                            name={"reasonOfLeaving"}
                                                            label={"Reason of leaving"}
                                                        />
                                                    </Row>

                                                    <Row
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <InputCheckbox
                                                            marginTop={22}
                                                            md={"6"}
                                                            name={"isTerminated"}
                                                            label={terminatedText}
                                                            checked={values.isTerminated}
                                                        />

                                                        <InputCheckbox
                                                            md={"6"}
                                                            marginTop={22}
                                                            name={"isCurrentEmployer"}
                                                            label={currentEmployerText}
                                                            checked={values.isCurrentEmployer}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <InputCheckbox
                                                            md={"6"}
                                                            name={"isContactEmployer"}
                                                            marginTop={22}
                                                            label={contactEmployerText}
                                                            checked={values.isContactEmployer}
                                                        />
                                                        <InputCheckbox
                                                            marginTop={22}
                                                            md={"6"}
                                                            name={"iSIsOperate"}
                                                            label={isOperateText}
                                                            checked={values.iSIsOperate}
                                                        />
                                                    </Row>

                                                </React.Fragment>
                                            )}

                                            <Col className="text-center">
                                                <button
                                                    color="primary"
                                                    type="submit"
                                                    className="btn btn-primary btn-sm"
                                                    style={STYLES.BUTTON_NEXT}
                                                >
                                                    {"SAVE EMPLOYMENT"}
                                                </button>
                                                <div className="text-center">
                                                    <Button
                                                        color="link" onClick={this.props.toggle}>
                                                        Cancel
                                                </Button>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmploymentModal);
