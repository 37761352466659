import _get from "lodash/get";
import React from "react";
import { Field } from "formik";
import { Col, FormGroup, Label } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";

Moment.locale("en");
momentLocalizer();

const STYLES = {
  formRow: {
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 0,
    marginBottom: -10
  },
  labelStyles: {
    marginBottom: -10,
    fontSize: 14,
    color: "black"
  }
};

const InputField = ({
  field,
  label,
  labelClass,
  showTimeSelect,
  form: { touched, errors, values, setFieldValue, setTouched },
  disabled = false,
  minDate,
  maxDate,
  onChange,
  timeStep,
  showDate,
  ...props
}) => {
  const fieldName = field.name;

  if (props.value === "__") {
    setFieldValue(`${fieldName}`, null);
  }

  let error = _get(errors, `${fieldName}`);
  //  let touch = _get(touched, `${fieldName}`);
  let inputClassName = "osrit_date_widget";
  let inputStyles = { background: "none", width: "100%" };

  if (error) {
    inputStyles.color = "red";
    inputStyles.fontSize = "12px";
    inputStyles.weight = "100";
    inputClassName = `${inputClassName} error`;
  }

  const fieldValue = _get(values, `${field.name}`, null);
  const _date = fieldValue ? Moment(fieldValue) : null;
  const selValue = _date && _date.isValid() ? new Date(fieldValue) : null;

  let otherProps = {};

  if (minDate) {
    otherProps.min = new Date(minDate);
  }
  if (maxDate) {
    otherProps.max = new Date(maxDate);
  }

  if (showTimeSelect) {
    // otherProps.dateFormat = "Pp";
    otherProps.step = 15;
  }

  return (
    <FormGroup >
      <DateTimePicker
        name={fieldName}
        value={selValue}
        onChange={date => {
          // console.log("cahge", date)
          setFieldValue(`${fieldName}`, date);
          onChange(date);
        }}
        date={showDate}
        placeholder={label}
        time={showTimeSelect}
        containerClassName={inputClassName}
        onFocus={() => setTouched([fieldName])}
        {...otherProps}
      />
      {error && (
        <div
          style={{
            marginTop: "0.25rem",
            fontSize: "100%",
            color: "#f86c6b"
          }}
        >
          {error}
        </div>
      )}
    </FormGroup>
  );
};

const DateTimeWidget = ({
  name,
  label,
  type = "date",
  size = "sm",
  xs = "12",
  md = "3",
  min = "0",
  tooltip,
  labelClass = "",
  showTimeSelect = false,
  containerPadding = 5,
  minDate,
  maxDate,
  timeStep = 15,
  value,
  noLabel = false,
  timeOnly = false,
  onChange = () => { }
}) => {
  let colProps = {};

  if (md !== "auto") {
    colProps.md = md;
  }

  const showDate = !timeOnly;

  return (
    <Col xs={xs} md={md}
      {...colProps}
      style={{
        paddingRight: containerPadding,
        paddingLeft: containerPadding,
        marginTop: 4,
      }}
    >
      {!noLabel ? (
        <Label
          for={name}
          className={labelClass || "label-color"}
          style={STYLES.labelStyles}
        >
          {label}
          {tooltip && (
            <React.Fragment>
              <span href="#" id={`${name}-dp-tooltip`}>
                {" "}
                <i className="fa fa-info-circle text-info" />
              </span>
              <UncontrolledTooltip target={`${name}-dp-tooltip`}>
                {tooltip}
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </Label>
      ) : null}
      <Field
        type={type}
        bsSize={size}
        min={min}
        name={name}
        label={label}
        onChange={onChange}
        timeStep={timeStep}
        showDate={showDate}
        showTimeSelect={showTimeSelect}
        labelClass={labelClass}
        component={InputField}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        noLabel={noLabel}
      />
    </Col>
  );
};

export default DateTimeWidget;
