import axios from "axios";
import _get from "lodash/get";
import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Button, Modal, ModalBody } from "reactstrap";
import ReviewContent from "./ReviewContent";
import html2pdf from "html2pdf.js";
import STYLES from "../STYLES";
import { formatDate } from "../../../utils/momentUtil";
import { pdfOptions } from "../../../constants";
import {
    uploadDocuments,
    sendEmail
} from "../../../actions/registration/registrationActions";
import SubmitModal from "./SubmitModal";
import { updateRegistration } from "../../../actions/registration/registrationActions";

export class ReviewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: false,
            sending: false,
            isOpen: false,
            isSubmitOpen: false,
            success: false,
            error: false
        };
    }

    updateStatus = async () => {
        await this.props.updateRegistration({ status: "SUBMITTED" });
    };

    submitToggle = () => {
        this.setState({
            isSubmitOpen: !this.state.isSubmitOpen
        });
    };

    onDone = async () => {
        this.setState({ isOpen: false, sending: false });
    };

    download = async () => {
        this.setState({
            downloading: true
        });
        const { filename } = this.props;
        const element = document.getElementById("DriverApplication");
        pdfOptions.filename = filename;
        await html2pdf()
            .set(pdfOptions)
            .from(element)
            .toPdf()
            .save();
        this.setState({
            downloading: false,
            showHeader: false
        });
    };

    submit = async (signURL) => {
        this.setState({
            signURL: signURL,
            isSubmitOpen: false,
            creating: true,
            isSendingModal: true,
            error: false,
            success: false,
            showHeader: true
        });
        const { filename } = this.props;
        window.scrollTo(0, 0);
        const element = document.getElementById("DriverApplication");
        pdfOptions.filename = filename;
        window.scrollTo(0, 0);
        const imageData = await html2pdf()
            .set(pdfOptions)
            .from(element)
            .toPdf()
            .output("blob");
        await this.sendEmail(imageData);
        this.setState({ sending: false, creating: false });
    };

    getFileName = () => {
        const personal = _get(this, "props.registration.personal", {});
        const dlNo = _get(this, "props.registration.license.licenseNo", "");
        const firstName = personal.firstName ? personal.firstName : "";
        const lastName = personal.lastName ? personal.lastName : "";
        const dName = `${firstName}_${lastName}`;
        return `${dName}_DL_${dlNo}_${Date.now()}.pdf`;
    };

    sendEmail = async imageData => {
        const personal = _get(this, "props.registration.personal", {});
        const dlNo = _get(this, "props.registration.license.licenseNo", "");
        const dlSt = _get(this, "props.registration.license.licenseState", "");
        const registerId = _get(this, "props.registration.registerId", "");
        const firstName = personal.firstName ? personal.firstName : "";
        const lastName = personal.lastName ? personal.lastName : "";
        const dName = `${firstName} ${lastName}`;

        const fileFolder = "newDriverApplication";
        const busEmail = _get(this, "props.business.email");
        const businessId = _get(this, "props.business.id");
        const bucket = _get(this, "props.business.bucket");
        const fileName = this.getFileName();
        const subject = `New driver application for ${dName} CLD# ${dlNo}`;
        const downloadConfig = {
            fileName,
            bucket,
            fileFolder,
            businessId,
            notes: subject,
            registerId
        };
        const html = `<div><h3>Hi,</h3><h4>New driver application</h4><div>Driver:- ${dName}</div> <div>CLD:- ${dlNo} (State: ${dlSt})</div><h3>Thanks & Regards</h3><h3>Osrit Team.</h3></div>`;
        let emailPayload = {
            to: busEmail,
            cc: personal.email,
            from: "noreply@email.osrit.com",
            subject: subject,
            filename: fileName,
            html: html
        };

        try {
            const response = await this.props.uploadDocuments(downloadConfig);
            const putUrl = response.putUrl;
            const getUrl = response.getUrl;

            if (!getUrl || !putUrl || !putUrl.url || !getUrl.url) {
                this.setState({ error: true });
                return;
            }
            const responseS3 = await axios.put(putUrl.url, imageData);

            if (!responseS3 || responseS3.status !== 200) {
                this.setState({ error: true });
                return;
            }
            emailPayload.url = getUrl.url;
            this.setState({ sending: true, creating: false });
            const responseEmail = await this.props.sendEmail(emailPayload);
            console.log("sendEmail response ::: ", responseEmail);
            if (responseEmail.id) {
                console.log("responseEmail :::  ", responseEmail);
                this.updateStatus()
                this.setState({ success: true });
            } else {
                this.setState({ error: true });
            }
        } catch (e) {
            this.setState({ error: true });
            console.log("e :::: ", e);
        }
    };

    toggleProgressModal = () => {
        this.setState({
            isSendingModal: false,
            showHeader: false
        });
    };

    render() {
        const { registration, business, isSigned } = this.props;
        const { isSendingModal, sending, downloading, creating, showHeader, isSubmitOpen, signURL } = this.state;
        const { success, error } = this.state;
        const businessName = _get(this, "props.business.name", {});
        const businessEmail = _get(this, "props.business.email", {});
        const personal = _get(this, "props.registration.personal", {});
        const licNo = _get(this, "props.registration.license.licenseNo", "");
        const firstName = personal.firstName ? personal.firstName : "";
        const middleName = personal.middleName ? personal.middleName : "";
        const lastName = personal.lastName ? personal.lastName : "";
        const driverName = `${firstName} ${middleName} ${lastName}`;
        const canSubmit = (registration.personal.firstName ? true : false) && (registration.general.position ? true : false)
            && (registration.license.licenseNo ? true : false) && (registration.hos.confirmText ? true : false)
            && (registration.status === "IN_PROGRESS") && isSigned
        window.scrollTo(0, 0);
        return (
            <div>
                {isSubmitOpen && (
                    <SubmitModal
                        firstName={firstName}
                        middleName={middleName}
                        lastName={lastName}
                        licNo={licNo}
                        isOpen={this.state.isSubmitOpen}
                        toggle={this.submitToggle}
                        onClose={(signURL) => {
                            this.submit(signURL)
                        }}
                    />)}
                {isSendingModal && (
                    <Modal
                        isOpen={isSendingModal}
                        toggle={this.toggleProgressModal}
                    >
                        <ModalBody>
                            <div
                                className="text-center"
                                style={{ padding: "25px 5px" }}
                            >
                                {(creating || sending) && (
                                    <div
                                        className="text-center"
                                        style={{ margin: " 25px auto" }}
                                    >
                                        <i className="fa fa-spinner fa-pulse text-primary fa-3x fa-fw" />
                                    </div>
                                )}
                                {creating && (
                                    <h5>{`Submiting your driver application.`}</h5>
                                )}
                                {sending && (
                                    <h5>{`Sending email to ${driverName} and ${businessName}.`}</h5>
                                )}
                                {success && (
                                    <div>
                                        <i className="fa fa-check fa-3x text-success" />
                                        <h5
                                            style={{ marginTop: 25 }}
                                        >{`Application is submited sucessfully.`}</h5>
                                    </div>
                                )}
                                {error && (
                                    <div>
                                        <i className="fa fa-exclamation-triangle fa-3x text-danger" />
                                        <div
                                            style={{ marginTop: 25 }}
                                        >{`We are not able to submit your application.`}</div>
                                        <div>{`Please download application and send email to ${businessEmail}`}</div>
                                    </div>
                                )}
                                {creating || sending ? null : (
                                    <div style={{ marginTop: 25 }}>
                                        <div>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                onClick={this.download}
                                            >
                                                <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                />
                                                {" DOWNLOAD"}
                                            </Button>
                                            <div />
                                            <Button
                                                size="sm"
                                                color="link"
                                                onClick={
                                                    this.toggleProgressModal
                                                }
                                            >
                                                {"CANCEL"}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                    </Modal>
                )}

                <div
                    autoFocus={true}
                    id="DriverApplication">
                    <ReviewContent
                        status={showHeader}
                        business={business}
                        data={registration}
                    />

                    {(showHeader && signURL) && (
                        <div style={{
                            padding: 10,
                            textAlign: "center"
                        }}
                        >
                            <img src={signURL} alt=""></img>
                        </div>
                    )}
                    {showHeader && (
                        <div style={{
                            padding: 10,
                            textAlign: "center"
                        }}
                        >
                            Signed on {formatDate(Date())} by {driverName}
                        </div>
                    )}
                </div>
                {(!sending && !downloading && canSubmit) &&
                    (
                        <Col
                            xs="12"
                            style={{ padding: 20 }}
                            className="text-center"
                        >
                            <Button
                                style={STYLES.BUTTON_NEXT}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.submitToggle();
                                }}
                                autoFocus={false}
                            >
                                {"Submit Application"}
                            </Button>
                        </Col>
                    )
                }
            </div>
        );
    }
}

ReviewContainer.defaultProps = {
    filename: "DriverApplication.pdf"
};

const mapStateToProps = state => {
    return {
        registration: _get(state, "registrationData", {})
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration,
    uploadDocuments, sendEmail
}, dispatch);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)
        (ReviewContainer));