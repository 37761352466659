export function getTerms(props) {
    const firstName = props.firstName || "";
    const middleName = props.middleName || "";
    const lastName = props.lastName || "";
    const licNo = props.licNo || "";

    const mainLine = `I, ${firstName} ${middleName} ${lastName} with CLD# ${licNo},`;

    const term1 = `${mainLine} certify that all ablove information is correct and agree with all terms and conditions to submit my driver application electronically.`;
    const term2 = `${mainLine} acknowledge that I have read and understand the Pre-Employment Screening Program Disclosure and Authorization and also have been given the opportunity to copy / print it, and(b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`;
    const term3 = `${mainLine} acknowledge that I have read and understand the above and also have been given the opportunity to copy / print it, and(b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`;
    const term4 = `${mainLine} acknowledge that I have read and understand the Summary of Rights Under 15 U.S.C.Section 1681m(a) and have been given the opportunity to copy / print the 1681m Summary of Rights and(b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`;
    const term5 = `${mainLine} acknowledge that I have read and understand the federal FCRA Summary of Rights and have been given the opportunity to copy / print the Summary of Rights and(b) agree to use an electronic signature to demonstrate my consent. An electronic signature is as legally binding as an ink signature.`;

    const terms = {
        one: {
            header: "Certify all ablove information is correct.",
            accept: term1,
            reject: "I do not agree to use an electronic signature to demonstrate my consent."

        },
        two: {
            header: "Pre-Employment Screening Program Disclosure",
            accept: term2,
            reject:
                "I do not agree to use an electronic signature to demonstrate my consent."
        },
        three: {
            header: "Opportunity to copy / print Terms",
            accept: term3,
            reject:
                "I do not agree to use an electronic signature to demonstrate my consent."
        },
        four: {
            header: "Summary of Rights Under 15 U.S.C.Section",
            accept: term4,
            reject:
                "I do not agree to use an electronic signature to demonstrate my consent."
        },
        five: {
            header: "Federal FCRA Summary of Rights",
            accept: term5,
            reject:
                "I do not agree to use an electronic signature to demonstrate my consent."
        }
    };
    return terms;
}
