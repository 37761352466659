import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { updateRegistration } from "../../../actions/registration/registrationActions";
import UnemploymentModal from "./modals/UnemploymentModal/UnemploymentModal";
import UnemploymentItem from "./items/UnemploymentItem";
import ConfirmationModal from "../../../modals/ConfirmationModal"

class UnemployementContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isConfirmOpen: false,
            errorMsg: "",
            successMsg: ""
        };
    }
    onModalClose = unemployment => {
        let unemployments = _get(this, "props.unemployment", []);
        unemployments.push(unemployment);
        this.props.updateRegistration({ unemployment: unemployments });
        this.toggle();
    };
    onItemDelete = ({ item }) => {
        let unemployments = _get(this, "props.unemployment", []);
        const items = unemployments.filter(i => i !== item);
        this.props.updateRegistration({ unemployment: items });
        this.toggleConfirm({ item: null })
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    toggleConfirm = ({ item }) => {
        this.setState({
            isConfirmOpen: !this.state.isConfirmOpen,
            item: item
        });
    };

    render() {
        const { unemployment } = this.props;

        return (
            <div>
                <div>
                    <Row style={{
                        marginTop: 8
                    }}>
                        <Col style={{ fontSize: 10, marginTop: 2 }}>
                            <h5
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                {"Add Unemployment Details :"}
                            </h5>
                        </Col>
                        <Col sm="4" style={{ textAlign: "right" }}
                        >
                            <div
                            >
                                {unemployment.length < 10 ?
                                    <button
                                        style={{

                                            width: 100,
                                            borderWidth: 0.5
                                        }}
                                        color="primary"
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                        onClick={this.toggle}
                                    >Add Details</button>
                                    : <span style={{
                                        fontSize: 10,
                                        color: "red"
                                    }}>
                                        Can't add more then 10
                                                        </span>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>

                <UnemploymentModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    onClose={this.onModalClose}
                />
                < ConfirmationModal
                    message={"Are you sure to delete this unemployment?"}
                    isOpen={this.state.isConfirmOpen}
                    toggle={() => this.toggleConfirm({ item: null })}
                    onConfirm={() => this.onItemDelete({ item: this.state.item })}
                />

                {Array.isArray(unemployment) &&
                    unemployment.map((item, i) => (
                        <UnemploymentItem
                            onDeleteClick={() => this.toggleConfirm({ item: item })}
                            item={item}
                        />
                    ))}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        unemployment: _get(state, "registrationData.unemployment", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ updateRegistration }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UnemployementContent)
);
