import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { validate } from "./formHelpers";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from "../../../actions/registration/registrationActions";

class HosForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: ""
        };
    }

    componentDidMount() { }

    onSubmit = async values => {
        await this.props.updateRegistration({ hos: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/fmcsr`;
        this.props.history.push(nextUrl);
    };

    render() {
        let { initialValues, isEdit = false } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/mvr`;
        const confirmText =
            "I confirm, I will follow the HOS Regulations while driving a commercial motor vehicle, or CMV.";

        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <InputCheckbox
                                        name={"confirmText"}
                                        label={confirmText}
                                        checked={values.confirmText}
                                        marginTop={isEdit ? "25" : "20"}
                                    />
                                </Row>
                                <Row style={STYLES.BUTTON_WRAPPER}>
                                    <Col xs="6">
                                        <Link
                                            to={backUrl}
                                            className="btn btn-warning btn-sm"
                                            style={STYLES.BUTTON_BACK}
                                        >
                                            BACK (MVR)
                                        </Link>
                                    </Col>
                                    <Col xs="6">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            style={STYLES.BUTTON_NEXT}
                                            type="submit"
                                        >
                                            NEXT (FMCSR)
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, "registrationData.hos", {}),
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration
}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HosForm)
);
