import React from 'react';
import { Row, Col } from 'reactstrap';
import ContactForm from './HosForm';

export class HosContainer extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col
            xs='12'
            style={{
              margin: '10px auto',
              fontSize: 12,
              fontWeight: 200,
            }}
          >
            <h5 className='text-center' style={{ margin: '15px auto' }}>{"HOS Information"}</h5>
          </Col>

          <Col md='12'>
            <ContactForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default HosContainer;
