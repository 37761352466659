export function validate(values) {
    let errors = {};

    // if (!values.confirmOne) {
    //     errors.confirmOne = "Required.";
    // }
    // if (!values.confirmTwo) {
    //     errors.confirmTwo = "Required.";
    // }
    // if (!values.confirmThree) {
    //     errors.confirmThree = "Required.";
    // }
    // if (!values.confirmFour) {
    //     errors.confirmFour = "Required.";
    // }
    // if (!values.confirmFive) {
    //     errors.confirmFive = "Required.";
    // }

    return errors;
}
