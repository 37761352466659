import React from 'react';
import { Row, Col } from 'reactstrap';
import MoreDetailsForm from './MoreDetailsForm';

export class MoreDetailsContainer extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col
            xs='12'
            style={{
              margin: '10px auto',
              fontSize: 12,
              fontWeight: 200,
            }}
          >
            <h5 className='text-center' style={{ margin: '15px auto' }}>
              More Information
            </h5>
          </Col>

          <Col md='12'>
            <MoreDetailsForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default MoreDetailsContainer;
