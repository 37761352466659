import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
// import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import InputRadiaButton from "../../../components/InputRadiaButton/InputRadiaButton";
import { validate, defaultValues } from "./formHelpers";
import { MILITARY_BRANCHED, RADIOBTN_VALS } from "../../../utils/constants";
import STYLES from "../STYLES";
import { DRIVER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from "../../../actions/registration/registrationActions";

const BTN = {
    design: {
        color: "#dc3545",
        fontSize: "12px%"
    }
};

class MilitaryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: "",
            successMsg: ""
        };
    }

    componentDidMount() { }

    onSubmit = async values => {
        await this.props.updateRegistration({ military: values });
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${DRIVER_BASE_URL}/${businessKey}/mvr`;
        const { isEdit, onClose } = this.props
        if (isEdit) {
            onClose()
        } else {
            this.props.history.push(nextUrl);
        }
    };

    render() {
        let { initialValues, isEdit, onClose } = this.props;
        const businessKey = _get(this, "props.match.params.businessKey");
        const backUrl = `${DRIVER_BASE_URL}/${businessKey}/history`;
        const militaryTxt = "Were you ever in the U.S. military?";
        const dd214Txt = "Can you obtain your DD214?";

        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={validate}
                    onSubmit={this.onSubmit}
                >
                    {({ handleSubmit, values, errors }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="12">
                                        <InputRadiaButton
                                            md={"10"}
                                            mdInput={"2"}
                                            name={"isMilitary"}
                                            label={militaryTxt}
                                            options={RADIOBTN_VALS}
                                        />
                                        {<p style={BTN.design}>{errors.isMilitary}</p>}

                                        {/* <InputCheckbox
                                        name={"isMilitary"}
                                        label={militaryTxt}
                                        checked={values.isMilitary}
                                        marginTop={isEdit ? "25" : "20"}
                                    /> */}
                                        {values.isMilitary ? (
                                            // <InputCheckbox
                                            //     name={"isDd214"}
                                            //     label={dd214Txt}
                                            //     checked={values.isDd214}
                                            // />
                                            <>     <InputRadiaButton
                                                md={"10"}
                                                mdInput={"2"}
                                                name={"isDd214"}
                                                label={dd214Txt}
                                                options={RADIOBTN_VALS}
                                            />
                                                {<p style={BTN.design}>{errors.isDd214}</p>}</>

                                        ) : null
                                        }

                                    </Col>
                                    <Col md="12">

                                        {values.isMilitary ? (

                                            <React.Fragment>
                                                <Row>
                                                    <InputDatePicker
                                                        type={"date"}
                                                        name={"start"}
                                                        label={"Military Start"}
                                                    />
                                                    <InputDatePicker
                                                        type={"date"}
                                                        name={"end"}
                                                        label={"Military End"}
                                                    />


                                                    <SelectBox
                                                        name={"branch"}
                                                        label={"Branch of Service"}
                                                        options={MILITARY_BRANCHED}
                                                    />
                                                    <InputBox
                                                        name={"rank"}
                                                        label={"Rank at discharge"}
                                                    /></Row>
                                            </React.Fragment>
                                        ) : null}
                                    </Col>
                                </Row>
                                {isEdit ?
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <button
                                                onClick={onClose}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                CANCEL
                                        </button>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                SAVE
                                        </button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row style={STYLES.BUTTON_WRAPPER}>
                                        <Col xs="6">
                                            <Link
                                                to={backUrl}
                                                className="btn btn-warning btn-sm"
                                                style={STYLES.BUTTON_BACK}
                                            >
                                                BACK (History)
                                        </Link>
                                        </Col>
                                        <Col xs="6">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                                type="submit"
                                            >
                                                NEXT (MVR)
                                        </button>
                                        </Col>
                                    </Row>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const initialValues = _get(state, "registrationData.military", {});
    return {
        initialValues: {
            ...defaultValues,
            ...initialValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateRegistration
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilitaryForm));
