import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { updateRegistration } from "../../../actions/registration/registrationActions";
import EmploymentModal from "./modals/EmploymentModal/EmploymentModal";
import EmploymentItem from "./items/EmploymentItem";
import ConfirmationModal from "../../../modals/ConfirmationModal"
class EmployementContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isConfirmOpen: false,
            errorMsg: "",
            successMsg: ""
        };
    }
    onModalClose = employment => {
        let employments = _get(this, "props.employment", []);
        employments.push(employment);
        this.props.updateRegistration({ employment: employments });
        this.toggle();
    };
    onItemDelete = ({ item }) => {
        let employments = _get(this, "props.employment", []);
        const items = employments.filter(i => i !== item);
        this.props.updateRegistration({ employment: items });
        this.toggleConfirm({ item: null })
    };

    toggleConfirm = ({ item }) => {
        this.setState({
            isConfirmOpen: !this.state.isConfirmOpen,
            item: item
        });
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        const { employment } = this.props;

        return (
            <div>
                <div>
                    <Row
                        style={{
                            marginTop: 8
                        }}
                    >
                        <Col style={{ fontSize: 10, marginTop: 2 }}>
                            <h5
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                {"Add Employment Details :"}
                            </h5>
                        </Col>
                        <Col sm="4" style={{ textAlign: "right" }}
                        >
                            <div
                            >
                                {employment.length < 10 ?
                                    <button
                                        style={{
                                            width: 100,
                                            borderWidth: 0.5
                                        }}
                                        color="primary"
                                        type="submit"
                                        className="btn btn-primary btn-sm"
                                        onClick={this.toggle}
                                    >Add Details</button>
                                    : <span style={{
                                        fontSize: 10,
                                        color: "red"
                                    }}>
                                        Can't add more then 10
                                                        </span>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                < ConfirmationModal
                    message={"Are you sure to delete this employment?"}
                    isOpen={this.state.isConfirmOpen}
                    toggle={() => this.toggleConfirm({ item: null })}
                    onConfirm={() => this.onItemDelete({ item: this.state.item })}
                />
                <EmploymentModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    onClose={this.onModalClose}
                />
                {employment.map((item, i) => (
                    <EmploymentItem
                        onDeleteClick={() => this.toggleConfirm({ item: item })}
                        item={item}
                    />
                ))}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        employment: _get(state, "registrationData.employment", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ updateRegistration }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EmployementContent)
);
