import { isUnvalidDate } from "../../../utils/momentUtil";

export const defaultValues = {
    isMilitary: false,
    isDd214: false,
    start: "",
    end: "",
    branch: "",
    rank: ""
}
export function validate(values) {
    let errors = {};
    if (values.isMilitary && isUnvalidDate(values.start, values.end)) {
        errors.end = "Select valid date"
    }

    if (values.isMilitary === null || values.isMilitary === undefined) {
        errors.isMilitary = "Required"
    }
    if (values.isMilitary === "true " && !values.isDd214) {
        errors.isDd214 = "Required"
    }
    if (values.isMilitary && !values.start) {
        errors.start = "Required"
    }
    if (values.isMilitary && !values.end) {
        errors.end = "Required"
    }
    if (values.isMilitary && !values.branch) {
        errors.branch = "Required"
    }
    if (values.isMilitary && !values.rank) {
        errors.rank = "Required"
    }

    return errors;
}
