import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from './Header';
import _get from 'lodash/get';
import { getBusiness } from './actions/app/appAction';
import GoogleSignIn from './GoogleSignIn';
import GoogleSignOut from './GoogleSignIn/SignOut';
import { CARRIER_BASE_URL } from './utils/constants';
import DisclaimerContainer from './containers/Carrier/DisclaimerContainer';
import RequirementContainer from './containers/Carrier/RequirementContainer';
import GeneralContainer from './containers/Carrier/GeneralContainer';
import ContactContainer from './containers/Carrier/ContactContainer';
import DriverEqipContainer from './containers/Carrier/DriverEqipContainer';
import HosContainer from './containers/Carrier/HosContainer';
import MoreDetailContainer from './containers/Carrier/MoreDetailContainer';
import FileuploadContainer from './containers/Carrier/DocumentsContainer';
import ReviewContainer from './containers/Carrier/ReviewContainer';

import './App.css';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isReview: false,
    };
  }

  hideHeader = () => {
    this.setState({
      isReview: true,
    });
  };
  showHeader = () => {
    this.setState({
      isReview: false,
    });
  };

  async componentDidMount() {
    const businessKey = _get(this, 'props.match.params.businessKey');
    await this.props.getBusiness('', businessKey);
  }

  toggleSignIn = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isSignInOpen } = this.props;
    const wrapperStyles = {
      padding: '10px 25px',
      width: '100%',
      maxWidth: 700,
      margin: '10px auto',
      backgroundColor: 'white',
      minHeight: 400,
      border: ' 1px solid #e4e7ea',
      borderRadius: 10,
      display: 'block',
    };
    const header = (
      <div>
        {isSignInOpen ? null : <GoogleSignOut />}
        <Header business={this.props.business} />
        {isSignInOpen ? <GoogleSignIn /> : null}
      </div>
    );
    return (
      <div className='app'>
        <div className='' style={wrapperStyles}>
          {header}
          <div id='contentWrapper'>
            <Switch>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/disclaimer`}>
                <DisclaimerContainer
                  isSigned={!isSignInOpen}
                  onClick={() => { }}
                />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/requirement`}>
                <RequirementContainer isSigned={!isSignInOpen} onClick={() => { }} />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/general`}>
                <GeneralContainer isSigned={!isSignInOpen} onClick={() => { }} />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/contacts`}>
                <ContactContainer isSigned={!isSignInOpen} onClick={() => { }} />
              </Route>
              <Route
                path={`${CARRIER_BASE_URL}/:businessKey/driversAndEqipments`}
              >
                <DriverEqipContainer
                  isSigned={!isSignInOpen}
                  onClick={() => { }}
                />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/hos`}>
                <HosContainer isSigned={!isSignInOpen} onClick={() => { }} />
              </Route>

              <Route path={`${CARRIER_BASE_URL}/:businessKey/moreDetails`}>
                <MoreDetailContainer
                  isSigned={!isSignInOpen}
                  onClick={() => { }}
                />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/documents`}>
                <FileuploadContainer
                  isSigned={!isSignInOpen}
                  onClick={() => { }}
                />
              </Route>
              <Route path={`${CARRIER_BASE_URL}/:businessKey/review`}>
                <ReviewContainer isSigned={!isSignInOpen} onClick={() => { }} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: _get(state, 'appData.business', {}),
    userEmail: _get(state, 'appData.googleUser.email', ''),
    isSignInOpen: _get(state, 'appData.isSignInOpen', true),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBusiness,
      // getRecruiting
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
