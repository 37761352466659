import React from "react";
import { Row, Col } from "reactstrap";
import ContentContainer from "./GeneralContainer";
import ProgressBar from "../../../components/ProgressBar";

export class GeneralContainer extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ProgressBar percentage={25} />
                    </Col>

                    <Col xs="12">
                        <ContentContainer {...this.props} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default GeneralContainer;
