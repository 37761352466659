import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import STYLES from '../STYLES';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from '../../../actions/carrier/carrierActions';
import ContactModal from './ContactModal';
import ContactView from './ContactView';
import ConfirmationModal from '../../../modals/ConfirmationModal';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMsg: '',
      successMsg: '',
      isOpen: false,
      isConfirmOpen: false,
    };
  }

  onModalClose = (data) => {
    const value = _get(this, 'props.contacts', []);
    value.push(data);
    this.props.updateRegistration({ contacts: value });
    this.toggle();
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  onItemDelete = ({ item }) => {
    const value = _get(this, 'props.contacts', []);
    const items = value.filter((i) => i !== item);
    this.props.updateRegistration({ contacts: items });
    this.toggleConfirm({ item: null });
  };

  toggleConfirm = ({ item }) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      item: item,
    });
  };

  render() {
    const businessKey = _get(this, 'props.match.params.businessKey');
    const backUrl = `${CARRIER_BASE_URL}/${businessKey}/general`;
    const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/driversAndEqipments`;
    const { contacts, isEdit, onClose } = this.props;
    const { isConfirmOpen, isOpen } = this.state;

    return (
      <div>
        {isConfirmOpen && (
          <ConfirmationModal
            message={'Are you sure to delete this contact?'}
            isOpen={isConfirmOpen}
            toggle={() => this.toggleConfirm({ item: null })}
            onConfirm={() =>
              this.onItemDelete({
                item: this.state.item,
              })
            }
          />
        )}

        {isOpen && (
          <ContactModal
            isOpen={isOpen}
            toggle={this.toggle}
            onClose={this.onModalClose}
          />
        )}

        <Row>
          <Col md={12}>
            <Row
              style={{
                marginTop: 12,
              }}
            >
              <Col md={8} style={{ fontSize: 10, marginTop: 2 }}>
                <h5
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Contacts
                </h5>
              </Col>
              <Col md={4} style={{ textAlign: 'right' }}>
                <div>
                  {contacts.length < 10 ? (
                    <Button
                      style={{
                        width: 100,
                        borderWidth: 0.5,
                      }}
                      color='primary'
                      className='btn btn-primary btn-sm'
                      onClick={this.toggle}
                    >
                      Add
                    </Button>
                  ) : (
                      <span
                        style={{
                          fontSize: 10,
                          color: 'red',
                        }}
                      >
                        Can't add more then 10
                    </span>
                    )}
                </div>
              </Col>
            </Row>

            {contacts.map((item, i) => {
              return (
                <ContactView
                  md={12}
                  contactinfo={item}
                  onClick={() => this.toggleConfirm({ item: item })}
                />
              );
            })}
          </Col>
        </Row>
        {isEdit ?
          <Row style={STYLES.BUTTON_WRAPPER}>
            <Col xs="6">
              <button
                onClick={onClose}
                className="btn btn-warning btn-sm"
                style={STYLES.BUTTON_BACK}
              >
                CANCEL
                                        </button>
            </Col>
            <Col xs="6">
              <button
                className="btn btn-primary btn-sm"
                style={STYLES.BUTTON_NEXT}
                onClick={onClose}
              >
                SAVE
                                        </button>
            </Col>
          </Row>
          :
          <Row style={STYLES.BUTTON_WRAPPER}>
            <Col xs='6'>
              <Link
                to={backUrl}
                className='btn btn-warning btn-sm'
                style={STYLES.BUTTON_BACK}
              >
                BACK (GENERAL)
            </Link>
            </Col>
            <Col xs='6'>
              <Link
                to={nextUrl}
                className='btn btn-warning btn-sm'
                style={STYLES.BUTTON_NEXT}
              >
                NEXT (DRIVERS & EQUIPMENTS)
            </Link>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: _get(state, 'appData.business', {}),
    contacts: _get(state, 'carrierData.contacts', []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateRegistration }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactForm)
);
