export const defaultValues = {
    recruiter: false,
    recruiterName: "",
    teamPosition: false,
    teamName: "",
    twicCard: false,
    fmcsr: false,
    otherName: false,
    driversOtherName: "",
    eligible: false,
    startDate: "",
    hearAboutUs: "",
    position: "",
    drivingAwards: "",
    customerServiceAwards: ""
};

export function validate(values) {
    let errors = {};

    if (values.recruiter === null || values.recruiter === undefined) {
        errors.recruiter = "Required."
    }
    if (values.recruiter && !values.recruiterName) {
        errors.recruiterName = "Required."
    }
    if (values.teamPosition === null || values.teamPosition === undefined) {
        errors.teamPosition = "Required."
    }
    if (values.teamPosition && !values.teamName) {
        errors.teamName = "Required."
    }
    if (values.twicCard === null || values.twicCard === undefined) {
        errors.twicCard = "Required."
    }
    if (values.fmcsr === null || values.fmcsr === undefined) {
        errors.fmcsr = "Required."
    }
    if (values.otherName === null || values.otherName === undefined) {
        errors.otherName = "Required."
    }
    if (values.otherName && !values.driversOtherName) {
        errors.driversOtherName = "Required."
    }
    if (values.eligible === null || values.eligible === undefined) {
        errors.eligible = "Required."
    }
    if (!values.startDate) {
        errors.startDate = "Required.";
    }
    if (!values.hearAboutUs) {
        errors.hearAboutUs = "Required.";
    }

    if (!values.position) {
        errors.position = "Required.";
    }

    // if (!values.drivingAwards) {
    //     errors.drivingAwards = "Required.";
    // }
    // if (!values.customerServiceAwards) {
    //     errors.customerServiceAwards = "Required.";
    // }
    return errors;
}
