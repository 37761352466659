export function formateDOB(driverdob) {
    var res = driverdob.slice(0, 10);
    return res;
}

export function formatSSN(ssn) {
    ssn = "" + ssn;
    ssn = ssn.replace(/[^\d]/g, "");
    if (ssn.length === 9) {
        return "" + ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
    }
    else {
        return ssn;
    }
}

export function isValidEmail(email) {
    if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        return true;
    } else {
        return false;
    }
}
export function formatPhoneNumber(phone) {
    phone = "" + phone;
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length === 10) {
        return "+1" + phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
    }
    else if (phone.length >= 6) {
        return "+1 " + phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    else if (phone.length >= 3) {
        return "+1 " + phone.replace(/(\d{3})(\d{3})/, "($1) $2");
    }
    else if (phone.length <= 3) {
        return "+1 " + phone.replace(/(\d{3})/, "($1)");
    }
    else
        return null;
}

