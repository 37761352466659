import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import { COUNTRIES, US_STATES, CA_STATES } from "../../../utils/constants";
import STYLES from "../STYLES";
import { isUnvalidDate } from "../../../utils/momentUtil";

// const defaultValues = {
//     street: "1077 S Dana Ct",
//     line2: "",
//     city: "Mountain House",
//     state: "CA",
//     country: "US",
//     postalCode: "95391",
//     residenceSince: new Date()
// };

const defaultValues = {
    street: "",
    line2: "",
    city: "",
    state: "",
    country: "US",
    postalCode: "",
    residenceFrom: "",
    residenceTo: ""
};

function validate(values) {
    let errors = {};

    if (!values.street) {
        errors.street = "Required.";
    }
    if (!values.country) {
        errors.country = "Required.";
    }
    if (!values.city) {
        errors.city = "Required.";
    }
    if (!values.state) {
        errors.state = "Required.";
    }
    if (!values.postalCode) {
        errors.postalCode = "Required.";
    }
    if (isUnvalidDate(values.residenceFrom, values.residenceTo)) {
        errors.residenceTo = "Select valid date"
    }
    if (!values.residenceFrom) {
        errors.residenceFrom = "Required.";
    }
    if (!values.residenceTo) {
        errors.residenceTo = "Required.";
    }

    return errors;
}

class AddAddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        let { initialValues } = this.props;
        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD ADDRESS
                </ModalHeader>
                <ModalBody style={{ padding: 30 }}>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            const SEL_STATES =
                                values.country === "CA" ? CA_STATES : US_STATES;
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <SelectBox
                                            name={"country"}
                                            label={"Country"}
                                            options={COUNTRIES}
                                        />
                                        <SelectBox
                                            name={"state"}
                                            label={"State"}
                                            options={SEL_STATES}
                                        />
                                        <InputBox
                                            name={"city"}
                                            label={"City"}
                                            placeholder={"Enter City"}
                                        />

                                        <InputBox
                                            md={"3"}
                                            name={"street"}
                                            label={"Street address"}
                                            placeholder={"Enter Street Address"}
                                        />
                                        <InputBox
                                            name={"line2"}
                                            label={"Apt"}
                                            placeholder={"Enter Apartment"}
                                        />
                                        <InputBox
                                            name={"postalCode"}
                                            label={"Postal Code"}
                                            placeholder={"Enter Postal Code"}
                                        />
                                        <InputDatePicker
                                            type={"date"}
                                            name={"residenceFrom"}
                                            label={"Residence from"}
                                        />
                                        <InputDatePicker
                                            type={"date"}
                                            name={"residenceTo"}
                                            label={"Residence to"}
                                        />
                                        <Col style={{
                                            marginTop: 10,
                                            marginBottom: 5
                                        }}
                                            className="text-center">
                                            <button
                                                color="primary"
                                                type="submit"
                                                className="btn btn-primary btn-sm"
                                                style={STYLES.BUTTON_NEXT}
                                            >
                                                {"SAVE ADDRESS"}
                                            </button>
                                            <div className="text-center">
                                                <Button
                                                    color="link" onClick={this.props.toggle}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAddressModal);
