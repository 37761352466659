import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button, Alert, Spinner } from 'reactstrap';
import InputBox from '../../../components/InputBox/InputBox';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { validate, defaultValues } from './formHelpers';
import {
  CARRIER_TYPE,
  BUSINESS_TYPES,
  COUNTRIES,
  US_STATES,
  CA_STATES,
} from '../../../utils/constants';
import STYLES from '../STYLES';
import { CARRIER_BASE_URL } from '../../../utils/constants';
import { updateRegistration } from '../../../actions/carrier/carrierActions';
import { searchSafer } from "../../../actions/search/searchActions";
import { formatPhoneNumber } from '../../../utils/Formator';

class ContentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMsg: '',
      successMsg: '',
      isChecked: true,
      filteredCarrier: [],
    };
  }

  onSubmit = async (values) => {
    await this.props.updateRegistration({
      general: values,
      physicalAddress: values.physicalAddress,
      mailingAddress: values.mailingAddress,
    });
    const businessKey = _get(this, 'props.match.params.businessKey');
    const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/contacts`;
    const { isEdit, onClose } = this.props
    if (isEdit) {
      onClose()
    } else {
      this.props.history.push(nextUrl);
    }

  };
  setSearchData = () => {
    const { searchResult } = this.state;
    this.setState({ searchCarrier: searchResult, });
  };

  onCarrierSearch = async e => {
    const isEdit = !!_get(this, "props.carrier.id");
    if (isEdit) { return true; }
    try {
      const params = {
        dot: e.target.value
      };

      this.setState({ searching: true, searchResult: null });
      const response = await this.props.searchSafer(params);
      const company = response.company || {};
      const mailingAddress = company.mailingAddress || {};
      const physicalAddress = company.physicalAddress || {};
      mailingAddress.country = "US"
      physicalAddress.country = "US"
      mailingAddress.line1 = mailingAddress.street
      physicalAddress.line1 = physicalAddress.street
      if (company.name) {
        const phoneNo = company.phone ? formatPhoneNumber(company.phone) : "";
        const searchResult = {
          name: company.name || "",
          mailingAddress: mailingAddress,
          physicalAddress: physicalAddress,
          firstname: "Dispatch",
          lastname: "Department",
          contactemail: company.email || "",
          phone: phoneNo,
          email: company.email || "",
          fax: company.fax || "",
          dot: company.uSDOTNumber || "",
          mc: company.uSDOTNumber,
          ...physicalAddress
        };
        this.setState({ searchResult, lookUp: false, searching: false });
      } else {
        this.setState({ lookUp: true, searchResult: null, searching: false });
      }
    } catch (e) {
      this.setState({ searching: false });
      console.log(e);
    }
  };

  render() {
    let {
      initialValues = {},
      mailingAddress = {},
      physicalAddress,
      isEdit, onClose
    } = this.props;
    const businessKey = _get(this, 'props.match.params.businessKey');
    const backUrl = `${CARRIER_BASE_URL}/${businessKey}/requirement`;
    initialValues.mailingAddress = mailingAddress;
    initialValues.physicalAddress = physicalAddress;

    const { searchResult, searchCarrier } = this.state;

    if (searchCarrier && searchCarrier.name) {
      initialValues = { ...initialValues, ...searchCarrier };
    }

    return (
      <div>
        {searchResult && (
          <Alert color="primary">
            <h5 className="text-center">
              <strong>FOUND ON SAFER.GOV (FMSCSA) </strong>
            </h5>
            <Row>
              <Col md={8}>
                <div>
                  <strong>{searchResult.name}</strong>
                  <div>DOT- {searchResult.dot}</div>
                  {searchResult.phone && <div>Phone- {searchResult.phone}</div>}
                  {searchResult.email && <div>Email- {searchResult.email}</div>}
                  <div>
                    Address:- {searchResult.line1} {searchResult.city}{" "}
                    {searchResult.state}-{searchResult.zip}
                  </div>
                </div>
              </Col>
              <Col md={4} className="text-center">
                <Button
                  color={"primary"}
                  size={"sm"}
                  onClick={this.setSearchData}
                  style={{ marginTop: 25 }}
                >
                  {"USE THIS CARRIER"}
                </Button>
              </Col>
            </Row>
          </Alert>
        )}

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            const SEL_STATES_1 =
              values.mailingAddress.countryMA === 'CA' ? CA_STATES : US_STATES;
            const SEL_STATES_2 =
              values.physicalAddress === 'CA' ? CA_STATES : US_STATES;

            return (
              <Form onSubmit={handleSubmit}>
                {this.state.searching && (
                  <Col md={12}>
                    <Alert color="primary" className="text-center">
                      <Spinner color="primary" />
                      <div>
                        Searching carrier information on
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://safer.fmcsa.dot.gov/CompanySnapshot.aspx`}
                        >
                          {" https://safer.fmcsa.dot.gov "}
                        </a>
                        .
                      </div>
                    </Alert>
                  </Col>
                )}

                <Row>
                  <Col>
                    <Row>
                      <InputBox
                        md={4}
                        name={'dot'}
                        label={'DOT #'}
                        type="number"
                        onBlur={this.onCarrierSearch}
                      />
                      <InputBox md={4} name={"mc"} label={'MC #'} type="number" />
                      <InputBox md={4} name={"ein"} label={'EIN #'} type="number" />
                      <InputBox md={4} name={'name'} label={'Carrier Name'} />
                      <SelectBox md={4} name={'businessType'} label={'Busines Type'} options={BUSINESS_TYPES} />
                      <SelectBox
                        md={4}
                        name={'carrierType'}
                        label={'Carrier Type'}
                        options={CARRIER_TYPE}
                      />

                      <InputBox md={3} name={'email'} label={'Email'} />
                      <InputBox md={3} name={'phone'} label={'Phone'} />
                      <InputBox md={3} name={'fax'} label={'Fax'} />
                      <InputBox md={3} name={'website'} label={'Website'} />
                    </Row>
                    <h6>Mailing Address</h6>
                    <Row>
                      <SelectBox
                        options={COUNTRIES}
                        md={4}
                        name={'mailingAddress.country'}
                        label={'Country'}
                      />
                      <InputBox
                        md={4}
                        name={'mailingAddress.line1'}
                        label={'Address Line 1'}
                      />
                      <InputBox
                        md={4}
                        name={'mailingAddress.line2'}
                        label={'Address Line 2'}
                      />
                      <InputBox
                        md={4}
                        name={'mailingAddress.city'}
                        label={'City'}
                      />
                      <SelectBox
                        options={SEL_STATES_2}
                        md={4}
                        name={'mailingAddress.state'}
                        label={'State'}
                      />
                      <InputBox
                        md={4}
                        name={'mailingAddress.zip'}
                        label={'Zip Code'}
                      />
                    </Row>
                    <h6>Physical Address</h6>
                    <input
                      onClick={(e) => {
                        this.setState({
                          isChecked: !this.state.isChecked,
                        });
                        if (this.state.isChecked) {
                          setFieldValue(
                            'physicalAddress',
                            values.mailingAddress
                          );
                        }
                      }}
                      type='checkbox'
                    />{' '}
                    Same as Mailing Address
                    <Row>
                      <SelectBox
                        options={COUNTRIES}
                        md={4}
                        name={'physicalAddress.country'}
                        label={'Country'}
                      />
                      <InputBox
                        md={4}
                        name={'physicalAddress.line1'}
                        label={'Address Line 1'}
                      />
                      <InputBox
                        md={4}
                        name={'physicalAddress.line2'}
                        label={'Address Line 2'}
                      />
                      <InputBox
                        md={4}
                        name={'physicalAddress.city'}
                        label={'City'}
                      />
                      <SelectBox
                        options={SEL_STATES_1}
                        md={4}
                        name={'physicalAddress.state'}
                        label={'State'}
                      />
                      <InputBox
                        md={4}
                        name={'physicalAddress.zip'}
                        label={'Zip Code'}
                      />
                    </Row>
                  </Col>
                </Row>
                {isEdit ?
                  <Row style={STYLES.BUTTON_WRAPPER}>
                    <Col xs="6">
                      <button
                        onClick={onClose}
                        className="btn btn-warning btn-sm"
                        style={STYLES.BUTTON_BACK}
                      >
                        CANCEL
                                        </button>
                    </Col>
                    <Col xs="6">
                      <button
                        className="btn btn-primary btn-sm"
                        style={STYLES.BUTTON_NEXT}
                        type="submit"
                      >
                        SAVE
                                        </button>
                    </Col>
                  </Row>
                  :
                  <Row style={STYLES.BUTTON_WRAPPER}>
                    <Col xs='6'>
                      <Link
                        to={backUrl}
                        className='btn btn-warning btn-sm'
                        style={STYLES.BUTTON_BACK}
                      >
                        BACK(REQUIREMENTS)
                    </Link>
                    </Col>
                    <Col xs='6'>
                      <button
                        className='btn btn-primary btn-sm'
                        style={STYLES.BUTTON_NEXT}
                        type='submit'
                      >
                        NEXT (CONTACTS)
                    </button>
                    </Col>
                  </Row>
                }
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...defaultValues,
      ..._get(state, 'carrierData.general', {}),
    },
    business: _get(state, 'appData.business', {}),
    physicalAddress: _get(state, 'carrierData.physicalAddress', {}),
    mailingAddress: _get(state, 'carrierData.mailingAddress', {}),
    carriers: _get(state, 'carriersData.carriers', []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateRegistration,
      searchSafer
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentForm)
);
