import React from "react";
import { Card, CardBody, CardSubtitle, Button } from "reactstrap";
import { pdfOptions } from "../../../../constants"
import html2pdf from "html2pdf.js";

class CertifyInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: false,
            sending: false,
            isOpen: false,
            isSubmitOpen: false,
            success: false,
            error: false
        };
    }
    onPrint = async () => {
        this.setState({
            downloading: true
        });
        //       const { filename } = this.props;
        const element = document.getElementById("info");
        pdfOptions.filename = "Certification of Information.pdf";
        await html2pdf()
            .set(pdfOptions)
            .from(element)
            .toPdf()
            .save();
        this.setState({
            downloading: false,
        });
    };


    render() {

        return (
            <div>
                <Card
                    style={{
                        fontSize: 12,
                        maxHeight: 200,
                        overflow: "scroll",
                        marginBottom: 15
                    }}
                >
                    <CardBody
                        id="info">
                        <CardSubtitle>
                            <h4 className="text-center">
                                Certification of Information
                        </h4>
                        </CardSubtitle>
                        <div>
                            By accessing the website at www.osrit.com, you are
                            agreeing to be bound by these terms of service, all
                            applicable laws and regulations, and agree that you are
                            responsible for compliance with any applicable local
                            laws. If you do not agree with any of these terms, you
                            are prohibited from using or accessing this site.
                        <ul style={{ marginTop: 15 }}>
                                <li >
                                    You have the right to ask for a copy of the
                                    information we hold about you and to have any
                                    inaccuracies in your information corrected.
                            </li>
                                <li>
                                    By providing us with your personal data,
                                    including sensitive personal data such as on
                                    your health and personal circumstances.
                            </li>
                            </ul>

                            <div className="text-center" style={{
                                marginBottom: 15,
                                marginTop: 15
                            }}>
                                {this.state.downloading ? null :
                                    <Button size="sm"
                                        disabled={this.state.downloading}
                                        onClick={this.onPrint}>
                                        PRINT
                                    </Button>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    };
}
export default CertifyInformation;
