import { getDays } from "../../../utils/momentUtil";
export const defaultValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    password: "",
    phone: "",
    ssn: "",
    promotions: true,
    communincation: true,
    address_street: "",
    address_city: "",
    address_state: "",
    address_postalCode: "",
    address_country: "  US",
    address_residenceSince: "",
    education: "",
    lastSchool: "",
    previousAddress: false
};

export function validate(values) {
    let errors = {};
    if (!values.firstName) {
        errors.firstName = "Required.";
    }
    if (!values.lastName) {
        errors.lastName = "Required.";
    }
    if (!values.gender) {
        errors.gender = "Required.";
    }
    if (!values.address_street) {
        errors.address_street = "Required.";
    }
    if (!values.address_city) {
        errors.address_city = "Required.";
    }
    if (!values.address_state) {
        errors.address_state = "Required.";
    }
    if (!values.address_postalCode) {
        errors.address_postalCode = "Required.";
    }
    if (!values.address_country) {
        errors.address_country = "Required.";
    }
    if (!values.address_residenceSince) {
        errors.address_residenceSince = "Required.";
    }
    if (!values.address_residenceSince) {
        errors.address_residenceSince = "Required.";
    } else if (!values.previousAddress) {
        const days = getDays(values.address_residenceSince);
        if (days < 3 * 365) {
            errors.previousAddress = "error"
        }
    }
    if (!values.dob) {
        errors.dob = "Required.";
    }
    else if (getDays(values.dob) < 18 * 356) {
        errors.dob = "Under age.";
    }
    else if (getDays(values.dob) > 90 * 356) {
        errors.dob = "Over age.";
    }
    if (!values.lastSchool) {
        errors.lastSchool = "Required.";
    }
    if (!values.education) {
        errors.education = "Required.";
    }
    if (!values.phone) {
        errors.phone = "Required.";
    }

    if (!values.email) {
        errors.email = "Required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address.";
    }
    if (!values.ssn) {
        errors.ssn = "Required.";
    }
    else if ((values.ssn + "").length !== 9) {
        errors.ssn = "Not valid."
    }
    if (!values.address_residenceSince) {
        errors.address_residenceSince = "Required.";
    }
    return errors;
}

export function isResident(lastDate, addressArray) {
    let startDate = lastDate
    let endDate = null
    if (addressArray.length === 0) {
        return false
    } else {
        console.log(addressArray)
        const addresses = addressArray.sort((a, b) => new Date(b.residenceFrom).getTime() - new Date(a.residenceFrom).getTime()).reverse();
        console.log("s::", addresses)

        addresses.map((item, i) => {
            if (i === 0) {
                startDate = item.residenceFrom
                endDate = item.residenceTo
            }
            else {
                const d = getDays(item.residenceFrom, endDate)
                if (d >= 0) {
                    endDate = item.residenceTo
                }
            }
            return null
        })
        const days = - getDays(lastDate, endDate)
        const totalDays = -getDays(Date(), startDate)
        if (days > 0) {
            return false
        }
        else if (totalDays > 365 * 3) {
            return true
        }
    }
}