import React from "react";
import { Row, Col } from "reactstrap";
import _get from "lodash/get";

export class ContentContainer extends React.Component {
    render() {
        const businessName = _get(this.props, "business.name");
        return (
            <div>
                <Row>
                    <Col
                        xs="12"
                        style={{
                            margin: "10px auto",
                            fontSize: 12,
                            fontWeight: 200
                        }}
                    >
                        <h5
                            className="text-center"
                            style={{ margin: "15px auto" }}
                        >
                            Driver Application
                        </h5>
                        <p>
                            Thank you for your interest in {businessName}. To
                            apply for a driving position, please complete our
                            online application for employment. Incomplete
                            information will delay the processing of your
                            application or prevent it from being submitted.
                        </p>
                        <p>
                            In compliance with Federal and State equal
                            employment opportunity laws, qualified applicants
                            are considered for all positions without regard to
                            race, color, religion, sex, national origin, age,
                            marital status, veteran status, non-job related
                            disability, or any other protected group status.
                        </p>
                        <h6>
                            To fill out this form, you will need to know the
                            following:
                        </h6>
                        <ul>
                            <li>Social Security Number</li>
                            <li>Home address history for the past 3 years</li>
                            <li>
                                Current driver's license number and driver's
                                license history for the past 3 years
                            </li>
                            <li>Employment history up to 10 years.</li>
                            <li>
                                History of traffic accidents, violations, and /
                                or convictions from the last 3 years (including
                                DUI or reckless driving convictions and license
                                suspensions)
                            </li>
                            <li>Military history (if applicable)</li>
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentContainer;
