import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import InputBox from "../../../components/InputBox/InputBox";
import SelectBox from "../../../components/SelectBox/SelectBox";
import InputDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import { COUNTRIES, US_STATES, CA_STATES, FINE_AMOUNTS, CHARGES_DETAILS } from "../../../utils/constants";
import STYLES from "../STYLES";

const defaultValues = {
    violationDate: "",
    charge: "",
    country: "US",
    address_city: "",
    state: "",
    isCommercial: "",
    fine: "",
    suspension: "",
    revocation: "",
    communityService: "",
    other: "",
    fineAmount: "",
    comments: ""
};

function validate(values) {
    let errors = {};

    if (!values.violationDate) {
        errors.violationDate = "Required.";
    }
    if (!values.charge) {
        errors.charge = "Required.";
    }
    if (!values.country) {
        errors.country = "Required.";
    }
    if (!values.address_city) {
        errors.address_city = "Required.";
    }
    if (!values.state) {
        errors.state = "Required.";
    }

    if (!values.comments) {
        errors.comments = "Required.";
    }

    return errors;
}

class ViolationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = async values => {
        this.props.onClose(values);
    };

    render() {
        let { initialValues } = this.props;
        return (
            <Modal
                size={"lg"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    ADD VIOLATION
                </ModalHeader>
                <ModalBody
                    style={{ padding: 30 }}
                >
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={validate}
                        onSubmit={this.onSubmit}
                    >
                        {({ handleSubmit, values }) => {
                            const SEL_STATES =
                                values.country === "CA" ? CA_STATES : US_STATES;
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <InputDatePicker
                                                    md={"6"}
                                                    type={"date"}
                                                    name={"violationDate"}
                                                    label={"Violation Date"}
                                                    autocomplete="off"

                                                />
                                                <SelectBox
                                                    md={"6"}
                                                    name={"charge"}
                                                    label={"Charge / Description"}
                                                    options={CHARGES_DETAILS}
                                                />

                                                <SelectBox
                                                    md={"4"}
                                                    name={"country"}
                                                    label={"Country"}
                                                    options={COUNTRIES}
                                                />
                                                <SelectBox
                                                    md={4}
                                                    name={"state"}
                                                    label={"State"}
                                                    options={SEL_STATES}
                                                />
                                                <InputBox
                                                    md={"4"}
                                                    name={"address_city"}
                                                    label={"City"}
                                                    placeholder={"City"}
                                                />
                                            </Row>
                                        </Col>
                                        <Col md={12}>
                                            <Row style={{
                                                marginTop: 10,

                                            }}>
                                                <InputCheckbox
                                                    md={6}
                                                    marginTop={22}
                                                    name={"isCommercial"}
                                                    label={"Were you in a Commercial Vehicle?"}
                                                />

                                            </Row>
                                            <Row>
                                                <InputCheckbox
                                                    md={4}
                                                    marginTop={22}
                                                    name={"communityService"}
                                                    label={"Community Service"}
                                                />
                                                <InputCheckbox
                                                    marginTop={22}
                                                    md={3}
                                                    name={"suspension"}
                                                    label={"Suspension"}
                                                />
                                                <InputCheckbox
                                                    marginTop={22}
                                                    md={3}
                                                    name={"revocation"}
                                                    label={"Revocation"}
                                                />
                                                <InputCheckbox
                                                    md={2}
                                                    marginTop={22}
                                                    name={"other"}
                                                    label={"Other"}
                                                />
                                            </Row>
                                        </Col>
                                        <SelectBox
                                            md={"4"}
                                            name={"fineAmount"}
                                            label={"Fine Amount (if applicable)"}
                                            options={FINE_AMOUNTS}
                                        />
                                        <InputBox
                                            md={"8"}
                                            name={"comments"}
                                            label={"Comments"}
                                            placeholder={"Enter Comments"}
                                        />
                                    </Row>
                                    <Col
                                        style={{
                                            marginTop: 10,

                                        }}
                                        className="text-center">
                                        <button
                                            color="primary"
                                            type="submit"
                                            className="btn btn-primary btn-sm"
                                            style={STYLES.BUTTON_NEXT}
                                        >
                                            {"SAVE VIOLATION"}
                                        </button>
                                        <div className="text-center">
                                            <Button
                                                color="link" onClick={this.props.toggle}>
                                                Cancel
                                                </Button>
                                        </div>
                                    </Col>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: {
            ...defaultValues
        },
        business: _get(state, "appData.business", {})
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViolationModal);
