import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import { Button, Label, Input, Alert } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import _get from "lodash/get";
import { getBusiness } from "../../actions/app/appAction";

export class DriverAppSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dot: "",
            mc: "",
            isMc: false,
            isDot: false,
            loading: false
        };
    }

    componentDidMount() { }

    getBusiness = async () => {
        const { dot, mc } = this.state;
        this.setState({ loading: true, error: false });
        try {
            const business = await this.props.getBusiness(dot, mc);
            if (business.id) {
                this.setState({ loading: false, business, isOpen: true });
            } else {
                this.setState({ loading: false, error: true, business: null });
            }
        } catch (e) {
            console.log(e);
            this.setState({ loading: false, error: true, business: null });
        }
    };

    toggle = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    onContinue = e => {
        const { dot, mc, isDot } = this.state;
        const url = isDot
            ? `/driver/${dot}/disclaimer`
            : `/carrier/${mc}/disclaimer`;
        window.location.replace(url);
    };

    handleRadioChange = dOrC => {
        this.setState({
            isDot: dOrC === "DOT",
            isMc: dOrC === "MC"
        });
    };

    render() {
        const { isDot, isMc } = this.state;
        const { dot, mc, error, loading, isOpen, business } = this.state;
        const cardStyles = {
            padding: 25,
            marginTop: 15,
            backgroundColor: "#f8f9fa",
            borderRadius: 5,
            textAlign: "center",
            fontSize: 25,
            fontWeight: 400,
            cursor: "pointer"
        };

        return (
            <div>
                {business && (
                    <Modal isOpen={isOpen} toggle={this.toggle} className={""}>
                        <ModalBody>
                            <div style={{ padding: 25 }}>
                                <h4
                                    style={{ marginTop: 10, marginBottom: 25 }}
                                    className="text-center text-uppercase"
                                >
                                    {business.name}
                                    <div>
                                        {business.dot ? (
                                            <small>DOT# {business.dot}</small>
                                        ) : (
                                                ""
                                            )}
                                    </div>
                                    <div>
                                        {business.mc ? (
                                            <small>MC# {business.mc}</small>
                                        ) : (
                                                ""
                                            )}
                                    </div>
                                </h4>

                                <div>Phone:- {business.phone}</div>
                                <div>Email:- {business.email}</div>
                                <div className="mt-2">
                                    <strong>Address:-</strong>
                                </div>
                                <div>{business.line1}</div>
                                <div>
                                    {business.city}, {business.state}-
                                    {business.zip}
                                </div>
                                <div className="text-center">
                                    <Button
                                        size="sm"
                                        color="success"
                                        onClick={this.onContinue}
                                        style={{ marginTop: 45, minWidth: 250 }}
                                    >
                                        CONTINUE
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button color="link" onClick={this.toggle}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}

                <Jumbotron fluid style={{ backgroundColor: "white" }}>
                    <Container fluid>
                        <Row>
                            <Col md="4" />
                            <Col
                                md="4"
                                className="text-center"
                                style={{ margin: "25px auto" }}
                            >
                                <h2 className="text-center text-uppercase">
                                    JOIN WITH US
                                </h2>
                                <p>
                                    We are excited to see you are interested in
                                    working with us.
                                </p>
                                <div style={{ marginTop: 15, fontWeight: 600 }}>
                                    How would you like work driver or carrier ?
                                </div>
                                <Row className="mb-4 text-left">
                                    <Col md="6">
                                        <div
                                            style={{
                                                ...cardStyles,
                                                color: isDot
                                                    ? "white"
                                                    : "black",
                                                backgroundColor: isDot
                                                    ? "#17a2b8"
                                                    : "#f8f9fa"
                                            }}
                                            onClick={() =>
                                                this.handleRadioChange("DOT")
                                            }
                                        >
                                            {"DRIVER"}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div
                                            style={{
                                                ...cardStyles,
                                                color: isMc ? "white" : "black",
                                                backgroundColor: isMc
                                                    ? "#17a2b8"
                                                    : "#f8f9fa"
                                            }}
                                            onClick={() =>
                                                this.handleRadioChange("MC")
                                            }
                                        >
                                            {"CARRIER"}
                                        </div>
                                    </Col>
                                </Row>

                                <Label
                                    for="examplePassword"
                                    className="mr-sm-2 sr-only"
                                >
                                    Dot
                                </Label>
                                {isDot && (
                                    <Input
                                        bsSize="sm"
                                        type="number"
                                        name="dot"
                                        value={dot}
                                        id="dot"
                                        placeholder="Enter DOT number of carrier, with whom you want to drive."
                                        className="btn-block"
                                        onChange={e => {
                                            this.setState({
                                                dot: e.target.value,
                                                mc: ""
                                            });
                                        }}
                                    />
                                )}
                                {isMc && (
                                    <Input
                                        bsSize="sm"
                                        type="number"
                                        name="mc"
                                        value={mc}
                                        id="mc"
                                        placeholder="Enter MC number of broker, with whom you want to work."
                                        className="btn-block"
                                        onChange={e => {
                                            this.setState({
                                                mc: e.target.value,
                                                dot: ""
                                            });
                                        }}
                                    />
                                )}
                                {error && (
                                    <Alert
                                        color="danger"
                                        isOpen={true}
                                        fade={false}
                                        style={{ marginTop: 5 }}
                                    >
                                        Not able to find.
                                    </Alert>
                                )}
                                <Row>
                                    <Col md="12">
                                        <Button
                                            size="sm"
                                            color="info"
                                            className="btn-block"
                                            disabled={!(dot || mc) || loading}
                                            style={{ marginTop: 25 }}
                                            onClick={this.getBusiness}
                                        >
                                            SEARCH
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="4" />
                        </Row>
                    </Container>
                </Jumbotron>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", ""),
        isSignInOpen: _get(state, "appData.isSignInOpen", true)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBusiness
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriverAppSearch)
);
