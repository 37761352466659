import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';

export class MoreDetailsInformation extends React.Component {
  render() {
    const { info = {} } = this.props;
    return (
      <Col xs='12' className='html2pdf__page-break'>
        <Table bordered style={{ fontSize: '12px' }}>
          <TableHeader name='More Information'
            onClick={() => { this.props.onEdit("More") }}
            status={this.props.status}
          />
          <tbody>
            <TableRow name='US bond' value={info.usbond ? info.usbond : "N/A"} />
            <TableRow name='CA bond' value={info.cabond ? info.cabond : "N/A"} />
            <TableRow name='SCAC' value={info.scac ? info.scac : "N/A"} />
            <TableRow
              name='SMC Tariff Code'
              value={info.smcTariffCode ? info.smcTariffCode : "N/A"}
            />
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default MoreDetailsInformation;
