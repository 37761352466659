import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        return (
            <Col xs={12} className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Traffic Convictions \ Violations"
                        onClick={() => { this.props.onEdit("License") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name="Violation Date" value={formatDate(info.violationDate)} />
                        <TableRow name="Charge / Description" value={info.charge} />
                        <TableRow name="Violation State/Province" value={info.state} />
                        <TableRow name="City" value={info.address_city} />
                        <TableRow name="In Commercial Vehicle" value={info.isCommercial ? "Yes" : "No"} />
                        <TableRow name="License Suspended?" value={info.suspension ? "Yes" : "No"} />
                        <TableRow name="License Revoked?" value={info.revocation ? "Yes" : "No"} />
                        <TableRow name="Perform Community Service?" value={info.communityService ? "Yes" : "No"} />
                        <TableRow name="Other Penalty?" value={info.other ? "Yes" : "No"} />
                        <TableRow name="Fine Amount:" value={info.fineAmount ? info.fineAmount : "$0"} />
                        <TableRow name="Comments:" value={info.comments} />
                    </tbody>
                </Table>
            </Col>

        );
    }
}

export default Information;
