import React from 'react';
import { Col, Table } from 'reactstrap';
import TableRow from '../../../components/TableRow';
import TableHeader from '../../../components/TableHeader';
import { capitalize } from '../../../utils/capitalize';

export class HOSInformation extends React.Component {

  render() {
    const info = this.props.info[0]
    return (
      <Col xs={12} className="html2pdf__page-break">
        <Table bordered style={{ fontSize: "12px" }}        >
          <TableHeader name="HOS Information"
            onClick={() => { this.props.onEdit("HOS") }}
            status={this.props.status}
          />
          {this.props.info.length === 1 ?
            <tbody>
              <TableRow name='Provider' value={info.provider ? capitalize(info.provider) : "N/A"} />
              <TableRow name='Contact' value={info.contact ? info.contact : "N/A"} />
              <TableRow name='Phone' value={info.phone ? info.phone : "N/A"} />
              <TableRow name='Email' value={info.email ? capitalize(info.email) : "N/A"} />
            </tbody>
            :
            this.props.info.map((item, i) => (
              <tbody>
                <TableRow name={capitalize(item.provider)} value="##" />
                <TableRow name='Contact' value={item.contact ? item.contact : "N/A"} />
                <TableRow name='Phone' value={item.phone ? item.phone : "N/A"} />
                <TableRow name='Email' value={item.email ? capitalize(item.email) : "N/A"} />
              </tbody>
            ))
          }
        </Table>
      </Col>

    );
  }

}

export default HOSInformation;
