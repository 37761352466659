import React from 'react';
import { Row, Col } from 'reactstrap';
import ContentContainer from './HosContainer';
import ProgressBar from '../../../components/ProgressBar';

export class HosContainer extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs="12">
            <ProgressBar percentage={60} />
          </Col>

          <Col xs='12'>
            <ContentContainer {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default HosContainer;
