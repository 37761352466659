import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AppHome from "./AppHome";
import AppDriver from "./AppDriver";
import AppCarrier from "./AppCarrier";

import "./App.css";

function App() {
    return (
        <BrowserRouter>
            <div>
                <Switch>
                    <Route exact path="/">
                        <AppHome />
                    </Route>
                    <Route path="/driver/:businessKey">
                        <AppDriver />
                    </Route>
                    <Route path="/carrier/:businessKey">
                        <AppCarrier />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
}
export default App;
