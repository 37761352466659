import React from "react";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import STYLES from "../containers/Driver/STYLES";

class ConfirmationModal extends React.Component {
    render() {
        const { message } = this.props
        return (
            <Modal
                size={"md"}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={""}
            >
                <ModalHeader toggle={this.props.toggle}>
                    Confirmation
                </ModalHeader>
                <ModalBody style={{ padding: 20 }}>
                    <h4 style={{ textAlign: "center" }}>
                        {message ? message : "Are you sure to perform this action?"}
                    </h4>
                    <Row style={STYLES.BUTTON_WRAPPER}>
                        <Col xs="6">
                            <Button
                                className="btn btn-warning btn-sm"
                                style={STYLES.BUTTON_BACK}
                                onClick={this.props.toggle}
                            >
                                Cancel
                          </Button>
                        </Col>
                        <Col xs="6">
                            <Button
                                className="btn btn-primary btn-sm"
                                style={STYLES.BUTTON_NEXT}
                                onClick={this.props.onConfirm}
                            >
                                Confirm
                                        </Button>
                        </Col>
                    </Row>

                </ModalBody>
            </Modal>
        );
    }
}

export default (ConfirmationModal);
