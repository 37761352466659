import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow";
import TableHeader from "../../../components/TableHeader";
import PersonalInformation from "./PersonalInformation";
import GeneralInformation from "./GeneralInformation";
import Employment from "./Employment"
import Unemployment from "./Unemployment"
import Licenses from "./Licenses"
import USMilitary from "./USMilitary"
import MVR from "./MVR"
import Training from "./Training"
import Address from "./Address"
import TrafficViolations from "./TrafficViolations";
import VehicleAccidentRecord from "./VehicleAccidentRecord";

import { formatPhoneNumber } from "../../../utils/Formator";
import Heading from "../../../components/Heading/Heading";
import CommonEditModal from "../../common/CommonEditModal";
// import { DRIVER_BASE_URL } from "../../../utils/constants";

export class ReviewContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onEdit = (title) => {
        this.setState({ isEdit: true, title })
    }
    render() {
        const { data, business } = this.props;
        const { isEdit, title } = this.state;
        let { status } = this.props;
        let isSubmitted = false
        if (data.status === "SUBMITTED") {
            status = true
            isSubmitted = true
        }

        // const url = `${DRIVER_BASE_URL}/${business.dot}/`;
        const details = {
            logo: business.logourl,
            name: business.name,
            address: `${business.line1} ${business.city}, ${business.state}-${business.zip
                }`,
            disContact: `Ph:- ${formatPhoneNumber(business.phone)}, Email:- ${business.email
                }`,
            invContact: `Ph:- ${business.invphone}, Email:- ${business.invemail
                }`
        };

        return (
            <div>
                {isEdit &&
                    <CommonEditModal
                        isOpen={isEdit}
                        title={title}
                        type={"driver"}
                        onClose={() => { this.setState({ isEdit: false, title: "" }) }}
                    />
                }
                <div>
                    <Col xs="12" style={{ marginTop: 7 }}>
                        <Heading md="12" details={details} />
                    </Col>
                </div>
                <div>
                    <div xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            id="title"
                            style={{
                                padding: 10,
                                marginBottom: 10,
                                textAlign: "center"
                            }}
                        >

                            {isSubmitted ? "Your Application is under review" : status
                                ? "Driver Application"
                                : "Review and Submit Your Info"}
                        </h5>
                    </div>

                    <PersonalInformation info={data.personal}
                        onEdit={this.onEdit}
                        status={status} />
                    <GeneralInformation info={data.general}
                        onEdit={this.onEdit}
                        status={status} />

                    {data.personalAddress.length > 0 ?
                        <div style={{ width: "100%" }} className="html2pdf__page-break">
                            {
                                <Address info={data.personalAddress}
                                    onEdit={this.onEdit}
                                    status={status} />
                            }
                        </div> : null}
                    <Col xs="12" >
                        <Table bordered
                            style={{ fontSize: 12 }}
                        >
                            <TableHeader name="Safety and Customer Service Awards"
                                status={true} />
                            <tbody>
                                <TableRow name="Please list any safe driving awards you have received and from whom:"
                                    value={data.general.drivingAwards ? data.general.drivingAwards : "None"} />
                                <TableRow name="Please list any customer service awards you have received and from whom:"
                                    value={data.general.customerServiceAwards ? data.general.customerServiceAwards : "None"} />
                            </tbody>
                        </Table>
                    </Col>

                    <Licenses info={data.license}
                        onEdit={this.onEdit}
                        status={status} />

                    {data.employment.length > 0 ? (
                        <div style={{ width: "100%" }}>
                            {data.employment.map((item, i) => (
                                <Employment key={i} info={item}
                                    onEdit={this.onEdit}
                                    status={status} />
                            ))}
                        </div>
                    ) : null}

                    {data.training.length > 0 ? (
                        <div style={{ width: "100%" }}>
                            <Training info={data.training}
                                onEdit={this.onEdit}
                                status={status} />
                        </div>
                    ) : null}

                    {data.unemployment.length > 0 ? (
                        <div style={{ width: "100%" }}>
                            {Array.isArray(data.unemployment) &&
                                <Unemployment info={data.unemployment}
                                    onEdit={this.onEdit}
                                    status={status} />
                            }
                        </div>
                    ) : null}

                    {data.violation.length > 0 ? (
                        <div style={{ width: "100%" }}>
                            {Array.isArray(data.violation) &&
                                data.violation.map((item, i) => (
                                    <TrafficViolations key={i} info={item}
                                        onEdit={this.onEdit}
                                        status={status} />
                                ))}
                        </div>
                    ) : null}

                    {data.accident.length > 0 ? (
                        <div style={{ width: "100%" }}>
                            {Array.isArray(data.accident) &&
                                data.accident.map((item, i) => (
                                    <VehicleAccidentRecord
                                        key={i}
                                        info={item}
                                        onEdit={this.onEdit}
                                        status={status}
                                    />
                                ))}
                        </div>
                    ) : null}
                    {data.military.isMilitary ?
                        <USMilitary info={data.military}
                            onEdit={this.onEdit}
                            status={status} /> : null
                    }
                    <MVR info={data.mvr ? data.mvr : {}}
                        onEdit={this.onEdit}
                        status={status} />
                </div>
            </div>
        );
    }
}
export default ReviewContent;