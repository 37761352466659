import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil"

export class Information extends React.Component {
    render() {
        const info_ = this.props.info[0]
        return (
            <Col xs={12}>
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Previous Residence History"
                        onClick={() => { this.props.onEdit("Personal") }}
                        status={this.props.status}
                    />
                    {this.props.info.length === 1 ?
                        <tbody>
                            <TableRow name="Address" value={info_.street} />
                            <TableRow name="City, State/Province Zip/Postal" value={info_.city + ", " + info_.state + " " + info_.postalCode} />
                            <TableRow name="Residence from" value={`${formatDate(info_.residenceFrom)}`} />
                            <TableRow name="Residence to" value={`${formatDate(info_.residenceTo)}`} />
                        </tbody>
                        :
                        this.props.info.map((item, i) => (
                            <tbody>
                                <TableRow name={"Address " + (i + 1)} value="##" />
                                <TableRow name="Address" value={item.street} />
                                <TableRow name="City, State/Province Zip/Postal" value={item.city + ", " + item.state + " " + item.postalCode} />
                                <TableRow name="Residence from" value={`${formatDate(item.residenceFrom)}`} />
                                <TableRow name="Residence to" value={`${formatDate(info_.residenceTo)}`} />
                            </tbody>
                        ))}
                </Table>
            </Col>

        );
    }
}

export default Information;
