import { isUnvalidDate } from "../../../../../utils/momentUtil";

export const defaultValues = {
    companyName: "",
    companyPhone: "",
    start: "",
    end: "",
    country: "US",
    street: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    position: "",
    reasonOfLeaving: ""
}

export function validate(values) {
    let errors = {};
    if (!values.companyName) {
        errors.companyName = "Required."
    }
    if (!values.companyPhone) {
        errors.companyPhone = "Required."
    } else if ((values.companyPhone + "").length !== 10) {
        errors.companyPhone = "Not valid."
    }
    if (isUnvalidDate(values.start, values.end)) {
        errors.end = "Select valid date"
    }
    if (!values.start) {
        errors.start = "Required."
    }
    if (!values.end) {
        errors.end = "Required."
    }
    if (!values.country) {
        errors.country = "Required."
    }
    if (!values.street) {
        errors.street = "Required."
    }
    if (!values.city) {
        errors.city = "Required."
    }
    if (!values.state) {
        errors.state = "Required."
    }
    if (!values.postalCode) {
        errors.postalCode = "Required."
    }
    if (!values.position) {
        errors.position = "Required."
    }
    if (!values.reasonOfLeaving) {
        errors.reasonOfLeaving = "Required."
    }
    return errors;
}
