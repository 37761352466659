// import { isValidLicence } from "../../../utils/momentUtil";

export const defaultValues = {
    licenseCountry: "US",
    licenseState: "",
    licenseClass: "",
    licenseExp: "",
    licenseNo: "",
    physicalExp: ""
}

export function validate(values) {
    let errors = {};

    if (values.hazmat && !values.hazmatExp) {
        errors.hazmatExp = "Required"
    }
    if (values.xEndorsement && !values.endorsementExp) {
        errors.endorsementExp = "Required"
    }
    if (!values.licenseCountry) {
        errors.licenseCountry = "Required"
    }
    if (!values.licenseState) {
        errors.licenseState = "Required"
    }
    if (!values.licenseClass) {
        errors.licenseClass = "Required"
    }
    if (!values.licenseNo) {
        errors.licenseNo = "Required"
    }
    // else if ((values.licenseNo + "").length !== 7 && (values.licenseNo + "").length !== 8) {
    //     errors.licenseNo = "Not valid."
    // }
    // else if (!isValidLicence(values.licenseNo)) {
    //     errors.licenseNo = "Not valid."
    // }

    if (!values.licenseExp) {
        errors.licenseExp = "Required"
    }
    if (!values.physicalExp) {
        errors.physicalExp = "Required"
    }
    return errors;
}

