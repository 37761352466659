import React from "react";
import { Row, Col } from "reactstrap";
// import STYLES from '../STYLES';
import HosForm from "./HosForm";

export class HosContent extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            style={{
                                marginTop: 5,
                                marginBottom: 10,
                                textAlign: "center"
                            }}
                        >
                            {"Hos Information"}
                        </h5>
                        <HosForm />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HosContent;
