import React from "react";
import { Row, Col } from "reactstrap";

export class ContentContainer extends React.Component {
    render() {
        const { business } = this.props;
        const businessName = business.name;
        return (
            <div>
                <Row>
                    <Col xs="12" style={{ margin: "10px auto", fontSize: 12 }}>
                        <h5
                            style={{
                                marginTop: 25,
                                marginBottom: 25,
                                textAlign: "center"
                            }}
                        >
                            Requirements
                        </h5>
                        <h6>
                            To qualify with {businessName}, you must meet the
                            following criteria:
                        </h6>
                        <ul>
                            <li>Must have a </li>
                            <li>Must have a </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentContainer;
