import React from "react";
import { Col, Table } from "reactstrap";
import TableRow from "../../../components/TableRow"
import TableHeader from "../../../components/TableHeader"
import { formatDate } from "../../../utils/momentUtil";
import { formatPhoneNumber } from "../../../utils/Formator";

export class Information extends React.Component {
    render() {
        const { info } = this.props
        return (
            <Col xs="12" style={{ width: "100%" }} className="html2pdf__page-break">
                <Table bordered
                    style={{ fontSize: "12px" }}
                >
                    <TableHeader name="Employment"
                        onClick={() => { this.props.onEdit("History") }}
                        status={this.props.status}
                    />
                    <tbody>
                        <TableRow name="Company" value={info.companyName} />
                        <TableRow name="Start Date" value={formatDate(info.start)} />
                        <TableRow name="End Date" value={formatDate(info.end)} />
                        <TableRow name="Address" value={info.street} />
                        <TableRow name="City, State/Province Zip/Postal" value={info.city + " " + info.state + " " + info.postalCode} />
                        <TableRow name="Country" value={info.country} />
                        <TableRow name="Phone" value={formatPhoneNumber(info.companyPhone)} />
                        <TableRow name="Position Held" value={info.position} />
                        <TableRow name="Reason for leaving?" value={info.reasonOfLeaving} />
                        <TableRow name="Were you terminated/discharged/aid off?" value={info.isTerminated ? "Yes" : "No"} />
                        <TableRow name="Is this your current employer?" value={info.isCurrentEmployer ? "Yes" : "No"} />
                        <TableRow name="May we contact this employer at this time?" value={info.isContactEmployer ? "Yes" : "No"} />
                        <TableRow name="Did you operate a commercial motor vehicle?" value={info.iSIsOperate ? "Yes" : "No"} />
                        {/* <TableRow name="Were you subject to the Federal Motor Carrier or Transport Canada Safety Regulations while employed/contracted by this employer/contractor?" value={""} />
                        <TableRow name="Did you perform any safety sensitive functions in this job subject to drug and alcohol testing?" value={""} />
                        <TableRow name="Areas Driven" value={""} />
                        <TableRow name="Miles driven weekly" value={""} />
                        <TableRow name="Most common truck driven" value={""} />
                        <TableRow name="Most common trailer" value={""} />
                        <TableRow name="Trailer length" value={""} />
                        { */

                        }
                    </tbody>
                </Table>
            </Col>

        );
    }
}

export default Information;
