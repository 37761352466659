import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import STYLES from "../STYLES";
import ContentContainer from "./ContentContainer";
import ProgressBar from "../../../components/ProgressBar";
import _get from "lodash/get";
import { CARRIER_BASE_URL } from '../../../utils/constants';

export class DriverRegRequirementContainer extends React.Component {
    render() {
        const businessKey = _get(this, "props.match.params.businessKey");
        const nextUrl = `${CARRIER_BASE_URL}/${businessKey}/general`;
        const backUrl = `${CARRIER_BASE_URL}/${businessKey}/disclaimer`;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <ProgressBar percentage={5} />
                    </Col>
                    <Col xs="12">
                        <ContentContainer {...this.props} />
                    </Col>
                </Row>
                {this.props.userEmail && (
                    <Row style={STYLES.BUTTON_WRAPPER}>
                        <Col xs="6">
                            <Link
                                to={backUrl}
                                className="btn btn-warning btn-sm"
                                style={STYLES.BUTTON_BACK}
                            >
                                BACK (disclaimer)
                            </Link>
                        </Col>
                        <Col xs="6">
                            <Link
                                to={nextUrl}
                                className="btn btn-primary btn-sm"
                                style={STYLES.BUTTON_NEXT}
                            >
                                NEXT (General)
                            </Link>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", true)
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(DriverRegRequirementContainer)
);
